import Layout from "../components/layouts/Layout";
import React, { useReducer, useRef } from "react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import AirplayIcon from "@mui/icons-material/Airplay";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import SchoolIcon from "@mui/icons-material/School";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import TranslateIcon from "@mui/icons-material/Translate";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
/// Image
// import logo from "../../images/user1.png"
import profile from "../../images/profile/profile.png";
import uploadImg from "../../images/profile/profile-placeholder.png";
import MainPagetitle from "../components/layouts/MainPagetitle";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getsupportChatdata,
  getsupportchatdetails,
  supportChat,
} from "../../store/actions/AdminAction";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import * as yup from "yup";
import { Formik, ErrorMessage } from "formik";

import Modal from "react-bootstrap/Modal";
import { Button, Switch } from "@mui/material";
import OwlCarousel from "react-owl-carousel";
import { useLocation } from "react-router-dom";
import { getOrderdetails, ordersstatus } from "../../store/actions/AdminAction";
import { Form } from "react-bootstrap";
import { CleanHands, Flag, Message } from "@mui/icons-material";
import Placholder from "../../images/placholder-img-admin1.png";
import { io } from "socket.io-client";

const newcconnection = io(process.env.REACT_APP_FILE_BASE_URL);
// const socket = io(process.env.REACT_APP_FILE_BASE_URL);

const SupportChat = () => {
  const BASE_URL = process.env.REACT_APP_FILE_BASE_URL;
  const [userdetails, setuserdetails] = useState("");
  console.log(userdetails);
  const [tarcking, settarckingdetails] = useState("");
  const [Orderdetails, setOrderdetails] = useState([]);
  const [bill, setbill] = useState([]);
  const [bil, setbil] = useState("");
  const [message, setMessage] = useState("");
  const [chatdata, setchatdata] = useState([]);
  console.log(chatdata, "chatdatatattat");
  const [AdminId, setAdminId] = useState("");
  const chatContainerRef = useRef(null);

  const [MesssageTitle, setMessageTitle] = useState("");

  const [flag, setflag] = useState(false);

  const location = useLocation();
  console.log(location, "locationnnn");

  const dispatch = useDispatch();

  useEffect(() => {
    const storedAdminId = sessionStorage.getItem("adminid");
    if (storedAdminId) {
      setAdminId(storedAdminId);
    }
  }, []);

  useEffect(() => {
    const getdata = async () => {
      const data = await dispatch(supportChat({ id: location?.state?.id }));
      console.log(data);
      if (data?.status === 200) {
        setchatdata(data?.data?.data);
      }
    };
    getdata();
  }, [flag]);

  useEffect(() => {
    const getdetails = async () => {
      try {
        const data = await dispatch(
          getOrderdetails({ order_id: location?.state?.orderid })
        );

        if (data?.status == 200) {
          setuserdetails(data?.data?.data?.user);
          settarckingdetails(data?.data?.data?.order);
          setOrderdetails(data?.data?.data?.order?.products);
          setbill(data?.data?.data?.order);
          setbil(data?.data?.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getdetails();
  }, [flag]);

  const vatAmount = (bill?.subtotal * 0.02).toFixed(2);

  const sendMessage = (e) => {
    e.preventDefault();
    const data = {
      contactus_id: location?.state?.id,
      sender_id: AdminId,
      receiver_id: userdetails?._id,
      message: message,
      message_type: 0,
    };
    newcconnection.emit("support_new_message", data);
    setMessage("");
    setflag(!flag);
  };

  newcconnection.on("new_message", async () => {
    await dispatch(supportChat({ id: location?.state?.id }));
  });

  useEffect(() => {
    const getdetails = async () => {
      try {
        const data = await dispatch(
          getsupportchatdetails({ id: location?.state?.id })
        );
        console.log(data, "hgavbskdfhaisdfji");
        if (data?.status == 200) {
          setMessageTitle(data?.data?.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getdetails();
  }, [flag]);

  return (
    <Layout>
      <div>
        <MainPagetitle
          mainTitle="Support chat"
          pageTitle="support chat"
          parentTitle="support"
        />
        <div className="container-fluid">
          <Link to="/support" className="btn btn-secondary ">
            Back
          </Link>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <div className="col-lg-12">
                <div className="profile card card-body px-3 pt-3 pb-0">
                  <div className="profile-head">
                    <h4>User Details</h4>

                    <div className="profile-info">
                      <div className="profile-info">
                        <div className="user_detail_img_inner">
                          <img
                            // src={require("../../images/avatar/1.png")}
                            src={
                              userdetails?.main_image
                                ? `https://api.telepharmacare.com/user_docs/${userdetails?.main_image}`
                                : uploadImg
                            }
                            className="img-fluid rounded-circle profile-image"
                            alt="profile"
                          />
                        </div>
                      </div>
                      <div className="card-body d-flex align-items-center">
                        <div className="user-data-view">
                          <h5>{userdetails?.name}</h5>
                          <h5>{userdetails?.email}</h5>
                          <h5>{`${userdetails?.country_code || ""} ${
                            userdetails?.phone_number || ""
                          }`}</h5>
                          {/* <h5 className="mb-0">Lorem Ipsum is simply </h5> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xxl-12  col-xxl-12 col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Tracking Details</h4>
                  </div>
                  <div className="card-body ">
                    <div className="basic-form">
                      <div className="row ">
                        <div className="col-12 ">
                          <div className="form-check custom-checkbox mb-3 checkbox-success ps-0">
                            <p>Tracking Id : {tarcking?.tracking_number}</p>
                            <p>Courier Company : {tarcking?.courier_company}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="profile card card-body px-3 pt-3 pb-3">
                    <div className="profile-head">
                      <h4>Order Details</h4>
                      {/* {Orderdetails?.map((details) => {
                    console.log(details?.product_id?.productimage);
                    return (
                      <>
                      {details?.product_id?.productimage?.map((imgs) => {
                          return(
                      <div className="order-box  mb-3">
                        
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="profile-info p-0">
                              <div className="product-img">
                              
                                <img
                                  className="img-fluid"
                                  src={
                                    details?.product_id?.productimage
                                      ? `https://api.telepharmacare.com/public/production/${imgs}`
                                      : uploadImg
                                  }
                                />
                                
                              </div>
                        
                            </div>
                          </div>
                          <div className="col-lg-8 d-flex align-items-center">
                            <div className="card-body  p-0">
                              <div className="user-data-view">
                                <h4>{details?.product_id?.title}</h4>
                                <p className="mb-0">
                                  {details?.product_id?.product_description}
                                </p>
                                <h4>${details?.product_id?.price}</h4>
                                <h5 style={{ textAlign: "right" }}>
                                  {details?.quantity}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      
                      </div>
                          )
                                })}
                      </>
                    );
                  })} */}

                      {Orderdetails?.map((details) => {
                        console.log(details, "");
                        return (
                          <>
                            <div className="order-box  mb-3">
                              <div className="row">
                                <div className="col-lg-4">
                                  <div className="profile-info p-0">
                                    <div className="product-img">
                                      {details?.product_id?.productimage &&
                                        details?.product_id
                                          ?.productimage[0] && (
                                          <img
                                            className="img-fluid"
                                            src={`https://api.telepharmacare.com/public/production/${details.product_id.productimage[0]}`}
                                            alt="Product Image"
                                          />
                                        )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-8 d-flex align-items-center">
                                  <div className="card-body  p-0">
                                    <div className="user-data-view">
                                      <h4>{details?.product_id?.title}</h4>
                                      <p className="mb-0">
                                        {
                                          details?.product_id
                                            ?.product_description
                                        }
                                      </p>
                                      <h4>${details?.product_id?.price}</h4>
                                      <h5 style={{ textAlign: "right" }}>
                                        {details?.quantity}
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* )
                                })} */}
                          </>
                        );
                      })}

                      <div className="order-total">
                        <div className="total-inner">
                          <h3> Sub Total</h3>
                          <h4>${bill?.subtotal}</h4>
                        </div>
                        <div className="total-inner">
                          <h3>Discount</h3>
                          <h4>${bil?.discount}</h4>
                        </div>
                        <div className="total-inner">
                          <h3>Shipping Fee</h3>
                          <h4>${bil?.shipping_charges}</h4>
                        </div>
                        <div className="total-inner pb-2">
                          <h3>VAT</h3>
                          <h4>{vatAmount}</h4>
                        </div>
                        <div className="total-inner sub">
                          <h3>Total</h3>
                          <h4>${bill?.total_amount}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-5">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Title"
                name="title"
                disabled
                readOnly
                value={MesssageTitle?.title}
              />
              <br />
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Message.."
                // name="description"
                disabled
                readOnly
                value={MesssageTitle?.message}
              />
              {/* <div className="support-inner">
                <h2>Chat</h2>

                <div fluid ref={chatContainerRef} className="support-msg-box">
                  {chatdata?.map((chats) => {
                    console.log(chats)
                    
                    return (
                      <>
                        {chats?.sender_model == "admins" ? (
                          <div className="user-box">
                            <div className="user-top-hdng">
                            <h4>
                                {new Date(chats?.createdAt).toLocaleTimeString()}
                              </h4>
                              <h3>{chats?.username ? chats?.username : "You" }</h3> 
                              <img
                                src={chats?.Image
                                  ? BASE_URL +
                                    "/user_docs/" +
                                    chats?.Image
                                  : Placholder}
                              />
                              
                              
                            </div>
                            <div className="user-msg-box">
                              <p>{chats?.message}</p>
                            </div>
                          </div>
                        ) : (
                          <div className="right-side">
                            <div className="user-box ">
                              <div className="user-top-hdng">
                                <h4>
                                  {" "}
                                  {new Date(chats?.createdAt).toLocaleTimeString()}
                                </h4>
                                <h3>YOU</h3>

                                <img
                                  src={chats?.profile_image
                                    ? `https://urush-api.bosselt.com/${chats?.profile_image}`
                                    : Placholder}
                                />
                              </div>
                              <div className="user-msg-box">
                                <p className="m-0">{chats?.message}</p>
                              </div>
                            </div>
                          </div>
                        )}
                      </>

                    );
                  })}
                   </div>
                   <form onSubmit={sendMessage} >
                  <div class="send-box">
                    <div class="mb-3 input-group">
                      <input
                        placeholder="Type your message here..."
                        aria-label="send"
                        aria-describedby="basic-addon2"
                        name="message"
                        type="text"
                        class="send-feild form-control"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        
                      />
                      <button
                        class="input-group-text"
                        id="basic-addon2"
                        type="submit"
                        
                      >
                        <img
                          src={require("../../images/arrowright.svg").default}
                        />
                      </button>
                    </div>
                  </div>
                </form>
                </div> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SupportChat;
