import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { ThemeContext } from "../../../../context/ThemeContext";
import { navtoggle } from "../../../../store/actions/AuthActions";
import LogoWhite from "../../../../images/newwhitelogo.svg";
import LogoWhite2 from "../../../../images/T.svg";

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const { navigationHader, openMenuToggle, background } =
    useContext(ThemeContext);
  const dispatch = useDispatch();
  const sideMenu = useSelector((state) => state.sideMenu);
  const handleToogle = () => {
    dispatch(navtoggle());
  };
  return (
    <div className="nav-header">
      <Link to="/dashboard" className="brand-logo">
        <img
          src={LogoWhite2}
          className="theme-logo-o logo-abbr"
          style={{ width: "25px", height: "22px", viewBox: "0 0 47 16" }}
        />
        <img
          src={LogoWhite}
          className="theme-logo-o brand-title"
          style={{
            width: "78px",
            height: "48px",
            viewBox: "0 0 47 16",
          }}
        />
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          handleToogle();
        }}
      >
        <div className={`hamburger ${sideMenu ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
