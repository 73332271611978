import React, { useEffect, useState } from "react";
import Layout from "../components/layouts/Layout";
import { IMAGES } from "../constant/theme";
import MainPagetitle from "../components/layouts/MainPagetitle";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { ManageOrderListing } from "../../store/actions/AdminAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ordersstatus } from "../../store/actions/AdminAction";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { Button, Switch } from "@mui/material";
import * as yup from "yup";
import { Formik, ErrorMessage } from "formik";
import { Form } from "react-bootstrap";

const validationSchema = yup.object().shape({
  number: yup.string().required(<span style={{ color: "red" }}>Required</span>),
  companyname: yup
    .string()
    .required(<span style={{ color: "red" }}>Required</span>),
});

const OrderManagement = () => {
  const ordersdata = useSelector(
    (state) => state?.manageOrders?.manageOrderslist
  );
  console.log(ordersdata);
  const [limit, setlimit] = useState(10);
  const [page, setpage] = useState(1);
  const [flag, setflag] = useState(false);
  const [search, setsearch] = useState("");
  const [i, seti] = useState("");
  // console.log(i)
  const [tracking, settracking] = useState("");
  console.log(tracking);
  const [trackingId, setTrackingId] = useState("");
  const [Company, setCompany] = useState("");

  console.log(trackingId, "ujsdhfisdh", Company);

  console.log(Company);

  const [show, setshow] = useState(false);
  const [name, setname] = useState(null);
  const handleClose = () => setshow(false);
  const handleShow = () => setshow(true);

  // const [currentPage, setCurrentPage] = useState(1);
  // const recordsPage = 10;
  // const lastIndex = currentPage * recordsPage;
  // const firstIndex = lastIndex - recordsPage;
  // const records = tableData.slice(firstIndex, lastIndex);
  // const npage = Math.ceil(ordersdata.length / recordsPage);
  // const number = [...Array(npage + 1).keys()].slice(1);

  const lastIndex = page * limit;
  const npage = Math.ceil(
    (ordersdata?.totalCount == undefined ? 0 : ordersdata?.totalCount) / limit
  );
  const number = npage ? [...Array(npage + 1).keys()]?.slice(1) : [1];

  function prePage() {
    if (page !== 1) {
      setpage(page - 1);
    }
  }
  function changeCPage(id) {
    setpage(id);
  }
  function nextPage() {
    if (page !== npage) {
      setpage(page + 1);
    }
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchdata = async () => {
      const data = await dispatch(
        ManageOrderListing({ limit: limit, page: page, name: search })
      );
      console.log(data);
      if (data?.status === 200) {
        settracking(data?.data?.data?.data);
      }
    };
    fetchdata();
  }, [limit, page, flag]);
  

  const handlerOrderID = (userid, id) => {
    navigate("/view-order", {
      state: {
        id: userid,
        orderid: id,
      },
    });
  };

  const orderstatushandler = async (e, id) => {
    try {
      const data = await dispatch(
        ordersstatus({
          status: e,
          order_id: i,
          tracking_number: trackingId,
          courier_company: Company,
        })
      );
      console.log(data);
      if (data?.status == 200) {
        toast.success(data?.data?.message);
        setshow(false);
        setflag(!flag);
      } else {
        toast.error(data?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log(ordersdata);

  const obj = {
    0: "Pending",
    1: "Cancel",
    5: "Out of Order",
    2: "Accepted",
    3: "Dispatched",
    4: "Shipped",
    6: "Completed",
  };

  return (
    <Layout>
      <MainPagetitle
        mainTitle="Order Management"
        pageTitle="Order Management"
        parentTitle="Home"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects manage-client">
                  <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                    <h4 className="heading mb-0">Orders Status</h4>
                    {/* <div>
                                            <CSVLink {...csvlink} className="btn btn-primary light btn-sm">
                                                <i className="fa-solid fa-file-excel" /> {" "} 
                                                Export Report
                                            </CSVLink> 
                                        </div> */}
                  </div>
                  <div
                    id="manage-tblwrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="reports-tbl"
                      className="table ItemsCheckboxSec dataTable no-footer mb-0"
                    >
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th className="width-set">Order ID</th>
                          <th>price</th>
                          <th style={{ textAlign: "center" }}>User</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {records.map((item, index) => (
                          <tr key={index}>
                            <td>{item.id}</td>

                            <td>
                              <Link to="/view-order">
                                <h6>{item.OrderId}</h6>
                              </Link>
                            </td>

                            <td style={{ textAlign: "left" }}>
                              <span>{item.price}</span>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span className="overflow-text">{item.User}</span>
                            </td>
                            <td>
                              <select
                                class="form-select"
                                aria-label="Default select example"
                              >
                                <option selected>Open this select menu</option>
                                <option value="1">Pending</option>
                                <option value="2">Accepted</option>
                                <option value="3">Shipped</option>
                                <option value="4">Out of Order</option>
                                <option value="5">Completed</option>
                              </select>
                            </td>
                          </tr>
                        ))} */}

                        {ordersdata?.data?.map((data, index) => {
                          console.log(data?.tracking_number);
                          console.log(data?.courier_company);
                          const serialNumber = (page - 1) * limit + index + 1;
                          return (
                            <tr>
                              <td>{serialNumber}</td>

                              <td className="width-set">
                                <p>
                                  <h6 className="overflow-text"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handlerOrderID(
                                      data?.user_id?._id,
                                      data?.order_id
                                    )
                                  }>{data.order_id}</h6>
                                </p>
                              </td>

                              <td>
                                <span>{data?.total_amount}</span>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <span
                                  
                                >
                                  {data?.user_id?.name}
                                </span>
                              </td>
                              <td>
                                <select
                                  class="form-select"
                                  aria-label="Default select example"
                                  value={data?.delivery_status}
                                  onChange={(e) => {
                                    const selectedValue = Number(
                                      e.target.value
                                    );
                                    setTrackingId(data?.tracking_number);
                                    setCompany(data?.courier_company);
                                    seti(data?.order_id);
                                    setname(selectedValue);
                                    setshow(true);


                                    // if (data?.delivery_status === 6 ) {
                                    //   toast.error("This order has already been completed. You cannot change its status.");
                                    //   setshow(false);
                                    //   return;
                                    // }
                                
                                    // if (data?.delivery_status !== 4) {
                                    //   if ([0, 1, 2].includes(selectedValue)) {
                                    //     setshow(true);
                                    //   } else if(selectedValue == 4  || selectedValue == 5) {
                                    //     toast.error("Please follow the steps.");
                                    //     setshow(false);
                                    //   }else if(selectedValue == 6) {
                                    //     toast.error("Please follow the steps.");
                                    //     setshow(false);
                                    //   }
                                    // } else {
                                    //   // If delivery_status is 4 (Shipped)
                                    //   setshow(true);
                                    // }
                                  }}
                                >
                                  <option value="0">Pending</option>
                                  <option value="1">Cancel</option>
                                  <option value="5">Out of Order</option>
                                  <option value="2">Accepted</option>
                                  <option value="3">Dispatched</option>
                                  <option value="4">Shipped</option>
                                  <option value="6">Completed</option>
                                </select>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {lastIndex - limit + 1} to{" "}
                        {ordersdata?.totalCount &&
                        ordersdata?.totalCount < lastIndex
                          ? ordersdata?.totalCount
                          : lastIndex}{" "}
                        of {ordersdata?.totalCount && ordersdata?.totalCount}{" "}
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                page === n ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {name == "0" ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Pending Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are You Sure About This?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="danger" onClick={() => orderstatushandler(0)}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      ) : name == "3" ? (
        <Modal show={show} onHide={handleClose}>
          <Formik
            validationSchema={validationSchema}
            initialValues={{ number: "", companyname: "" }}
            onSubmit={async (values) => {
              console.log(values);
              try {
                const data = await dispatch(
                  ordersstatus({
                    status: "3",
                    order_id: i,
                    tracking_number: values?.number,
                    courier_company: values?.companyname,
                  })
                );
                console.log(data);
                if (data?.status == 200) {
                  toast.success(data?.data?.message);
                  handleClose(false);
                  setflag(!flag);
                } else {
                  toast.error(data?.data?.message);
                }
              } catch (error) {
                console.log(error);
              }
            }}
          >
            {({ values, handleChange, handleSubmit }) => (
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title>Dispatched Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Tracking ID</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Type Here.."
                      name="number"
                      as={Form.Control}
                      onChange={handleChange}
                      value={values?.number}
                    />
                    <ErrorMessage name="number" component="div" />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Courier Company</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Type Here.."
                      name="companyname"
                      as={Form.Control}
                      onChange={handleChange}
                      value={values?.companyname}
                    />
                    <ErrorMessage name="companyname" component="div" />
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    className="button-add"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="button-add"
                    variant="secondary"
                    type="button"
                    onClick={() => {
                      // orderAccpthandler();
                      handleSubmit();
                    }}
                  >
                    Save
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      ) : name == "1" ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title> Cancel Status </Modal.Title>
          </Modal.Header>
          <Modal.Body>Are You Sure About This?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="danger" onClick={() => orderstatushandler(1)}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      ) : name == "2" ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title> Accepted Status </Modal.Title>
          </Modal.Header>
          <Modal.Body>Are You Sure About This?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="danger" onClick={() => orderstatushandler(2)}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      ) : name == "5" ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title> Out of Order Status </Modal.Title>
          </Modal.Header>
          <Modal.Body>Are You Sure About This?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="danger" onClick={() => orderstatushandler(5)}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      ) : name == "4" ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title> Shipped Status </Modal.Title>
          </Modal.Header>
          <Modal.Body>Are You Sure About This?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="danger" onClick={() => orderstatushandler(4)}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      ) : name == "6" ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title> Completed Status </Modal.Title>
          </Modal.Header>
          <Modal.Body>Are You Sure About This?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="danger" onClick={() => orderstatushandler(6)}>
              yes
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        ""
      )}
    </Layout>
  );
};

export default OrderManagement;
