import React, { useState, useRef, useEffect } from "react";
import Layout from "../components/layouts/Layout";
import { Link, useNavigate } from "react-router-dom";
import InviteCustomer from "../constant/ModalList";
import EmployeeOffcanvas from "../constant/EmployeeOffcanvas";
import MainPagetitle from "../components/layouts/MainPagetitle";
import { useDispatch, useSelector } from "react-redux";
import {
  PharmacistAction,
  patientsAction,
  updatepharmacistProfile,
} from "../../store/actions/AdminAction";
import moment from "moment";
import Search from "../components/elements/Search";
import Placholder from "../../images/placholder-img-admin1.png";
import { toast } from "react-toastify";
import { Modal,Button } from "react-bootstrap";
import { date } from "yup";
const Pharmacist = () => {
  const IMG_URL = process.env.REACT_APP_FILE_BASE_URL;
  const limit = 10;
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState();
  const [show, setshow] = useState(false);
  const [showR, setshowR] = useState(false);
  const [id, setid] = useState("");
  const [IDRej, setIdRej] = useState("");


  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allUsers = useSelector(
    (state) => state?.adminReducer?.userList?.user_list
  );
  console.log(allUsers);

  const totalUsers = useSelector(
    (state) => state?.adminReducer?.userList?.totallength
  );
  console.log(totalUsers)
  const lastIndex = page * limit;
  const npage = Math.ceil((totalUsers == undefined ? 0 : totalUsers) / limit);
  const number = npage ? [...Array(npage + 1).keys()]?.slice(1) : [1];

  // useEffect(async() => {
  //   const data = await dispatch(PharmacistAction(page, limit, search));
  //   console.log(data);
  // }, [page, limit, search]);

  const getdata = async() => {
    try{
      const data = await dispatch(PharmacistAction(page, limit, search));
      console.log(data);
    }catch(error){
     console.log(error)
    }
  }


  useEffect(() => {
    getdata();
  },[search,page,limit])

  function prePage() {
    if (page !== 1) {
      setPage(page - 1);
    }
  }
  function changeCPage(id) {
    setPage(id);
  }
  function nextPage() {
    if (page !== npage) {
      setPage(page + 1);
    }
  }

  const invite = useRef();
  const employe = useRef();
  return (
    <Layout>
      <MainPagetitle
        mainTitle="Pharmacist"
        pageTitle={"Pharmacist"}
        parentTitle={"Home"}
      />
      {/* <div className="container-fluid">
        <Link to="/Patient" className="btn btn-secondary ">
          Back
        </Link>
      </div> */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                  <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                    <h4 className="heading mb-0">Pharmacists</h4>
                    <Search setSearch={setSearch} setPage={setPage} />
                  </div>
                  <div
                    id="employee-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyees-tblwrapper"
                      className="table ItemsCheckboxSec dataTable no-footer mb-0"
                    >
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>User Name</th>
                          <th>Email Address</th>
                          <th>Follow UP</th>
                          <th>Gender</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allUsers &&
                          allUsers?.map((item, index) => {
                            console.log(item)
                            return(
                      
                            <tr key={index}>
                              <td>
                                <span>{index + lastIndex - limit + 1}</span>
                              </td>
                              <td>
                                <div className="products">
                                  <img
                                    src={
                                      item?.main_image
                                        ? IMG_URL +
                                          "/user_docs/" +
                                          item?.main_image
                                        : Placholder
                                    }
                                    className="avatar avatar-md"
                                    alt=""
                                  />
                                  <div
                                    className="pointerUser"
                                    onClick={() =>
                                      navigate(`/pharmacist/${item._id} `)
                                    }
                                  >
                                    <h6>{item?.name}</h6>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <a
                                  href={`mailto:${item?.email}`}
                                  style={{ cursor: "auto" }}
                                >
                                  <span className="text-primary">
                                    {item?.email}
                                  </span>
                                </a>
                              </td>
                              <td>{item?.followupPercentage ? `${item.followupPercentage}%` : "N/A" }</td>
                              {/* <td>{item?.followupPercentage ? item?.followupPercentage : "N/A" }</td> */}
                              <td>
                                <span>
                                  {item?.gender == 1
                                    ? "Male"
                                    : item?.gender == 2
                                    ? "Female"
                                    : item?.gender == 3
                                    ? "Not To Say"
                                    : "N/A"}
                                </span>
                              </td>

                              <td>
                                <span
                                  className={`badge light border-0 ${
                                    item?.approval_status === 1
                                      ? "badge-success"
                                      : "badge-danger"
                                  } `}
                                >
                                  {item?.approval_status === 1
                                    ? "Approved"
                                    : item?.approval_status === 0 ||
                                      item?.approval_status === "undefined"
                                    ? "Pending"
                                    : "Rejected"}
                                </span>
                              </td>
                              <td>
                                <span className="badge light border-0 pl-0">
                                  <button
                                    onClick={() => { 
                                     setshow(true)
                                     setid(item?._id)
                                    }}
                                    className="badge border-0 badge-success"
                                  >
                                    Accept
                                  </button>
                                </span>
                                <span className="">
                                  <button
                                    onClick={() => { 
                                      setshowR(true)
                                      setIdRej(item?._id)
                                    }}
                                    className="badge border-0 badge-danger"
                                  >
                                    Reject
                                  </button>
                                </span>
                              </td>
                            </tr>
                                    
                                    )
                          })}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {lastIndex - limit + 1} to{" "}
                        {totalUsers && totalUsers < lastIndex
                          ? totalUsers
                          : lastIndex}{" "}
                        of {totalUsers && totalUsers} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left"/>
                        </Link>
                        <span className="">
                          {number?.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                page === n ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EmployeeOffcanvas ref={employe} Title="Add Employee" />
      <InviteCustomer ref={invite} Title="Invite Employee" />


      <Modal show={show} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">Are you sure you want to Approve this</h2> 
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button class="btn btn-secondary " onClick={async() => {

             const data = await dispatch(
              updatepharmacistProfile({
                pharmacist_id: id,
                approval_status: 1,
              }),
              
            );
            console.log(data);
            if(data?.status == 200){
              toast.success(data?.data?.message);
              setshow(false)
            }else{
              toast.error(data?.data?.message);
            }
            dispatch(
              PharmacistAction(page, limit, search)
            );
          }}>
            Yes
          </Button>
          <Button class="btn btn-secondary " onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>



      <Modal show={showR} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">Are you sure you want to Reject this</h2> 
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button class="btn btn-secondary " onClick={async() => {
            const data = await  dispatch(
                updatepharmacistProfile({
                  pharmacist_id: IDRej,
                  approval_status: 2,
                })
                
              );
              console.log(data);
              if(data?.status == 200){
                toast.success(data?.data?.message);
                setshowR(false)
              }else{
                toast.error(data?.data?.message);
              }
              dispatch(
                PharmacistAction(page, limit, search)
              );
              dispatch(
                PharmacistAction(page, limit, search)
              );
          }}>
            Yes
          </Button>
          <Button class="btn btn-secondary " onClick={() => setshowR(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

export default Pharmacist;
