import React from 'react'
import Layout from '../components/layouts/Layout';
import MainPagetitle from '../components/layouts/MainPagetitle';
import Search from "../components/elements/Search";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate } from "react-router-dom";
import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { ServiceinformationsaveAction, ServicesAction , deleteservice} from '../../store/actions/AdminAction';
import { useEffect } from 'react';
import Placholder from "../../images/placholder-img-admin1.png";
export default function Service() {
  const IMG_URL=process.env.REACT_APP_FILE_BASE_URL
    const [show, setShow] = useState(false);
    const [serviceid, setServiceid] = useState("");
    const [title, setTitle] = useState("Add Service");
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [sho, setsho] = useState(false);
    const [ID, setID] = useState("");
    const[flag,setflag]=useState(true);

    const dispatch=useDispatch();


    const deleteSrvchandler = async (id) => {
      try {
        const data = await dispatch(deleteservice({id:id}));
        console.log(data);
        setsho(false);
        setflag(!flag)
      } catch (error) {
        console.log(error);
      }
    };

      useEffect(() => {
        dispatch(ServicesAction(page, search));
      }, [page, search,flag]);
      const serviceslist=useSelector((state)=>state?.adminReducer?.services)
   const   limit=10;
      const lastIndex = page * limit;
      const npage = Math.ceil((serviceslist?.totallength==undefined?0:serviceslist?.totallength) / limit);
      const number = npage ? [...Array(npage + 1).keys()]?.slice(1) : [1];
    const errors = {};
    const validate = (values) => {
    if (!values.title) {
      errors.title = "Title is Required*";
      
    }else if (!values.icon) {
      errors.icon = "Icon is Required*";
    }
    return errors;
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Required'),
    icon: Yup.string().required('Required'),
    // Add more validation rules for other fields
  });

  const formik = useFormik({
    initialValues: {
        title: "",
         icon: "",
    },
        validationSchema,
 // and this one
    onSubmit: (values) => {
   
     const fomdate=new FormData()
      values.serviceid=serviceid;
      fomdate.set('title',values.title)
      fomdate.set('serviceid',values.serviceid)
      fomdate.set('icon',values.icon)
      dispatch(ServiceinformationsaveAction(fomdate))
      setShow(false)
     
    
    },
  });
  function prePage() {
    if (page !== 1) {
      setPage(page - 1);
    }
  }

  function changeCPage(id) {
    setPage(id);
  }
  function nextPage() {
    if (page !== npage) {
      setPage(page + 1);
    }
  }
  return (
    <Layout>
    <MainPagetitle
      mainTitle="Services"
      pageTitle={"Services"}
      parentTitle={"Home"}
    />
    <div className="container-fluid">
 
   
      <div className="row">
      <div className='mb-3 col-auto float-end ms-auto '>
    <button className='btn btn-secondary ' onClick={()=>{
                                  formik.setFieldValue('title',"")
                                   formik.setFieldValue('icon',"")
                                   setServiceid("")
                                   setTitle("Add Service")
    handleShow()}
    }>Add Services</button>
    </div>
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                  <h4 className="heading mb-0">Services</h4>
                  <Search  setSearch={setSearch} setPage={setPage} />
                </div>
                <div
                  id="employee-tbl_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <table
                    id="empoloyees-tblwrapper"
                    className="table ItemsCheckboxSec dataTable no-footer mb-0"
                  >
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Title</th>
                        <th>Icon</th>
                        <th>Action</th>
                        <th>Delete</th>

                      </tr>
                    </thead>
                    <tbody>
                    {
                      serviceslist?.servicelist?.map(function(service_object,i){
                        return(
                          <tr key={i}>
                            <td>
                              {i+1}
                            </td>
                            <td>
                             {service_object.title}
                            </td>
                            <td>
                            <div className="products">
                                <img
                                  src={
                                    service_object?.icon
                                      ? IMG_URL +"/seviceicon/"+   service_object?.icon
                                      : Placholder
                                  }
                                  className="avatar avatar-md"
                                  alt=""
                                />
                                </div>
                            </td>
                            <td>
                            <span className="badge light border-0 pl-0">
                                <button onClick={()=>{
                                   formik.setFieldValue('title',service_object?.title)
                                   formik.setFieldValue('icon',service_object?.icon)
                                   setServiceid(service_object?._id)
                                    handleShow()
                                    setTitle("Update Service")
                                   
                                }} className="badge border-0 badge-success">
                                <i class="fa fa-pencil"></i>
                                </button>
                              </span>
                            </td>
                            <td>
                            <span
                                  className="badge-danger light border-0 p-2"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => 
                                  {setsho(true) 
                                  setID(service_object?._id) }
                                }
                                >
                                  Delete
                                </span>
                            </td>
                          </tr>
                        )
                      })
                    }
                    </tbody>
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {lastIndex - limit + 1} to{" "}
                        {serviceslist?.totallength && serviceslist?.totallength < lastIndex ? serviceslist?.totallength : lastIndex} of{" "}
                        {serviceslist?.totallength && serviceslist?.totallength} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span className="">
                          {number?.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                page === n ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
        <Modal show={show} onHide={handleClose}>
        <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Service Name"
                autoFocus
                onChange={formik.handleChange}
                 onBlur={formik.handleBlur}
                 name="title"
                 value={formik.values.title}
              />
                {formik.errors.title && (
                <div className="text-danger fs-12 mt-2">
                {formik.errors.title}
                </div>
            )}
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label >File <span className='text-danger'>*</span></Form.Label>
              <Form.Control
                type="file"
                placeholder="Service Name"
                onChange={(e)=>{
                 formik.setFieldValue('icon',e.target.files[0])
                }}
                autoFocus
                onBlur={formik.handleBlur}
                 name="icon"
                
              />
               {formik.errors.icon && (
                <div className="text-danger fs-12 mt-2">
                {formik.errors.icon}
                </div>
                )}
            </Form.Group>
         
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="secondary" type='submit'>
            Save 
          </Button>
        </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={sho} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to Delete 
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={() => deleteSrvchandler(ID)}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setsho(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
  </Layout>
  )
}
