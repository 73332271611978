import Layout from "../components/layouts/Layout";
import React, { useReducer } from "react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import AirplayIcon from "@mui/icons-material/Airplay";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import SchoolIcon from "@mui/icons-material/School";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import TranslateIcon from "@mui/icons-material/Translate";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
/// Image
import Placholder from "../../images/placholder-img-admin1.png";
import profile from "../../images/profile/profile.png";
import uploadImg from "../../images/profile/profile-placeholder.png";
import MainPagetitle from "../components/layouts/MainPagetitle";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changePassword,
  deleteUserAcc,
  updateUserProfile,
  viewUserDetail,
  deleteProduct,
  updateProduct,
  findProducts,
  productListing,
  ProdusctList,
  DropdownCateList,
} from "../../store/actions/AdminAction";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import Buttonreact from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Button, Switch } from "@mui/material";
import Search from "../components/elements/Search";

const ViewProduct = () => {
  const IMG_URL = process.env.REACT_APP_FILE_BASE_URL;
  const [profileImage, setProfileImage] = useState("");

  const DropdownCategorylist = useSelector(
    (state) => state?.adminReducer?.drop_cate_list
  );
  console.log(DropdownCategorylist);

  const handleClose = () => setshow(false);
  // const handleShow = () => setShow(true);
  const [show, setshow] = useState(false);
  const [images, setimages] = useState([]);
  const [imgArr, setImgArr] = useState([]);

  const [limit, setlimit] = useState(10);
  const [page, setpage] = useState(1);
  const [search, setsearch] = useState("");

  const dispatch = useDispatch();
  const location = useLocation();
  console.log(location);
  const [Finddata, setFinddata] = useState(null);
  console.log(Finddata);

  const [videoError, setVideoError] = useState("");

  const navigate = useNavigate();

  const handleImageChange = (e) => {
    // const files = Array.from(e.target.files);
    // setimages(files);
    // setFormData((old) => ({ ...old, productimage: e.target.files[0] }));
    const files = e.target.files;

    const selectedImages = [];

    Array.from(files).forEach((file) => {
      if (selectedImages.length < 3 && file.type.startsWith("image/")) {
        selectedImages.push(file);
      }
    });

    setimages(selectedImages);
  };

  const [video, setvideo] = useState([]);

  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    console.log("Selected file:", file);
    if (file) {
      const videoElement = document.createElement("video");
      videoElement.src = URL.createObjectURL(file);
      videoElement.onloadedmetadata = () => {
        if (videoElement.duration > 60) {
          setVideoError("Max 60 sec video only");
          setFormData((old) => ({ ...old, video: null }));
        } else {
          // Clear any previous error
          setVideoError("");

          const existingVideoElement = document.querySelector("video");
          if (existingVideoElement) {
            existingVideoElement.src = videoElement.src;
          } else {
            // Append the new video element to the preview area
            const previewContainer = document.querySelector(".image-container");
            if (previewContainer) {
              previewContainer.innerHTML = ""; // Clear previous video if any
              videoElement.width = "200";
              videoElement.height = "170";
              videoElement.controls = true;
              videoElement.style.objectFit = "cover";
              previewContainer.appendChild(videoElement);
            }
          }
          // Update form data with the selected video file
          setFormData((old) => ({ ...old, video: file }));
        }
      };
    }
  };

  const deletePRoductHandler = async () => {
    try {
      const data = await dispatch(
        deleteProduct({ product_id: location?.state?.id })
      );
      console.log(data);
      setshow(false);
      navigate("/Products");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(DropdownCateList());
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await dispatch(findProducts({ id: location?.state?.id }));
        console.log(data);
        if (data?.data?.status === 200) {
          setFinddata(data?.data?.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (Finddata) {
      console.log(Finddata.productimage, "inddata.productimage");
      setFormData({
        title: Finddata.title || "",
        productoverview: Finddata.productoverview || "",
        product_description: Finddata.product_description || "",
        product_category_id: Finddata.product_category_id || "",
        product_id: Finddata.product_id || "",
        price: Finddata.price || "",
        productimage: Finddata.productimage.map((x) => x) || [],
        video: Finddata.video || [],
      });
    }
  }, [Finddata]);

  const [formData, setFormData] = useState({
    title: "",
    productoverview: "",
    product_description: "",
    product_category_id: "",
    product_id: "",
    price: "",
    productimage: null,
    video: null,
  });

  const [error, seterror] = useState({
    title: "",
    productoverview: "",
    product_description: "",
    product_category_id: "",
    product_id: "",
    price: "",
    productimage: "",
  });

  const customValidation = () => {
    let errors = {};

    if (!formData.title) {
      errors.title = "Required";
    }
    if (!formData.productoverview) {
      errors.productoverview = "Required";
    }
    if (!formData.product_description) {
      errors.product_description = "Required";
    }
    if (!formData.product_category_id) {
      errors.product_category_id = "Required";
    }
    if (!formData.price) {
      errors.price = "Required";
    }

    return errors;
  };

  const handlerChange = (e) => {
    const { name, value } = e.target;
    setFormData((old) => ({
      ...old,
      [name]: value,
    }));
  };

  const handleUpdateProduct = async () => {
    const isFormEmpty = Object.values(formData).some((value) => value === "");

    if (isFormEmpty) {
      const errors = customValidation();

      if (Object.keys(errors).length > 0) {
        seterror(errors);
        return;
      }
    }

    const formDataObj = new FormData();
    formDataObj.append("title", formData.title);
    formDataObj.append("productoverview", formData.productoverview);
    formDataObj.append("product_description", formData.product_description);
    formDataObj.append("product_category_id", formData.product_category_id);
    formDataObj.append("price", formData.price);
    formDataObj.append("product_id", location?.state?.id);
    imgArr?.forEach((image, index) => {
      console.log(image);
      formDataObj.append(`productimage`, image);
    });
    formDataObj.append("video", formData.video);
    console.log(formDataObj);
    try {
      const data = await dispatch(updateProduct(formDataObj));
      console.log(data, "whiefoiwerjpfk[pqeklfdqw[kfdlq");
      navigate("/products");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let demo = images?.map((x) => x);
    console.log(demo);
    setImgArr(demo);
  }, [images]);

  console.log(formData?.video, "==========================");

  return (
    <Layout>
      <div>
        <MainPagetitle
          mainTitle="Products"
          pageTitle="Products View"
          parentTitle="Products"
        />
        <div className="container-fluid ">
          <div className="row">
            <div className="col-lg-6">
              <Link to="/products" className="btn btn-secondary ">
                Back
              </Link>
            </div>
            <div className="col-lg-6 d-flex justify-content-end">
              <button
                className="btn btn-secondary "
                type="submit"
                onClick={handleUpdateProduct}
              >
                Save
              </button>
            </div>
            <div className="col-lg-12 mt-4">
              <div className="profile card card-body px-3 pt-3 pb-0">
                <div className="profile-head">
                  <div className="photo-content ">
                    {/* <div className="cover-photo rounded"></div> */}
                  </div>
                  <div className="profile-info">
                    <div className="card-body">
                      <div className="basic-form">
                        <form onSubmit={(e) => e.preventDefault()}>
                          <div className="row">
                            <div className="form-group mb-3 col-md-6">
                              <label>Product name</label>
                              <input
                                type="text"
                                name="title"
                                className="form-control"
                                placeholder="Type Here"
                                value={formData.title}
                                onChange={handlerChange}
                              />
                              {error.title && formData?.title === "" && (
                                <p style={{ color: "red" }}>{error.title}</p>
                              )}
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label>Product Overview</label>
                              <input
                                type="text"
                                name="productoverview"
                                className="form-control"
                                placeholder="Type Here"
                                value={formData.productoverview}
                                onChange={handlerChange}
                              />
                              {error.productoverview &&
                                formData?.productoverview === "" && (
                                  <p style={{ color: "red" }}>{error.title}</p>
                                )}
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label>Product Description</label>
                              <textarea
                                rows={3}
                                name="product_description"
                                className="form-control"
                                placeholder="Type Here"
                                value={formData.product_description}
                                onChange={handlerChange}
                              />
                              {error.product_description &&
                                formData?.product_description === "" && (
                                  <p style={{ color: "red" }}>{error.title}</p>
                                )}
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label>Categories</label>
                              <select
                                class="form-select"
                                name="product_category_id"
                                aria-label="Default select example"
                                value={formData.product_category_id}
                                onChange={handlerChange}
                              >
                                <option value="" selected>
                                  Select Categories
                                </option>
                                {DropdownCategorylist?.map((data, i) => {
                                  // console.log(data?._id);
                                  return (
                                    <option value={data?._id}>
                                      {data?.title}
                                    </option>
                                  );
                                })}
                              </select>
                              {error.product_category_id &&
                                formData.product_category_id === "" && (
                                  <p style={{ color: "red" }}>
                                    {error.product_category_id}
                                  </p>
                                )}
                            </div>
                            <div className="form-group mb-3 col-md-6 mt-2">
                              <label>Price</label>
                              <input
                                type="number"
                                name="price"
                                className="form-control"
                                placeholder="Type Here"
                                value={formData?.price}
                                onChange={handlerChange}
                              />
                              {error.price && formData?.price === "" && (
                                <p style={{ color: "red" }}>{error.title}</p>
                              )}
                            </div>{" "}
                            <div className="form-group mb-3 col-md-6 mt-4"></div>
                            <div className="form-group mb-3 col-md-6">
                              <label>Add Option video (max 60 seconds.)</label>
                              <div className="user_detail_img_inner">
                                <img
                                  src={require("../../images/uploadimage.png")}
                                />
                                <div className="main-img-contain">
                                  <div className="image-container">
                                    {formData?.video && (
                                      <video
                                        width="200"
                                        height="150"
                                        controls
                                        style={{ objectFit: "cover" }}
                                      >
                                        <source
                                          src={`https://api.telepharmacare.com/public/production/${formData?.video}`}
                                          type="video/mp4"
                                        />
                                      </video>
                                    )}
                                  </div>
                                </div>

                                <input
                                  className="user_detail_upload"
                                  id="formFileSm"
                                  type="file"
                                  name="video"
                                  onChange={handleVideoChange}
                                  accept="video/*"
                                  hidden
                                />

                                {videoError && (
                                  <p style={{ color: "red" }}>{videoError}</p>
                                )}
                              </div>
                              <br />
                              <label
                                htmlFor="formFileSm"
                                className="add-more-image"
                              >
                                <i sty className="fas fa-pencil-alt"></i> Edit
                                Video
                              </label>
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label>Product Images</label>
                              <div className="user_detail_img_inner w-100">
                                <img
                                  src={require("../../images/uploadimage.png")}
                                />

                                <input
                                  className="user_detail_upload"
                                  id="formFileSm1"
                                  type="file"
                                  name="productimage"
                                  onChange={(e) => {
                                    const files = e.target.files;

                                    const selectedImages = [];

                                    Array.from(files).forEach((file) => {
                                      if (selectedImages.length <= 3) {
                                        selectedImages.push(file);
                                      }
                                    });
                                    if (selectedImages.length > 3) {
                                      toast.error("You can upload a maximum of 3 images.");
                                      return;
                                    }

                                    setimages(selectedImages);
                                  }}
                                  // value={formData?.productimage}
                                  accept="image/*"
                                  multiple
                                />

                                <div className="main-img-contain">
                                  {images[0]
                                    ? images.map((image, index) => (
                                        <div
                                          key={index}
                                          className="image-container"
                                        >
                                          <img
                                            src={URL.createObjectURL(image)}
                                            alt={`Preview ${index}`}
                                          />
                                        </div>
                                      ))
                                    : Finddata?.productimage?.map(
                                        (img, index) => {
                                          return (
                                            <div
                                              key={index}
                                              className="image-container"
                                            >
                                              <img
                                                src={`https://api.telepharmacare.com/public/production/${img}`}
                                                alt={`Preview ${index}`}
                                              />
                                            </div>
                                          );
                                        }
                                      )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-8">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Delete Product</h4>
                </div>
                <div className="card-body">
                  <div className="basic-form">
                    <h6>
                      Once you delete the Product, there is no going back.
                      Please be certain.
                    </h6>
                    <div className="row align-items-center">
                      <div className="col-auto">
                        <label className="sr-only">Name</label>
                      </div>
                      <div className="col-12">
                        <div className="form-check custom-checkbox mb-3 checkbox-success">
                          <button
                            className="btn btn-danger mb-2"
                            onClick={() => setshow(true)}
                            // onClick={handleShow}
                          >
                            Delete Product
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>

      {/* ================ Delete Product ==================== */}

      <Modal show={show} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">Are you sure you want to Delete</h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button class="btn btn-secondary " onClick={deletePRoductHandler}>
            Yes
          </Button>
          <Button class="btn btn-secondary " onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

export default ViewProduct;
