import React,{useContext, useEffect} from 'react';
import { ThemeContext } from "../../context/ThemeContext";
import Layout from "../components/layouts/Layout";
import MainPagetitle from '../components/layouts/MainPagetitle';
import CardWidget from '../components/elements/CardWidget';
import ToDoList from '../components/elements/ToDoList';
import ActiveUserMap from '../components/elements/ActiveUserMap';
import ProjectStatusBlog from '../components/elements/ProjectStatusBlog';
import EarningChart from '../components/elements/EarningChart';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardction } from '../../store/actions/AdminAction';
const Dashboard = () =>{
    const dispatch=useDispatch()
    useEffect(()=>{
        dispatch(dashboardction())
    },[])
    const userDetail = useSelector((state) => state?.adminReducer?.dashboard_data);
    return (
        <>	
        <Layout>
        <MainPagetitle mainTitle="Dashboard" pageTitle="Dashboard" parentTitle="Home"  />
        <div className="container-fluid">
            <div className="row">
                <div className="col-xl-9 wid-100">
                    <div className="row">
                        <CardWidget userDetail={userDetail} />
                        <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header border-0 pb-0 ">
                                <h4 className="heading mb-0">Monthly Registration</h4>
                            </div>                
                            <EarningChart userDetail={userDetail} />
                        </div>
                        </div>
                     

                    </div>
                </div>
          
     

                <div className="col-xl-3 col-md-6 up-shd">
                    <ProjectStatusBlog  title="Platforms"/>
                </div>
               
            </div>							
        </div>		
         </Layout>
    </>
    )
}

export default Dashboard;