import React, { useEffect, useState } from "react";
import Layout from "../components/layouts/Layout";
import { IMAGES } from "../constant/theme";
import MainPagetitle from "../components/layouts/MainPagetitle";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaqAction, getFAQs } from "../../store/actions/AdminAction";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { Button, Switch } from "@mui/material";
import { Form } from "react-bootstrap";
import { chatStatus } from "../../store/actions/AdminAction";
import { Details } from "@mui/icons-material";

const FAQ = () => {
  const faqData = useSelector((state) => state?.getfaqs?.faqs);
  console.log(faqData);
  const [limit, setlimit] = useState(10);
  const [page, setpage] = useState(0);
  // const [count, setPage] = useState(0);
  const [flag, setflag] = useState(false);
  const [search, setsearch] = useState("");
  const [pagi, setpagi] = useState([]);

  const [show, setshow] = useState(false);

  const handleShow = () => setshow(true);
  const [ID, setID] = useState("");
  const [value, setvalue] = useState("");

  const lastIndex = (page + 1) * limit;
  const npage = Math.ceil((pagi?.count || 0) / limit);
  const number = npage ? [...Array(npage + 1).keys()]?.slice(1) : [1];
  console.log(number, "num");

  function prePage() {
    if (page > 0) {
      setpage(page - 1);
    }
  }
  function changeCPage(id) {
    setpage(id);
  }
  function nextPage() {
    if (page < npage - 1) {
      setpage(page + 1);
    }
  }

  const showingFrom = page * limit + 1;
  const showingTo = pagi?.count && pagi?.count < lastIndex ? pagi?.count : lastIndex;

  const handlerAction = async (e) => {
    const data = await dispatch(
      FaqAction({
        id: ID,
        status: value,
      })
    );
    if (data?.status === 200) {
      toast.success(data?.data?.message);
      setshow(false);
      setflag(!flag);
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const getdata = async () => {
      const data = await dispatch(getFAQs({ limit: limit, page: page }));
      console.log(data);
      if (data?.status === 200) {
        setpagi(data?.data);
      }
    };
    getdata();
  }, [limit, page, flag]);

  const navigate = useNavigate();

  const handlerOrderID = (userid) => {
    navigate("/faq-chat", {
      state: {
        id: userid,
      },
    });
  };

  return (
    <Layout>
      <MainPagetitle
        mainTitle="FAQ Support"
        pageTitle="FAQ Support"
        parentTitle="Home"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects support manage-client">
                  <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                    <h4 className="heading mb-0">FAQ Support</h4>
                    {/* <div>
                                            <CSVLink {...csvlink} className="btn btn-primary light btn-sm">
                                                <i className="fa-solid fa-file-excel" /> {" "} 
                                                Export Report
                                            </CSVLink> 
                                        </div> */}
                  </div>
                  <div
                    id="manage-tblwrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="reports-tbl"
                      className="table ItemsCheckboxSec dataTable no-footer mb-0"
                    >
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Date</th>
                          <th>Question</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {faqData?.map((data, index) => {
                          const serialNumber = (page - 0) * limit + index + 1;
                          console.log(data);
                          return (
                            <tr>
                              <td>{serialNumber}</td>
                              {/* <td >{`${data?.first_name} ${data?.last_name}`}</td> */}
                              <td style={{ cursor: "pointer" }}>
                                <p onClick={() => handlerOrderID(data?._id)}>
                                  <h6>{`${data?.first_name} ${data?.last_name}`}</h6>
                                </p>
                              </td>
                              <td>{data?.email}</td>
                              <td>
                                {data?.updatedAt
                                  ? new Date(data?.updatedAt)
                                      .toISOString()
                                      .slice(0, 10)
                                  : "N/A"}
                              </td>
                              <td className="text-overlap">
                                <span>
                                  {data?.question ? data?.question : "N/A"}
                                </span>
                              </td>
                              <td>{data?.status === 0 ? "Open" : "Closed"}</td>
                              <td className="select-box">
                                <Form.Select
                                  aria-label="Default select example"
                                  value={data?.status}
                                  onChange={(e) => {
                                    setshow(true);
                                    setID(data?._id);
                                    setvalue(e.target.value);
                                  }}
                                >
                                  {/* <option value="">Select</option>
                                  <option value="0">Open</option>
                                  <option value="1">Close</option> */}
                                  {data?.status === 0 && (
                                    <>
                                      <option value="">Select</option>
                                      <option value="0">Open</option>
                                      <option value="1">Close</option>
                                    </>
                                  )}

                                  {data?.status === 1 && (
                                    <>
                                      <option value="">Select</option>
                                      <option value="1">Close</option>
                                    </>
                                  )}
                                  {/* {data?.status === 3 && (
                                    <>
                                      <option value="3">Closed</option>
                                    </>
                                  )}
                                    {data?.status === 0 && (
                                    <>
                                      <option value="3">Closed</option>
                                    </> */}
                                  {/* )} */}
                                </Form.Select>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                      Showing {showingFrom} to {showingTo} of {pagi?.count || 0}
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                page === i ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(i)}
                            >
                              {i + 1}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to change the Status
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handlerAction}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

export default FAQ;
