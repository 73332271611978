import React from "react";
import Layout from "../components/layouts/Layout";
import MainPagetitle from "../components/layouts/MainPagetitle";
import Search from "../components/elements/Search";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useState } from "react";
import { ErrorMessage, useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { ProdusctList } from "../../store/actions/AdminAction";
import { addProductCategory } from "../../store/actions/AdminAction";
import { updateProductcategoty } from "../../store/actions/AdminAction";
import Pagination from "../components/layouts/Pagination";
// import deleteicon from "../"
import {
  ServiceinformationsaveAction,
  ServicesAction,
  deleteProductcate,
} from "../../store/actions/AdminAction";
import { useEffect } from "react";
import Placholder from "../../images/placholder-img-admin1.png";
export default function ProductCategories() {
  const IMG_URL = process.env.REACT_APP_FILE_BASE_URL;
  const [categoryid, setcategoryid] = useState("");
  const [title, setTitle] = useState("Add Category");
  const [producticonURL, setProducticonURL] = useState("");

  const [show, setshow] = useState(false);
  
  const handleClose = () => setshow(false);
  const handleShow = () => setshow(true);
  const [sho, setsho] = useState(false);
  const [ID, setID] = useState("");
  const [flag, setflag] = useState(true);
  // const flagRef = useRef(false);

  const ProductCategoriesList = useSelector(
    (state) => state?.adminReducer?.Product_list
  );
  console.log(ProductCategoriesList)
  const [page, setPage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState("");
  const lastIndex = page * limit;
  const npage = Math.ceil(
    (ProductCategoriesList?.totalCount == undefined
      ? 0
      : ProductCategoriesList?.totalCount) / limit
  );
  const number = npage ? [...Array(npage + 1).keys()]?.slice(1) : [1];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ProdusctList({ limit: limit, page: page, title: search }));
  }, [page, limit, flag, search]);

  const deletePRoductHandler = async (id) => {
    try {
      const data = await dispatch(deleteProductcate({ category_id: id }));
      console.log(data);
      setsho(false);
      setflag(!flag);
    } catch (error) {
      console.log(error);
    }
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
    producticon: Yup.string().required("Required"),
    // Add more validation rules for other fields
  });

  

  const formik = useFormik({
    initialValues: {
      title: "",
      producticon: "",
    },
    validationSchema,
    // and this one
    onSubmit: (values) => {
      const fomdate = new FormData();
      // values.serviceid = serviceid;
      fomdate.set("title", values.title);
      // fomdate.set("serviceid", values.serviceid);
      fomdate.set("producticon", values.producticon);

      if (categoryid != "") {
        fomdate.set("id", categoryid);
        dispatch(updateProductcategoty(fomdate)).then((res) => {
          dispatch(ProdusctList({ limit: limit, page: page, title: search }));
          if (res?.data?.status == 200) {
            setflag(!flag);
          }
        });
        formik.setFieldValue("title", "");
        formik.setFieldValue("icon", "");
        setcategoryid("");
      } else {
        dispatch(addProductCategory(fomdate)).then((res) => {
          console.log(res, "res");
          dispatch(ProdusctList({ limit: limit, page: page, title: search }));
          formik.setFieldValue("title", "");
          formik.setFieldValue("icon", "");
          if (res?.data?.status == 200) {
            setflag(!flag);
          }
        });
      }

      setshow(false);
    },
  });

  const handlelimit = (e) => {
    setlimit(e.target.value);
  };

  const handlesearch = (e) => {
    setsearch(e.target.value);
  };

  function prePage() {
    if (page !== 1) {
      setPage(page - 1);
    }
  }
  function changeCPage(id) {
    setPage(id);
  }
  function nextPage() {
    if (page !== npage) {
      setPage(page + 1);
    }
  }
  console.log(categoryid, "categoryidcategoryidcategoryid");

  return (
    <Layout>
      <MainPagetitle
        mainTitle="Product Categories"
        pageTitle="Product Categories"
        parentTitle={"Product"}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="mb-3 col-auto float-end ms-auto ">
            <button
              className="btn btn-secondary "
              onClick={() => {
                formik.setFieldValue("title", "");
                formik.setFieldValue("icon", "");
                setcategoryid("");
                setTitle("Add Category");
                handleShow();
              }}
            >
              Add Category
            </button>
          </div>
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                  <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                    <h4 className="heading mb-0">Product Categories</h4>
                    <Search setSearch={setsearch} setPage={setPage} />
                  </div>
                  <div
                    id="employee-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyees-tblwrapper"
                      className="table ItemsCheckboxSec dataTable no-footer mb-0"
                    >
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Title</th>
                          <th>Icon</th>
                          <th>Action</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ProductCategoriesList?.data?.map((details, index) => {
                          console.log(details?._id);
                          const serialNumber = (page - 1) * limit + index + 1;
                          return (
                            <tr>
                              <td>{serialNumber}</td>
                              <td>{details?.title ? details?.title : "N/A"}</td>
                              <td>
                                <div className="products">
                                  <img
                                    src={
                                      details?.producticon
                                        ? IMG_URL +
                                          "/public/production/" +
                                          details?.producticon
                                        : Placholder
                                    }
                                    className="avatar avatar-md"
                                    alt=""
                                  />
                                </div>
                              </td>
                              <td>
                                <span className="badge light border-0 pl-0">
                                  <button
                                    onClick={() => {
                                      formik.setFieldValue(
                                        "title",
                                        details?.title
                                      );
                                      formik.setFieldValue(
                                        "icon",
                                        details?.producticon
                                      );
                                      setcategoryid(details?._id);
                                      handleShow();
                                      setTitle("Update Service");
                                    }}
                                    className="badge border-0 badge-success"
                                  >
                                    <i class="fa fa-pencil"></i>
                                  </button>
                                </span>
                              </td>
                              <td>
                                <span
                                  className="badge-danger light border-0 p-2"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    // deletePRoductHandler(details?._id)

                                    {
                                      setsho(true);
                                      setID(details?._id);
                                    }
                                  }
                                >
                                  Delete
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        {/* {`Showing ${startIndex} to ${endIndex} of ${totalEntries} entries`} */}
                        Showing {lastIndex - limit + 1} to{" "}
                        {ProductCategoriesList?.totalCount &&
                        ProductCategoriesList?.totalCount < lastIndex
                          ? ProductCategoriesList?.totalCount
                          : lastIndex}{" "}
                        of{" "}
                        {ProductCategoriesList?.totalCount &&
                          ProductCategoriesList?.totalCount}{" "}
                        entries
                      </div>

                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span className="">
                          {number?.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                page === n ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Category Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="title"
                value={formik.values.title}
                autoFocus
              />
              {formik.submitCount > 0 && formik.errors.title && (
                <div className="text-danger fs-12 mt-2">
                  {formik.errors.title}
                </div>
              )}
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>
                File <span className="text-danger"></span>
              </Form.Label>
              <Form.Control
                type="file"
                placeholder="Category Name"
                onChange={(e) => {
                  formik.setFieldValue("producticon", e.target.files[0]);
                }}
                autoFocus
                onBlur={formik.handleBlur}
                name="producticon"
              />
              {formik.submitCount > 0 && formik.errors.producticon && (
                <div className="text-danger fs-12 mt-2">
                  {formik.errors.producticon}
                </div>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-secondary " onClick={handleClose}>
              Cancel
            </Button>
            <Button className="btn btn-secondary " type="submit">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={sho} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">Are you sure you want to Delete</h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button
            class="btn btn-secondary"
            onClick={() => deletePRoductHandler(ID)}
          >
            Yes
          </Button>
          <Button class="btn btn-secondary" onClick={() => setsho(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
