import React, { useEffect, useState } from "react";
import Layout from "../components/layouts/Layout";
import { IMAGES } from "../constant/theme";
import MainPagetitle from "../components/layouts/MainPagetitle";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { getpromotionlist } from "../../store/actions/AdminAction";
import { useDispatch, useSelector } from "react-redux";
import { date } from "yup";
import { DeletePromotion } from "../../store/actions/AdminAction";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

const tableData = [
  {
    id: "1",
    date: "10-10-24",
    name: "John Antony",
    Role: "Web Designer",

    permissions: "Full Access",
    active: "10-10-24",

    actionedit: "Edit",
    actiondelete: "Delete",
  },
  {
    id: "2",
    date: "10-10-24",
    name: "John Antony",
    Role: "Web Designer",

    permissions: "Full Access",
    active: "10-10-24",

    actionedit: "Edit",
    actiondelete: "Delete",
  },
  {
    id: "3",
    date: "10-10-24",
    name: "John Antony",
    Role: "Web Designer",

    permissions: "Full Access",
    active: "10-10-24",

    actionedit: "Edit",
    actiondelete: "Delete",
  },
  {
    id: "4",
    date: "10-10-24",
    name: "John Antony",
    Role: "Web Designer",

    permissions: "Full Access",
    active: "10-10-24",

    actionedit: "Edit",
    actiondelete: "Delete",
  },
  {
    id: "5",
    date: "10-10-24",
    name: "John Antony",
    Role: "Web Designer",

    permissions: "Full Access",
    active: "10-10-24",

    actionedit: "Edit",
    actiondelete: "Delete",
  },
  {
    id: "6",
    date: "10-10-24",
    name: "John Antony",
    Role: "Web Designer",

    permissions: "Full Access",
    active: "10-10-24",

    actionedit: "Edit",
    actiondelete: "Delete",
  },
  {
    id: "7",
    date: "10-10-24",
    name: "John Antony",
    Role: "Web Designer",

    permissions: "Full Access",
    active: "10-10-24",

    actionedit: "Edit",
    actiondelete: "Delete",
  },
  {
    id: "8",
    date: "10-10-24",
    name: "John Antony",
    Role: "Web Designer",

    permissions: "Full Access",
    active: "10-10-24",

    actionedit: "Edit",
    actiondelete: "Delete",
  },
  {
    id: "9",
    date: "10-10-24",
    name: "John Antony",
    Role: "Web Designer",

    permissions: "Full Access",
    active: "10-10-24",

    actionedit: "Edit",
    actiondelete: "Delete",
  },
];

const headers = [
  { label: "Name", key: "name" },
  { label: "Designation", key: "designation" },
  { label: "User Name", key: "username" },
  { label: "Contact", key: "contact" },
  { label: "Gender", key: "gender" },
  { label: "Location", key: "location" },
  { label: "action", key: "action" },
];

const csvlink = {
  headers: headers,
  data: tableData,
  filename: "csvfile.csv",
};

const Promotion = () => {
  const promotiondata = useSelector(
    (state) => state?.Promotion_list?.getpromotionlist
  );
  console.log(promotiondata);

  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [showd, setshowd] = useState(false);
  const[id,setid]=useState("");
  const[flag,setflag]=useState(false);
  const[pagination,setpagination]=useState("");
  console.log(pagination,"skf;owseghijpserjogwoehpi")

  const [Page, setCurrentPage] = useState(1);
  // const recordsPage = 10;
  // const lastIndex = currentPage * recordsPage;
  // const firstIndex = lastIndex - recordsPage;
  // const records = tableData.slice(firstIndex, lastIndex);
  const lastIndex = page * limit;
  const npage = Math.ceil(
    (pagination?.totalPromotions == undefined ? 0 : pagination?.totalPromotions) /
      limit
  );
  const number = npage ? [...Array(npage + 1).keys()]?.slice(1) : [1];

  function prePage() {
    if (page !== 1) {
      setpage(page - 1);
    }
  }
  function changeCPage(id) {
    setpage(id);
  }
  function nextPage() {
    if (page !== npage) {
      setpage(page + 1);
    }
  }

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchdata = async() => {
      try{
        const data = await dispatch(getpromotionlist({page:page,limit:limit}));
        console.log(data);
        setpagination(data?.data)
      }catch(error){
        console.log(error)
      }
    }
    fetchdata();
  }, [page,limit,flag]);  

  const deletePromotionhandler = async () => {
    try {
      const data = await dispatch(DeletePromotion({Promotion_id:id}));
      console.log(data);
      if (data?.status == 200) {
        toast.success(data?.data?.message);
        setshowd(false);
      } else {
        toast.error(data?.data?.message);
      }
      setflag(!flag);
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(totalPromotions?.totalPromotions);

  return (
    <Layout>
      <MainPagetitle
        mainTitle="Promotions"
        pageTitle="Promotions"
        parentTitle="Home"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="mb-3 col-auto float-end ms-auto ">
            <Link to="/create-promotion" className="btn btn-secondary ">
              Create New
            </Link>
          </div>
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects manage-client">
                  <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                    <h4 className="heading mb-0">Promotions</h4>
                    {/* <div>
                                            <CSVLink {...csvlink} className="btn btn-primary light btn-sm">
                                                <i className="fa-solid fa-file-excel" /> {" "} 
                                                Export Report
                                            </CSVLink> 
                                        </div> */}
                  </div>
                  <div
                    id="manage-tblwrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="reports-tbl"
                      className="table ItemsCheckboxSec dataTable no-footer mb-0"
                    >
                      <thead>
                        <tr>
                          <th>S.no</th>
                          <th className="text-center">Promotion Image</th>
                          <th>action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {promotiondata?.map((data, index) => {
                          const serialNumber = (page - 1) * limit + index + 1;
                          // console.log(data);
                          return (
                            <tr>
                              <td>{serialNumber}</td>
                              <td>
                                {" "}
                                <div className="promo-banner-img">
                                  <img
                                    src={`https://api.telepharmacare.com/production/${data?.promotion_image}`}
                                  />
                                </div>
                              </td>
                              <td style={{ textAlign: "left" }}>
                                <button
                                  className="badge-danger light border-0 p-2"
                                  onClick={() => {
                                    setshowd(true);
                                    setid(data?._id);
                                  }}
                                >
                                  {data.actiondelete}
                                  Delete
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {lastIndex - limit + 1} to{" "}
                        {pagination?.totalPromotions &&
                        pagination?.totalPromotions < lastIndex
                          ? pagination?.totalPromotions
                          : lastIndex}{" "}
                        of{" "}
                        {pagination?.totalPromotions && pagination?.totalPromotions}{" "}
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                page === n ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showd} animation={false} className="delete-popup">
      <Modal.Body className="text-center">
        <h2 className="are-sure-text">
          Are you sure you want to Delete this Account
        </h2>
      </Modal.Body>
      <Modal.Footer className="justify-content-center border-none">
        <Button className="theme-btn" onClick={deletePromotionhandler}>
          Yes
        </Button>
        <Button className="theme-btn" onClick={() => setshowd(false)}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
    </Layout>
  );
};

export default Promotion;
