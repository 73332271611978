import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import {Nav, Tab} from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { dashboardction } from "../../../store/actions/AdminAction";


const  EarningChart = ({userDetail}) =>{
	const earningRef = useRef();
	const chartWidth = useRef(null);
	const [width, setWidth] = useState(0);
	useLayoutEffect(() => {
		setWidth(chartWidth.current.offsetWidth);
	}, []);

    const  series = [
        {
          name: "Desktops",
          data: userDetail?.monthlyregistration,
        },
    ];
   const options = {
        chart: {
            type: 'area',
			height: 350,        	
            width: width + 55,
			offsetX : -45,
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false
            },           
        },
        colors:['var(--primary)'],
       
		dataLabels: {
		  enabled: false,
		},
        legend: {
			show: false,
		},
		stroke: {
            show: true,
            width: 2,
            curve:'straight',
            colors:['var(--primary)'],
        },
		grid: {
			show:true,
			borderColor: '#eee',
			xaxis: {
				lines: {
					show: true
				}
			},   
			yaxis: {
				lines: {
					show: false
				}
			},  
		},
		yaxis: {			
			show: true,
			tickAmount: 4,
			min: 0,
			max: 800,
			labels:{
				offsetX:50,
			}
		},
		xaxis: {
			
			categories: ['','jan ','Feb ','March ','April ','May ', 'June', 'July', 'Aug', 'Sep ', 'Oct','Nov ','Dec ', ""],
			overwriteCategories: undefined,
			axisBorder: {
				show: false,
			},
			axisTicks: {
				show: false
			},
			labels: {
				show: true,
				// offsetX:5,
				style: {
					fontSize: '12px',

				}
			},
		},
		fill: {
		  opacity: 0.5,
		  colors:'var(--primary)',
		  type: 'gradient', 
		  gradient: {
			colorStops:[ 
				{
				  offset: 0.6,
				  color: 'var(--primary)',
				  opacity: .2
				},
				{
				  offset: 0.6,
				  color: 'var(--primary)',
				  opacity: .15
				},
				{
				  offset: 100,
				  color: 'white',
				  opacity: 0
				}
			  ],
			  
			}
		},
		tooltip: {
			enabled:true,
			style: {
				fontSize: '12px',
			},
			y: {
				formatter: function(val) {
					return   val 
				}
			}
		}
   }   


    return (
		<div className="card-body px-0 overflow-hidden">
			<div className="total-earning">
			<div id="earningChart" ref={chartWidth}>
						<ReactApexChart
							options={options}
							series={series}
							type="area"
							height={350}	
							ref={earningRef}
							width={width+55}
						/>
					</div>
				{/* <h2>$6,743.00</h2> */}
		
			</div>	
		</div>		
    );
  
}

export default EarningChart;
