import React,{useState} from 'react';
import ProjectStatusBlogDonutChart from './ProjectStatusBlogDonutChart';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { dashboardction } from '../../../store/actions/AdminAction';

const mediaBlog = [

    {title: 'iOS', color: "var(--primary)"},
    {title: 'Android', color: "#3AC977"},
  
   
];
const ProjectStatusBlog = ({title}) => {

    
   
    const dispatch=useDispatch()
    useEffect(()=>{
        dispatch(dashboardction())
    },[])
    const userDetail = useSelector((state) => state?.adminReducer?.dashboard_data);
    
    return (
        <>
            <div className="card">
                <div className="card-header pb-0 border-0">
                    <h4 className="heading mb-0">{title}</h4>                    
                    
                </div>
                <div className="card-body ">
                    
                    <ProjectStatusBlogDonutChart  userDetail={userDetail} />
                    <div className="project-date">
           
                     
                            <div className="project-media" >
                                <p className="mb-0">
                                    <svg className="me-2" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect y="0.5" width="12" height="12" rx="3" fill='var(--primary)'/>
                                    </svg>{" "}
                                    iOS
                                </p>
                                <div className="progress">
                                            <div className="progress-bar bg-primary" style={{width:"200px", height:"5px", borderRadius:"4px"}} ></div>
                                        </div>
                                <span>{`${userDetail?.iosregistration}`} Registered</span>
                            </div>	

                            <div className="project-media" >
                                <p className="mb-0">
                                    <svg className="me-2" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect y="0.5" width="12" height="12" rx="3" fill='#3AC977'/>
                                    </svg>{" "}
                                    Android
                                </p>
                                <div className="progress">
                                            <div className="progress-bar bg-warning" style={{width:"200px", height:"5px", borderRadius:"4px",color:""}} ></div>
                                        </div>
                                <span>{`${userDetail?.androidregistration}`} Registered</span>
                                </div>            
                    </div>
                </div>
            </div>  
        </>
    );
};

export default ProjectStatusBlog;