import Layout from "../components/layouts/Layout";
import React, { useReducer, useState } from "react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import MainPagetitle from "../components/layouts/MainPagetitle";
import MyEditor from "../components/elements/TextEditor";
import draftToHtml from "draftjs-to-html";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { addBlogs } from "../../store/actions/AdminAction";
import { useNavigate } from "react-router-dom";

const CreateBlog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [titleerr, setTitleerr] = useState("");
  const [htmlEntity, setHtmlEntity] = useState("");
  const [htmlContent, setHtmlContent] = useState();
  const [htmlContenterr, setHtmlContenterr] = useState();
  const [status, setstatus] = useState();
  const [statuserr, setstatuserr] = useState();
  const [prevImages, setprevImages] = useState(null);
  const [prevImageserr, setprevImageserr] = useState(null);
  // const [image, setImage] = useState(null);
  const [images, setImages] = useState([]);
  const [imagesErr, setImagesErr] = useState("");

  const dataHtmlHandle = (html) => {
    console.log(html, "html");

    setHtmlEntity(html);
    setHtmlContent(draftToHtml(JSON.parse(html)));
    setHtmlContenterr("");
  };

  // const handleImageChange = (e) => {
  //   // const files = Array.from(e.target.files);
  //   setprevImages(URL.createObjectURL(e.target.files[0]));
  //   setImages(e.target.files[0]);
  //   setprevImageserr("");
  // };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    console.log(files);
    const validTypes = ["image/jpeg", "image/png", "image/gif"];

    const selectedImages = files.filter((file) =>
      validTypes.includes(file.type)
    );
    if (selectedImages.length > 3) {
      toast.error("You can upload a maximum of 3 images.");
      return;
    }

    if (selectedImages.length > 0) {
      setImages(selectedImages);
      setImagesErr("");
    } else {
      setImages([]);
      // setImagesErr("Please select valid image files (JPEG/PNG/GIF).");
    }
  };

  const handlesave = async () => {
    let isvalid = true;

    if (!title) {
      setTitleerr("Required*");
      isvalid = false;
    }
    if (!htmlContent) {
      setHtmlContenterr("Required*");
      isvalid = false;
    }
    if (!status) {
      setstatuserr("Required*");
      isvalid = false;
    }
    if (images.length === 0) {
      setImagesErr("Required*");
      isvalid = false;
    }
    if (isvalid) {
      const formdata = new FormData();

      formdata.append("description", htmlContent);

      formdata.append("title", title);

      formdata.append("filter_description", htmlEntity);
      formdata.append("publish_status", status);
      // formdata.append("blog_image", images);

      images.forEach((image, index) => {
        formdata.append(`blog_image`, image);
      });

      try {
        let data = await dispatch(addBlogs(formdata));

        if (data?.status == 200) {
          toast.success(data?.data?.message);
          navigate("/blogs");
        } else {
          toast.error(data?.data?.message);
        }
      } catch (error) {
        toast.error(error?.message);
      }
    }
  };

  return (
    <Layout>
      <div>
        <MainPagetitle
          mainTitle="Create Blog"
          pageTitle="Blog"
          parentTitle="Blogs"
        />
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 mb-3 col-auto d-flex justify-content-end">
              <button onClick={handlesave} className="btn btn-secondary ">
                Create
              </button>
            </div>
            <div className="col-lg-8">
              <div className="profile card card-body px-3 pt-3 pb-0">
                <div className="profile-head">
                  <div className="photo-content "></div>
                  <div className="profile-info">
                    <div className="card-body">
                      <div className="basic-form">
                        <form onSubmit={(e) => e.preventDefault()}>
                          <div className="row">
                            <div className="form-group mb-3 col-md-12">
                              <label>Blog Title</label>
                              <input
                                type="text"
                                name="title"
                                className="form-control"
                                placeholder="John"
                                onChange={(e) => {
                                  setTitle(e.target.value);
                                  setTitleerr("");
                                }}
                                value={title}
                              />
                              <div className="text-danger">{titleerr}</div>
                            </div>
                            {/* <div className="form-group mb-3 col-md-12">
                              <label>Blog Image</label>
                              <div className="user_detail_img_inner">
                                <img
                                  src={require("../../images/uploadimage.png")}
                                />
                                <div className="main-img-contain">
                                  <div
                                    
                                    className="image-container"
                                  >
                                    <img
                                      src={prevImages}
                                     
                                    />
                                  </div>
                                </div>

                                <input
                                  className="user_detail_upload"
                                  id="formFileSm"
                                  type="file"
                                  name="productimage"
                                  onChange={handleImageChange}
                                  accept="image/*"
                                  multiple
                                />
                              </div>
                              <div className="text-danger">{prevImageserr}</div>
                            </div> */}

                            <div className="form-group mb-3 col-md-12">
                              <label>Blog Image</label>
                              <div className="user_detail_img_inner">
                                <img
                                  src={require("../../images/uploadimage.png")}
                                  alt="Upload Icon"
                                />
                                <div className="main-img-contain view-blog">
                                  {images.map((image, index) => (
                                    <div
                                      key={index}
                                      className="image-container"
                                    >
                                      <img
                                        src={URL.createObjectURL(image)}
                                        alt={`Preview ${index}`}
                                      />
                                    </div>
                                  ))}
                                </div>
                                <input
                                  className="user_detail_upload"
                                  id="formFileSm"
                                  type="file"
                                  name="productimage"
                                  onChange={handleImageChange}
                                  accept="image/*"
                                  multiple
                                />
                              </div>
                              <div className="text-danger">{imagesErr}</div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="profile card card-body px-3 pt-3 pb-0">
                <div className="profile-head">
                  <div class="product-overview-box">
                    <h2>PUBLISH STATUS</h2>
                    <select
                      aria-label="Default select example"
                      name="publish_status"
                      class="form-select"
                      onChange={(e) => {
                        setstatus(e.target.value);
                        setstatuserr("");
                      }}
                      value={status}
                    >
                      <option>SELECT STATUS</option>
                      <option value="2">DRAFT</option>
                      <option value="1">PUBLISH</option>
                    </select>

                    <div className="text-danger">{statuserr}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <MyEditor htmltext={dataHtmlHandle} />
              <div className="text-danger">{htmlContenterr}</div>
            </div>
          </div>{" "}
        </div>
      </div>
    </Layout>
  );
};
export default CreateBlog;
