import React, { useState, useEffect } from "react";
import Layout from "../components/layouts/Layout";
import { IMAGES } from "../constant/theme";
import MainPagetitle from "../components/layouts/MainPagetitle";
import { CSVLink } from "react-csv";
import { Link, useNavigate } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  ApproveRejectComment,
  BlogComments,
  BlogStatus,
  findblog,
  getBlogs,
} from "../../store/actions/AdminAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const Blogs = () => {
  const blogsdata = useSelector((state) => state?.blog_listing?.bloglisting);
  console.log(blogsdata);

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPage = 10;
  const [show, setShow] = useState(false);
  const [blogId, setBlogId] = useState("");
  console.log(
    "kjlnasdkfnmal", blogId
  )
  const [blogComment, setBlogComments] = useState(null);
  console.log(blogComment);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState("");
  const [flag, setflag] = useState("");

  const lastIndex = page * limit;
  const npage = Math.ceil(
    (blogsdata?.totalCount == undefined ? 0 : blogsdata?.totalCount) / limit
  );
  const number = npage ? [...Array(npage + 1).keys()]?.slice(1) : [1];

  function prePage() {
    if (page !== 1) {
      setpage(page - 1);
    }
  }
  function changeCPage(id) {
    setpage(id);
  }
  function nextPage() {
    if (page !== npage) {
      setpage(page + 1);
    }
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBlogs({ page: page, limit: limit, status:0 }));
  }, [page, limit, flag,]);

  const blogidhandler = (id) => {
    navigate("/CreateBlog", {
      state: {
        blogid: id,
      },
    });
  };

  useEffect(() => {
    const fetchdata = async () => {
      let data = await dispatch(BlogComments({ id: blogId }));
      console.log(data)

      setBlogComments(data);
    };

    fetchdata();
  }, [blogId,flag]);



  const handlerblogAcc = async (id) => {
    const data = await dispatch(ApproveRejectComment({ id: id, status: 1 }));
    console.log(data);
    if (data?.status === 200) {
      toast.success(data?.data?.message);
      setflag(!flag);
      setShow(false);
    } else {
      toast.error(data?.data?.message);
    }
  };

  const handlerblogRej = async (id) => {
    const data = await dispatch(ApproveRejectComment({ id: id, status: 2 }));
    console.log(data);
    if (data?.status === 200) {
      toast.success(data?.data?.message);
      setflag(!flag);
      setShow(false);
    } else {
      toast.error(data?.data?.message);
    }
  };
console.log("blogComment",blogComment)
  return (
    <Layout>
      <MainPagetitle mainTitle="Blogs" pageTitle="Blogs" parentTitle="Home" />
      <div className="container-fluid">
        <div className="row">
          <div className="mb-3 col-auto float-end ms-auto ">
            <Link to="/create-blog" className="btn btn-secondary ">
              Create New
            </Link>
          </div>
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects manage-client">
                  <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                    <h4 className="heading mb-0">Blogs</h4>
                  </div>
                  <div
                    id="manage-tblwrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="reports-tbl"
                      className="table ItemsCheckboxSec dataTable no-footer mb-0"
                    >
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Created Date</th>
                          <th>Title</th>
                          <th>Comments</th>
                          <th>Last Edited</th>
                          <th style={{ textAlign: "center" }}>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {blogsdata?.data?.map((item, index) => {
                          console.log(item,"ysidgsephjhdhfgsdidgjsirpogh")
                          const serialNumber = (page - 1) * limit + index + 1;
                          return (
                            <tr>
                              <td>{serialNumber}</td>

                              <td>
                                <span>
                                  {new Date(item.createdAt).toLocaleDateString(
                                    "en-CA"
                                  )}
                                </span>
                              </td>

                              <td>
                                <Link to={`/view-blog/${item?._id}`}>
                                  <h6>{item.title || "N/A"}</h6>
                                </Link>
                              </td>
                              {/* <td>
                              <span>{item.Createdby}</span>
                            </td> */}

                              {/* <td onClick={handleShow}>
                              <span>{item.Comment}</span>
                            </td> */}
                              <td>
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setShow(true);
                                    setBlogId(item?._id);
                                  }}
                                >
                                  {item.commentCount}
                                </span>
                              </td>
                              <td style={{ textAlign: "left" }}>
                                <span>
                                  {new Date(item.updatedAt).toLocaleDateString(
                                    "en-CA"
                                  )}
                                </span>
                              </td>
                              <td>
                                <span>
                                  {/* {item?.publish_status == 1
                                    ? "Publish"
                                    : "Draft"} */}

                                  <select
                                    aria-label="Default select example"
                                    name="publish_status"
                                    class="form-select"
                                    onChange={async (e) => {
                                      try {
                                        const data = await dispatch(
                                          BlogStatus({
                                            blogs_id: item?._id,
                                            publish_status: e.target.value,
                                          })
                                        );
                                        console.log(data);
                                        if (data?.status == 200) {
                                          toast.success(data?.data?.message);

                                          setflag(!flag);
                                        } else {
                                          toast.error(data?.data?.message);
                                        }
                                      } catch (error) {
                                        console.log(error);
                                      }
                                      // setstatuserr("");
                                    }}
                                    value={item?.publish_status}
                                  >
                                    <option>SELECT STATUS</option>
                                    <option value="2">DRAFT</option>
                                    <option value="1">PUBLISH</option>
                                  </select>
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {lastIndex - limit + 1} to{" "}
                        {blogsdata?.totalCount &&
                        blogsdata?.totalCount < lastIndex
                          ? blogsdata?.totalCount
                          : lastIndex}{" "}
                        of {blogsdata?.totalCount && blogsdata?.totalCount}{" "}
                        entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                page === n ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Comments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="promocode-add social-form-new">
            <div className="row">
              {blogComment?.data?.data?.map((comment, index) => (
                <div className="form-group mb-3 col-md-12" key={index}>
                  <div className="d-flex ">
                    <p style={{ marginRight: "8px" }}>
                      <b>{index + 1}</b>
                    </p>
                    <label className="font-set">{comment?.name}</label>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginLeft: "14px", flex: 1 }}>
                      {comment?.comments}
                    </p>
                    {/* Accept and Reject buttons */}

                    {comment?.status === 0 ? (
                      <>
                        <Button className="me-2"
                          variant="success"
                          onClick={() => handlerblogAcc(comment?._id)}
                          // style={{ marginRight : "10px"}}
                        >
                          Approve
                        </Button>
                        <Button className="ms-2"
                          variant="danger"
                          onClick={() => handlerblogRej(comment?._id)}
                        >
                          Reject
                        </Button>
                      </>
                    ) : comment?.status === 1 ? (
                      <>
                        <Button
                          variant="success"
                          onClick={() => handlerblogAcc(comment?._id)}
                          style={{ marginRight: "5px" }}
                        >
                          Approved
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="danger"
                          onClick={() => handlerblogRej(comment?._id)}
                        >
                          Rejected
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default Blogs;
