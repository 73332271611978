import React, { useState,useEffect } from "react";
import Layout from "../components/layouts/Layout";
import { IMAGES } from "../constant/theme";
import MainPagetitle from "../components/layouts/MainPagetitle";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { productListing } from "../../store/actions/AdminAction";
import { useNavigate } from "react-router-dom";
import { ProductStock } from "../../store/actions/AdminAction";



const Products = () => {
  const productdata = useSelector((state) => state?.productlistmain?.get_products);
  console.log(productdata);
  const [ limit, setlimit] = useState(10);
  const [ page, setpage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [status, setStatus] = useState(0);
  const [flag,setflag] = useState(false);
 
  const lastIndex = page * limit;
  const npage = Math.ceil((productdata?.totalCount==undefined?0:productdata?.totalCount) / limit);
  const number = npage ? [...Array(npage + 1).keys()]?.slice(1) : [1];

  

  useEffect(() => {
    dispatch(productListing({limit:limit, page:page}));
  }, [page,flag]);
 



  const handlelimit = (e) => {
    setlimit(e.target.value);
  };

  const handlepagination = (page) => {
    console.log(page);
    setpage(page);
  }
   
  // const [currentPage, setCurrentPage] = useState(1);
  // const recordsPage = 10;
  // const lastIndex = currentPage * recordsPage;
  // const firstIndex = lastIndex - recordsPage;
  // const records = tableData.slice(firstIndex, lastIndex);
  // const npage = Math.ceil(tableData.length / recordsPage);
  // const number = [...Array(npage + 1).keys()].slice(1);
  // function prePage() {
  //   if (currentPage !== 1) {
  //     setCurrentPage(currentPage - 1);
  //   }
  // }
  // function changeCPage(id) {
  //   setCurrentPage(id);
  // }
  // function nextPage() {
  //   if (currentPage !== npage) {
  //     setCurrentPage(currentPage + 1);
  //   }
  // }

  function prePage() {
    if (page !== 1) {
      setpage(page - 1);
    }
  }
  function changeCPage(id) {
    setpage(id);
  }
  function nextPage() {
    if (page !== npage) {
      setpage(page + 1);
    }
  }


  const handleid = (product) => {
    navigate("/view-product", {
      state: {
        id: product,
      },
    });
  };

  const StockStatusHandler = async(newStatus,proId) => { 
    try{
      const data = await dispatch(ProductStock({status: newStatus,product_id:proId }));
      console.log(data);
      setflag(!flag)
    }catch(error){
     console.log(error);
    }
  }

  const handleCheckboxChange = (e,id) => {
    const newStatus = e.target.checked ? 1 : 0;
    const proId = id
    setStatus(newStatus);
    StockStatusHandler(newStatus,id);   
  };




  return (
    <Layout>
      <MainPagetitle
        mainTitle="Products"
        pageTitle="Products"
        parentTitle="Home"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="mb-3 col-auto float-end ms-auto">
            <Link to="/create-product" className="btn btn-secondary">
              Create Product
            </Link>
          </div>
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects manage-client">
                  <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                    <h4 className="heading mb-0">Products</h4>
                    {/* <div>
                                            <CSVLink {...csvlink} className="btn btn-primary light btn-sm">
                                                <i className="fa-solid fa-file-excel" /> {" "} 
                                                Export Report
                                            </CSVLink> 
                                        </div> */}
                  </div>
                  <div
                    id="manage-tblwrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="reports-tbl"
                      className="table ItemsCheckboxSec dataTable no-footer mb-0"
                    >
                      <thead>
                        <tr>
                          <th>id #</th>

                          <th>name</th>
                          <th>description</th>
                          <th>price</th>
                          <th>Stock</th>
                        </tr>
                      </thead>
                      <tbody>
                        {productdata?.data?.map((details, index) => {
                          const serialNumber = (page - 1) * limit + index + 1;
                          console.log(details?.status)
                          return(
                      
                          <tr>
                            <td>{serialNumber}</td>

                            <td>
                              <b style={{ cursor: "pointer" }} className="link-tag" onClick={()=> handleid(details?._id)}>
                                <h6>{details?.title ? details?.title : "N/A"}</h6>
                              </b>
                            </td>
                            <td>
                              <span className="overflow-text">
                                {details?.product_description ? details?.product_description : "N/A"}
                              </span>
                            </td>
                            <td style={{ textAlign: "left" }}>
                              <span>{details?.price ? details?.price : "N/A"}</span>
                            </td>
                            <td>
                              <div class="form-check form-switch">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                  checked={details?.status == 1 ?true :false}
                                  onChange={(e) => handleCheckboxChange( e,details?._id)}
                                />
                                
                              </div>
                            </td>
                          </tr>
                              )
                            })}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        {/* Showing {lastIndex - recordsPage + 1} to{" "}
                        {tableData.length < lastIndex
                          ? tableData.length
                          : lastIndex}{" "}
                        of {tableData.length} entries */}
                         Showing {lastIndex - limit + 1} to{" "}
                        {productdata?.totalCount && productdata?.totalCount < lastIndex ? productdata?.totalCount : lastIndex} of{" "}
                        {productdata?.totalCount && productdata?.totalCount} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                page === n ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Products;
