import { Cases } from "@mui/icons-material";
import {
  GET_ALL_DRIVERS,
  GET_ALL_PATIENT,
  GET_ALL_PHARMACIST,
  GET_ALL_SERVICES,
  GET_DASHBOARD_DATA,
  UPDATE_DRIVER_PROFILE,
  UPDATE_PHARMACIST_PROFILE,
  UPDATE_PHARMACIST_SERVICE,
  VIEW_DRIVER_DETAIL,
  VIEW_USER_DETAIL,
  GET_ALL_PRODUCTLIST,
  ADD_PRODUCT_CATEGORY,
  GET_PRODUCSTS,
  DROPDOWN_CATE_LIST,
  GET_PROMOTION_LISTING,
  GET_PROMOCODE_LISTING,
  MANAGE_ORDER_LISTING,
  GET_BLOGS,
  MANAGE_ADMIN_LIST,
  GET_PROMOTION_LIST,
  ADD_NOTIFICATION,
  GET_NOTIFICATIONS,
  GET_COMMISSIONS,
  SUPPORT_LIST,
  SUPPORT_CHAT,
  FAQS,
  FAQS_DATA,
} from "../Constant/constant";

let initialstate = {
  userList: [],
  services: [],
  patientlist: [],

  userDetail: null,
  driverDetail: null,
  dashboard_data: [],
  get_products: [],
};
const AdminReducer = (state = initialstate, action) => {
  switch (action.type) {
    case GET_ALL_PHARMACIST:
      return {
        ...state,
        userList: action?.payload,
      };
    case GET_ALL_PATIENT:
      return {
        ...state,
        patientlist: action?.payload,
      };
    case VIEW_USER_DETAIL:
      return {
        ...state,
        userDetail: action?.payload,
      };
    case VIEW_DRIVER_DETAIL:
      return {
        ...state,
        driverDetail: action?.payload,
      };
    case UPDATE_PHARMACIST_PROFILE:
      const newArr = state.userList.map((userList) =>
        userList?._id == action?.payload._id ? action?.payload : userList
      );
      return {
        ...state,
        userList: newArr,
      };
    case GET_ALL_SERVICES:
      return {
        ...state,
        services: action?.payload,
      };

    case UPDATE_PHARMACIST_SERVICE:
      if (action?.payload.type != 0) {
        const newArrservices = state.services?.servicelist.map(
          (services_object) =>
            services_object?._id == action?.payload._id
              ? action?.payload
              : services_object
        );
        return {
          ...state,
          services: {
            servicelist: newArrservices,
            totallength: state.services?.totallength,
          },
        };
      } else {
        const newArrservices = [...state.services?.servicelist];
        newArrservices.unshift(action?.payload);

        return {
          ...state,
          services: {
            servicelist: newArrservices,
            totallength: state.services?.totallength,
          },
        };
      }
    case GET_DASHBOARD_DATA:
      return {
        ...state,
        dashboard_data: action.payload,
      };

    case GET_ALL_PRODUCTLIST:
      return {
        ...state,
        Product_list: action.payload,
      };

    case GET_PRODUCSTS:
      return {
        ...state,
        get_products: action.payload,
      };
    case DROPDOWN_CATE_LIST:
      return {
        ...state,
        drop_cate_list: action.payload,
      };
    case GET_PROMOCODE_LISTING:
      return {
        ...state,
        promocodelist: action.payload,
      };

    case MANAGE_ORDER_LISTING:
      return {
        ...state,
        manageOrderslist: action.payload,
      };
    case GET_BLOGS:
      return {
        ...state,
        bloglisting: action.payload,
      };
    case MANAGE_ADMIN_LIST:
      return {
        ...state,
        Admin_list: action.payload,
      };
    case GET_PROMOTION_LIST:
      return {
        ...state,
        getpromotionlist: action.payload,
      };
      case GET_NOTIFICATIONS:
        return {
          ...state,
          getnotifications: action.payload,
        };
        case GET_COMMISSIONS:
          return{
            ...state,
            getcommi: action.payload,
          }
          case SUPPORT_LIST:
            return {
              ...state,
              Supportlist: action.payload
            }
            // case SUPPORT_CHAT:
            //   console.log(action.payload,"action.payloadaction.payload==")
            //   return {
            //     ...state,
            //  supportchat: action.payload
            //   }
            case FAQS_DATA:
              console.log(action.payload);
              return{
                ...state,
                faqs: action.payload
              }

    default:
      return state;
  }
};
export default AdminReducer;
