import API from "../../services/Api";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { MenuList } from "../../jsx/components/layouts/nav/Menu";
// import { LOADING_TOGGLE_ACTION, LOGOUT_ACTION } from '../Constant/constant';
export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";
export const NAVTOGGLE = "NAVTOGGLE";
var cookies = new Cookies();
export function Logout(navigate) {
  localStorage.removeItem("userDetails");
  cookies.remove("adminToken");
  cookies.remove("name");
  cookies.remove("email");
  sessionStorage.clear("adminToken");
  navigate("/");
  //history.push('/login');

  return {
    type: LOGOUT_ACTION,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
export const navtoggle = () => {
  return {
    type: "NAVTOGGLE",
  };
};
export const Adminlogin = (data, navigate) => async (dispatch) => {
  try {
    const response = await API.post("/adminLogin", data);
    const responseData = response.data.data
    if (response?.data?.status == "Success") {
      console.log("response.data.data.name", responseData);

      sessionStorage.setItem("adminToken", responseData.token);
      sessionStorage.setItem("name", responseData.name);
      sessionStorage.setItem("email", responseData.email);
      sessionStorage.setItem("adminid", responseData._id);
      sessionStorage.setItem("permissions", responseData?.permissions);
      sessionStorage.setItem(
        "is_super_admin",
        responseData.is_super_admin
      );
      if (responseData.is_super_admin) return (window.location.href = `/dashboard`);
      const roles = responseData.permissions;
      const filteredMenuList = MenuList.filter((item) =>
        roles.includes(item.permission)
      );
      window.location.href = `${
        roles?.includes("dashboard") ? "/dashboard" : filteredMenuList[0]?.to
      }`;
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
};
export const changePasswordAdmin = (data) => async () => {
  const res = await API.post("/adminUpdatepassword", data);
  if (res?.data?.status == 200) {
    toast.success(res?.data?.message);
  } else {
    toast.error(res?.data?.message);
  }
};
