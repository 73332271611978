import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Offcanvas } from "react-bootstrap";
import { CSVLink } from "react-csv";

import { IMAGES } from "../constant/theme";
import InviteCustomer from "../constant/ModalList";
import EmployeeOffcanvas from "../constant/EmployeeOffcanvas";
import Layout from "../components/layouts/Layout";
import MainPagetitle from "../components/layouts/MainPagetitle";
import { useDispatch, useSelector } from "react-redux";
import { PharmacistAction, patientsAction } from "../../store/actions/AdminAction";
import moment from "moment";
import Search from "../components/elements/Search";
import Placholder from "../../images/placholder-img-admin1.png";
const Pharmacist = () => {
  const IMG_URL=process.env.REACT_APP_FILE_BASE_URL
  const limit = 10;
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState();
  const navigate=useNavigate()
  const dispatch = useDispatch();
  const allUsers = useSelector((state) => state?.adminReducer?.patientlist);
  const totalUsers = useSelector((state) => state?.adminReducer?.patientlist?.totallength);

  const lastIndex = page * limit;
  const npage = Math.ceil((totalUsers==undefined?0:totalUsers) / limit);
  const number = [...Array(npage + 1).keys()].slice(1);

  useEffect(() => {
    dispatch(patientsAction(page, limit, search));
  }, [page, limit, search]);
 
  function prePage() {
    if (page !== 1) {
      setPage(page - 1);
    }
  }
  function changeCPage(id) {
    setPage(id);
  }
  function nextPage() {
    if (page !== npage) {
      setPage(page + 1);
    }
  }

  const invite = useRef();
  const employe = useRef();
  return (
    <Layout>
      <MainPagetitle
        mainTitle="Patient"
        pageTitle={"Patient"}
        parentTitle={"Home"}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                  <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                    <h4 className="heading mb-0">Patients</h4>
                    <Search setSearch={setSearch}  setPage={setPage}/>
                  </div>
                  <div
                    id="employee-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyees-tblwrapper"
                      className="table ItemsCheckboxSec dataTable no-footer mb-0"
                    >
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>User Name</th>
                          <th>Email Address</th>
                          <th>Gender</th>
                        
                        
                        </tr>
                      </thead>
                      <tbody>
                        {allUsers?.user_list?.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <span>{index+lastIndex - limit + 1}</span>
                            </td>
                            <td>
                              <div className="products">
                                <img
                                           src={
                                    item?.main_image
                                      ? IMG_URL +"/user_docs/"+ item?.main_image
                                      : Placholder
                                  }
                                  className="avatar avatar-md"
                                  alt=""
                                />
                                <div className="pointerUser"
                                 onClick={()=>navigate(`/pharmacist/${item._id}`,{state:{
                                  nav:true
                                 }})}
                                 
                                 
                                 >
                                  <h6>{item?.name}</h6>
                                 
                                </div>
                              </div>
                            </td>
                            <td>
                            <a href={`mailto:${item?.email}`} style={{ cursor:'auto' }} >
                          
                          <span className="text-primary">
                            {item?.email}
                          </span>
                          </a>
                            </td>
                            <td>
                          <span>
                          {item?.gender == 1
                                  ? "Male"
                                  : item?.gender == 2
                                  ? "Female"
                                  : item?.gender == 3
                                  ? "Not To Say"
                                  : "N/A"
                                  
                                  
                                  
                                  
                                  }
                          </span>
                          </td>
                            
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {lastIndex - limit + 1} to{" "}
                        {totalUsers < lastIndex
                          ? totalUsers
                          : lastIndex}{" "}
                        of {totalUsers} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                page === n ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EmployeeOffcanvas ref={employe} Title="Add Employee" />
      <InviteCustomer ref={invite} Title="Invite Employee" />
    </Layout>
  );
};

export default Pharmacist;
