import { toast } from "react-toastify";
import { useState } from "react";
import API from "../../services/Api";
import {
  GET_ALL_PATIENT,
  GET_ALL_PHARMACIST,
  GET_ALL_SERVICES,
  GET_DASHBOARD_DATA,
  UPDATE_DRIVER_PROFILE,
  UPDATE_PHARMACIST_PROFILE,
  UPDATE_PHARMACIST_SERVICE,
  UPDATE_PROFILE_DETAIL,
  VIEW_DRIVER_DETAIL,
  VIEW_USER_DETAIL,
  GET_ALL_PRODUCTLIST,
  ADD_PRODUCT_CATEGORY,
  GET_PRODUCSTS,
  ADD_PRODUCTS,
  DELETE_PRODUCT,
  DELETE_PRODUCT_CATEGOTY,
  UPDATE_PRODUCT,
  FIND_PRODUCTS,
  DELETE_SERVICE,
  UPDATE_PRODUCT_CATEGORY,
  PRODUCT_STOCK,
  DROPDOWN_CATE_LIST,
  GET_PROMOTION_LISTING,
  GET_PROMOCODE_LISTING,
  MANAGE_ORDER_LISTING,
  ADD_PROMOCODES,
  ORDERS_DETAILS,
  ORDERS_STATUS,
  DELETE_PROMOCODE,
  UPDATE_PROMOCODE,
  GET_BLOGS,
  FIND_PROMOCODE,
  ADD_BLOGS,
  DEACTIVATE_USER,
  UNDEACTIVATE_USER,
  PAUSE_PROMOCODE,
  MANAGE_ADMIN_LIST,
  ADMIN_ADD_PERMISSION,
  DEL_ADMIN_PERMISSION,
  FIND_BLOGS,
  UPDATE_BLOGS,
  DELETE_BLOG,
  UPDATE_ADMIN_PERMISSION,
  GET_PROMOTION_LIST,
  FIND_ADMIN_LIST,
  ADD_PROMOTION,
  DELETE_PROMOTION,
  
  UPDATE_BLOGS_STATUS,
  ADD_NOTIFICATION,
  GET_NOTIFICATIONS,
  DELETE_NOTIFICATION,
  UPDATE_BLOG_STATUS,
  BLOG_COMMENTS,
  ADD_COMMISSION,
  GET_COMMISSION,
  GET_COMMISSIONS,
  UPDATE_COMMISSIONS,
  DELETE_COMMISSIONS,
  SUPPORT_LIST,
  SUPPORT_ORDER_DETAIL,
  SUPPORT_CHAT,
  APPROVE_REJECT_BLOG,
  CHAT_STATUS,
  USERS_LIST,
  SUPPORT_CHAT_DATA,
  FAQS,
  FAQS_DATA,
  FAQS_ACTION,
  GET_FAQS_BY_ID,
} from "../Constant/constant";
import { Toast } from "react-bootstrap";
import { date } from "yup";



// export const PharmacistAction =

//   (page, limit, search, data) => async (dispatch) => {
//     try {
//       const res = await API.get(
//         `/pharmacist?page=${
//           page == undefined ? 1 : page
//         }&limit=${limit}&search=${search ? search : undefined}`,
//         data
//       );
//       console.log(res?.data)

//       if (res?.data?.status == 200) {
//         dispatch({
//           type: GET_ALL_PHARMACIST,
//           payload: res?.data?.data,
//         });
//       } else {
//         toast.error(data?.message);
//       }
//       return res;
//     } catch (error) {
//       toast.error(error);
//     }
//   };


export const  PharmacistAction = (page, limit, search, data) => async (dispatch) => {
  try {
    const data = await API.get(`/pharmacist?page=${
               page
              }&limit=${limit}&search=${search}`);

    if (data?.status === 200) {
      dispatch({
        type: GET_ALL_PHARMACIST,
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};

export const viewUserDetail = (id) => async (dispatch) => {
  try {
    const { data } = await API.get(`/admin_profile_details?id=${id}`);

    if (data?.status == 200) {
      dispatch({
        type: VIEW_USER_DETAIL,
        payload: data.data,
      });
    } else {
      toast.error(data?.message);
    }
  } catch (error) {
    toast.error(error);
  }
};
export const updateUserProfile = (data) => async (dispatch) => {
  try {
    const res = await API.post("/update_user_details", data);
    if (res?.data.status == 200) {
      dispatch({
        type: UPDATE_PROFILE_DETAIL,
        payload: res?.data?.data,
      });
      toast.success(res?.data?.message);
    } else {
      toast.error(res?.data.message);
    }
  } catch (error) {
    toast.error(error);
  }
};
export const deleteUserAcc = (id, navigate) => async (dispatch) => {
  try {
    const response = await API.get(`/deleteUserAccount?id=${id}`);
    if (response?.data?.status == 200) {
      toast.success(response?.data?.message);
      navigate("/Pharmacist");
    } else {
      toast.error(response?.data?.message);
    }
  } catch (error) {
    toast.error(error);
  }
};
export const changePassword = (data) => async () => {
  const res = await API.post("/updatepassworduser", data);
  if (res?.data.status == 200) {
    toast.success(res?.data?.message);
  } else {
    toast.error(res?.data?.message);
  }
};
// Driver Actions
export const patientsAction =
  (page, limit, search, data) => async (dispatch) => {
    try {
      const res = await API.get(
        `/patients?page=${page == undefined ? 1 : page}&limit=${limit}&search=${
          search ? search : undefined
        }`,
        data
      );
      console.log(res);
      if (res?.data?.status == 200) {
        dispatch({
          type: GET_ALL_PATIENT,
          payload: res?.data?.data,
        });
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };
export const viewDriverDetail = (id) => async (dispatch) => {
  try {
    const { data } = await API.get(`/getDriverProfileById?id=${id}`);
    if (data?.success) {
      dispatch({
        type: VIEW_DRIVER_DETAIL,
        payload: data.data,
      });
    } else {
      toast.error(data?.message);
    }
  } catch (error) {
    toast.error(error);
  }
};
export const updatepharmacistProfile = (data) => async (dispatch) => {
  try {
    const res = await API.post("/Updatestatus", data);
    console.log(res,"response")
    return res
    // if (res?.data.status == 200) {
    //   dispatch({
    //     type: UPDATE_PHARMACIST_PROFILE,
    //     payload: res?.data?.data,
    //   });

      
    // } else {
    //   toast.error(res?.data.message);
    // }

    
  } catch (error) {
    toast.error(error);
  }

  
};
export const deleteDriverAcc = (id, navigate) => async (dispatch) => {
  try {
    const response = await API.get(`/deleteDriverAccount?id=${id}`);
    if (response?.data?.success) {
      toast.success(response?.data?.message);
      navigate("/drivers");
    } else {
      toast.error(response?.data?.message);
    }
  } catch (error) {
    toast.error(error);
  }
};
export const changePasswordDriver = (data) => async () => {
  const res = await API.put("/resetPassDriver", data);
  if (res?.data.success) {
    toast.success(res?.data?.message);
  } else {
    toast.error(res?.data?.message);
  }
};

export const ServiceinformationsaveAction = (data) => async (dispatch) => {
  try {
    const res = await API.post("/serviceinformationsave", data);
    if (res?.data.status == 200) {
      toast.success(res?.data?.message);
      dispatch({
        type: UPDATE_PHARMACIST_SERVICE,
        payload: res?.data?.data,
      });
    } else {
      toast.error(res?.data?.message);
    }
  } catch (error) {
    toast.error(error?.message);
  }
};

export const ServicesAction = (page, search) => async (dispatch) => {
  try {
      

    const res = await API.get(
      `/services?page=${page == undefined ? 1 : page}&search=${
        search ? search : undefined
      }`
    );

    if (res?.data?.status == 200) {
      dispatch({
        type: GET_ALL_SERVICES,
        payload: res?.data?.data,
      });
    } else {
      toast.error(res?.data?.message);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const dashboardction = (page, search) => async (dispatch) => {
  try {
    const res = await API.get(`/dashboard`);

    if (res?.data?.status == 200) {
      dispatch({
        type: GET_DASHBOARD_DATA,
        payload: res?.data?.data,
      });
    } else {
      toast.error(res?.data?.message);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const ProdusctList = (details) => async (dispatch) => {
  try {
    const data = await API.get(`/productcategorylist?limit=${details?.limit}&page=${details?.page}&title=${details?.title}`);

    if (data?.data?.status == 200) {
      dispatch({
        type: GET_ALL_PRODUCTLIST,
        payload: data?.data?.data,
      });
    } else {
      toast.error(data?.data?.message);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const addProductCategory = (Details) => async (dispatch) => {
  try {
    const data = await API.post(`/addproductcategory`, Details);
    console.log(data);

     toast.success(data?.data?.message)
    if (data?.data?.status == 200) {
      dispatch({
        type: ADD_PRODUCT_CATEGORY,
        payload: data?.data?.data,
      });
    } else {
      toast.error(data?.data?.message);
    }
    return data
  } catch (error) {
    toast.error(error);
  }
};

export const  productListing = (details) => async (dispatch) => {
  try {
    const data = await API.get(`/product_listing?limit=${details?.limit}&page=${details?.page}`);

    if (data?.data?.status == 200) {
      dispatch({
        type: GET_PRODUCSTS,
        payload: data?.data?.data,
      });
    } else {
      toast.error(data?.data?.message);
    }
  } catch (error) {
    toast.error(error);
  }
};



export const  addProducts = (Details) => async (dispatch) => {
  try {
    const data = await API.post(`/addproducts`, Details);
     console.log(data);
    // if (data?.status === 200) {
      // toast.success(data?.data?.message);
      dispatch({
        type: ADD_PRODUCTS,
        payload: data?.data?.data,
      });
      return data;
    // } else {
    //   toast.error(data?.data?.message);
    // }
  } catch (error) {
    toast.error(error);
  }
};

export const  deleteProductcate = (details) => async (dispatch) => {
  try {
    const data = await API.delete(`/deletecategory/${details?.category_id}`);

    if (data?.data?.status == 200) {
      toast.success(data?.data?.message);
      dispatch({
        type: DELETE_PRODUCT_CATEGOTY,
        payload: data?.data?.data,
      });
    } else {
      toast.error(data?.data?.message);
    }
  } catch (error) {
    toast.error(error);
  }
};






export const  deleteProduct = (details) => async (dispatch) => {
  try {
    const data = await API.delete(`/deleteproduct/${details?.product_id}`);

    if (data?.data?.status == 200) {
      toast.success(data?.data?.message);
      dispatch({
        type: DELETE_PRODUCT,
        payload: data?.data?.data,
      });
    } else {
      toast.error(data?.data?.message);
    }
  } catch (error) {
    toast.error(error);
  }
};


export const  updateProduct = (Details) => async (dispatch) => {
  try {
    const data = await API.post(`/update_products`, Details);

    if (data?.status == 200) {
      toast.success(data?.data?.message);
      dispatch({
        type: UPDATE_PRODUCT,
        payload: data?.data?.data,
      });
    } else {
      toast.error(data?.data?.message);
    }
    return data;
  } catch (error) {
    toast.error(error);
  }
};

export const  updateProductcategoty = (Details) => async (dispatch) => {
  try {
    const data = await API.post(`/updateproductcategory`, Details);

    if (data?.data?.status == 200) {
      dispatch({
        type: UPDATE_PRODUCT_CATEGORY,
        payload: data?.data?.data,
      });
    } else {
      toast.success(data?.data?.message);
    }
    return data;
  } catch (error) {
    toast.error(error);
  }
};


export const  findProducts = (details) => async (dispatch) => {
  try {
    const data = await API.post(`/find_product`,details );

    if (data?.data?.status == 200) {
      dispatch({
        type: FIND_PRODUCTS,
        payload: data?.data?.data,
      });

      return data

    } else {
      toast.error(data?.data?.message);
    }
  } catch (error) {
    toast.error(error);
  }

  
};


export const  deleteservice = (details) => async (dispatch) => {
  try {
    const data = await API.delete(`/delete_services/${details?.id}`);

    if (data?.data?.status == 200) {
    toast.success(data?.data?.message);
      dispatch({
        type: DELETE_SERVICE,
        payload: data?.data?.data,
      });

      return data

    } else {
      toast.error(data?.data?.message);
    }
  } catch (error) {
    toast.error(error);
  }
  
};


// Product stock status


export const  ProductStock = (details) => async (dispatch) => {
  try {
    const data = await API.post(`/update_products_status`,details);
    console.log(data, "klhoihipk;jopk;hukdftg,jvb");

    if (data?.status == 200) {
    toast.success(data?.data?.message);
      dispatch({
        type: PRODUCT_STOCK,
        payload: data?.data?.data,
      });

      return data

    } else {
      toast.error(data?.data?.message);
    }
  } catch (error) {
    toast.error(error);
  }
  
};

// category list for Dropdown

export const  DropdownCateList = (details) => async (dispatch) => {
  try {
    const data = await API.get(`/product_category_list`,details);

    if (data?.status == 200) {
      dispatch({
        type: DROPDOWN_CATE_LIST,
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};

// Get promocodes listing 

export const  PromocodesListing = (details) => async (dispatch) => {
  try {
    const data = await API.get(`/promocode_list?limit=${details?.limit}&page=${details?.page}`);

    if (data?.status == 200) {
      dispatch({
        type: GET_PROMOCODE_LISTING,
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};

 // Orders Listing

export const  ManageOrderListing = (details) => async (dispatch) => {
  try {
    const data = await API.get(`/order_list_admin?limit=${details?.limit}&page=${details?.page}&name=${details?.name}`);

    if (data?.status == 200) {
      dispatch({
        type: MANAGE_ORDER_LISTING,
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};

export const  AddPromocodes = (details) => async (dispatch) => {
  try {
    const data = await API.post(`/add_promocode`,details);

    if (data?.status == 200) {
      dispatch({
        type: ADD_PROMOCODES,
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};

// get ordersdetails

export const  getOrderdetails = (details) => async (dispatch) => {
  try {
    const data = await API.post(`/get_detail_orders_by_order_id`,details);

    if (data?.status == 200) {
      dispatch({
        type: ORDERS_DETAILS,
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};

export const  ordersstatus = (details) => async (dispatch) => {
  try {
    const data = await API.post(`/update-order-status`,details);

    if (data?.status == 200) {
      dispatch({
        type: ORDERS_STATUS,
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};

// DELETE PROMOCODE



export const  deletePromocodes = (details) => async (dispatch) => {
  try {
    const data = await API.post(`/delete_promocode`,details);

    if (data?.status == 200) {
      dispatch({
        type: DELETE_PROMOCODE,
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};

// Update promocode

export const  updatepromocode = (details) => async (dispatch) => {
  try {
    const data = await API.post(`/update_promocode`,details);

    if (data?.status == 200) {
      dispatch({
        type: UPDATE_PROMOCODE,
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};


export const  findpromocode = (details) => async (dispatch) => {
  try {
    const data = await API.post(`/find_Promocode`,details);

    if (data?.status == 200) {
      dispatch({
        type: FIND_PROMOCODE,
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};


// Get Blogs

export const  getBlogs = (details) => async (dispatch) => {
  try {
    const data = await API.get(`/blog_List?limit=${details?.limit}&page=${details?.page}&status=${details?.status}`);

    if (data?.status == 200) {
      dispatch({
        type: GET_BLOGS,
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};

// blog by id


export const  findblog = (details) => async (dispatch) => {
  try {
    const data = await API.post(`/find_blogs`,details);

    if (data?.status == 200) {
      dispatch({
        type: FIND_BLOGS,
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};


// add blogs

export const  addBlogs = (details) => async (dispatch) => {
  try {
    const data = await API.post(`/add_blogs`,details);

    if (data?.status == 200) {
      dispatch({
        type: ADD_BLOGS,
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};

 // update blog status

export const  updateBlogStatus = (details) => async (dispatch) => {
  try {
    const data = await API.post(`/update_blogs`,details);

    if (data?.status == 200) {
      dispatch({
        type: UPDATE_BLOGS,
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};

// blog status

export const  BlogStatus = (details) => async (dispatch) => {
  try {
    const data = await API.post(`/update_blogs_status`,details);

    if (data?.status == 200) {
      dispatch({
        type: UPDATE_BLOGS_STATUS,
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};


// delete blog

export const  deleteBlog = (details) => async (dispatch) => {
  try {
    const data = await API.post(`/delete_blogs`,details);

    if (data?.status == 200) {
      dispatch({
        type: DELETE_BLOG,
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};

// find blog comments

export const  BlogComments = (details) => async (dispatch) => {
  try {
    const data = await API.get(`/blog-comments/${details?.id}`);

    if (data?.status == 200) {
      dispatch({
        type:BLOG_COMMENTS,
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};

// deactivate use in pharmacist details 

export const  DeactivateUser = (details) => async (dispatch) => {
  try {
    const data = await API.post(`/deactivateUser`, details);

    if (data?.status == 200) {
      dispatch({
        type: DEACTIVATE_USER,
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};

export const  UnDeactivateUser = (details) => async (dispatch) => {
  try {
    const data = await API.post(`/Un_deactivateUser`, details);

    if (data?.status == 200) {
      dispatch({
        type: UNDEACTIVATE_USER,
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};



export const  PausePromocode = (details) => async (dispatch) => {
  try {
    const data = await API.post(`/pause_promocode`, details);

    if (data?.status == 200) {
      dispatch({
        type: PAUSE_PROMOCODE,
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};


// manage admin get listing 

export const  manageAdminList = (details) => async (dispatch) => {
  try {
    const data = await API.get(`/manage-admin?limit=${details?.limit}&page=${details?.page}`);

    if (data?.status == 200) {
      dispatch({
        type: MANAGE_ADMIN_LIST,
        payload: data?.data?.data,
      });
      

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};


// create admin 

export const  AddadminPermission = (details) => async (dispatch) => {
  try {
    const data = await API.post(`/manage-admin`, details);

    if (data?.status == 200) {
      dispatch({
        type: ADMIN_ADD_PERMISSION,
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};

 // Delete Admin Permission

export const  DELadminPermission = (details) => async (dispatch) => {
  try {
    const data = await API.delete(`/manage-admin/${details?.id}`);

    if (data?.status == 200) {
      dispatch({
        type: DEL_ADMIN_PERMISSION,
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};

// Update Admin Permission


export const  updateAdminPermission = (details) => async (dispatch) => {
  try {
    const data = await API.put(`/manage-admin/${details?.id}`, details);

    if (data?.status == 200) {
      dispatch({
        type: UPDATE_ADMIN_PERMISSION,
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};

// get Promotion List  


export const  getpromotionlist = (details) => async (dispatch) => {
  try {
    const data = await API.get(`/get_promotions_list_admin?limit=${details?.limit}&page=${details?.page}`);

    if (data?.status == 200) {
      dispatch({
        type: GET_PROMOTION_LIST,
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};

// find admin data by id  

export const  findadminlist = (details) => async (dispatch) => {
  try {
    const data = await API.get(`/manage-admin/${details?.id}`);

    if (data?.status == 200) {
      dispatch({
        type: FIND_ADMIN_LIST,
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};


// add promotion api

export const  addPromotion = (details) => async (dispatch) => {
  try {
    const data = await API.post(`/add_promotions`, details);

    if (data?.status == 200) {
      dispatch({
        type: ADD_PROMOTION,
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};

 // Delete Promotion
export const  DeletePromotion = (details) => async (dispatch) => {
  try {
    const data = await API.post(`/delete_promotions`, details);

    if (data?.status == 200) {
      dispatch({
        type: DELETE_PROMOTION ,
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};


// Add Notification

export const  addNotification = (details) => async (dispatch) => {
  try {
    const data = await API.post(`/add_notification_admin`, details);

    if (data?.status == 200) {
      dispatch({
        type: ADD_NOTIFICATION ,
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};



export const  AddCommission = (details) => async (dispatch) => {
  try {
    const data = await API.post(`/add_commision`, details);

    if (data?.status == 200) {
      dispatch({
        type: ADD_COMMISSION ,
        payload: data?.data?.data,
      });
      toast.success(data?.data?.message)


      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};

// get Admin notification

export const  getNotifications = (details) => async (dispatch) => {
  try {
    const data = await API.get(`/get_admin_notification?limit=${details?.limit}&page=${details?.page}`);
       
    if (data?.status == 200) {
      dispatch({
        type: GET_NOTIFICATIONS ,
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};


// Delete notification

export const  DeleteNotification = (details) => async (dispatch) => {
  try {
    const data = await API.post(`/delete_admin_notification`, details);

    if (data?.status == 200) {
      dispatch({
        type: DELETE_NOTIFICATION,  
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};

// Get All Commission List

export const  getCommissions = (details) => async (dispatch) => {
  try {
    const data = await API.get(`/getcommision_list?limit=${details?.limit}&page=${details?.page}`);
       
    if (data?.status == 200) {
      dispatch({
        type: GET_COMMISSIONS ,
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};

// Update Commission 

export const  updateCommission = (Details) => async (dispatch) => {
  try {
    const data = await API.post(`/update_commision`, Details);
    console.log(data);

    if (data?.status === 200) {
      dispatch({
        type: UPDATE_COMMISSIONS,
        payload: data?.data?.data,
      });
      toast.success(data?.data?.message);
    } 
    return data;
  } catch (error) {
    toast.error(error);
  }
};


export const  deleteCommision = (details) => async (dispatch) => {
  try {
    const data = await API.post(`/delete_commision`,details);

    if (data?.data?.status == 200) {
      toast.success(data?.data?.message);
      dispatch({
        type: DELETE_COMMISSIONS,
        payload: data?.data?.data,
      });
    } else {
      toast.error(data?.data?.message);
    }
  } catch (error) {
    toast.error(error);
  }
};

// Get Support Listing

export const  Supportlist = (details) => async (dispatch) => {
  try {
    const data = await API.get(`/get_Contactus?limit=${details?.limit}&page=${details?.page}`);
       
    if (data?.status == 200) {
      dispatch({
        type: SUPPORT_LIST,
        payload: data?.data?.data,
      });

      return data;
    }
  } catch (error) {
    toast.error(error);
  }
  
};


export const  SupportOrderDetail = (details) => async (dispatch) => {
  try {
    const data = await API.post(`/contact_usorder_detail`,details);

    if (data?.data?.status == 200) {
      dispatch({
        type: SUPPORT_ORDER_DETAIL,
        payload: data?.data?.data,
      });
    } 
  } catch (error) {
    toast.error(error);
  }
};

export const  supportChat = (details) => async (dispatch) => {
  try {
    const data = await API.get(`/support-chat/${details?.id}`);
    console.log(data,"ikidfnsdkfgnks;dlngfjsd")
        
    if (data?.data?.status == 200) {
      dispatch({
        type: SUPPORT_CHAT,
        payload: data?.data?.data,
      });
    } 
    return data
  } catch (error) {
    toast.error(error);
  }

};


export const  ApproveRejectComment = (details) => async (dispatch) => {
  try {
    const data = await API.put(`/blog-comment/${details?.id}`,details);
    console.log(data,"ikidfnsdkfgnks;dlngfjsd");
        
    if (data?.data?.status == 200) {
      dispatch({
        type: APPROVE_REJECT_BLOG,
        payload: data?.data?.data,
      });
    } 
    return data
  } catch (error) {
    toast.error(error);
  }

};


export const  chatStatus = (details) => async (dispatch) => {
  try {
    const data = await API.put(`/support-chat/${details?.id}`,details);
        
    if (data?.data?.status == 200) {
      dispatch({
        type: CHAT_STATUS,
        payload: data?.data?.data,
      });
    } 
    return data;
  } catch (error) {
    toast.error(error);
  }

};

// search user list when add notification

export const  NotificationUSerList = (details) => async (dispatch) => {
  try {
    const data = await API.get(`/user-list?search=${details?.search}`);
        
    if (data?.data?.status == 200) {
      dispatch({
        type: USERS_LIST,
        payload: data?.data?.data,
      });
      console.log(data)
    } 
    return data;
  } catch (error) {
    toast.error(error);
  }

};

// get support details 

export const  getsupportchatdetails = (details) => async (dispatch) => {
  try {
    const data = await API.get(`/contact-support-detail/${details?.id}`);
        
    if (data?.data?.status == 200) {
      dispatch({
        type: SUPPORT_CHAT_DATA,
        payload: data?.data?.data,
      });
    } 
    return data;
  } catch (error) {
    toast.error(error);
  }

};

// get FAQs  

export const  getFAQs = (details) => async (dispatch) => {
  try {
    const data = await API.get(`/faqs?limit=${details?.limit}&page=${details?.page}`);
    console.log(data);     
    if (data?.status == 200) {
      dispatch({
        type: FAQS_DATA,
        payload: data?.data?.data,
      });
      
    } 
    return data;
  } catch (error) {
    toast.error(error);
  }

};

// handle Faqs action 

export const  FaqAction = (details) => async (dispatch) => {
  try {
    const data = await API.put(`/faqs/${details?.id}`,details);

    if (data?.status == 200) {
      dispatch({
        type: FAQS_ACTION,
        payload: data?.data?.data,
      });
    }
  return data 
  } catch (error) {
    toast.error(error);
  }
};

// faqs details by id 

export const  faqsdetailsByid = (details) => async (dispatch) => {
  try {
    const data = await API.get(`/faqs/${details?.id}`);

    if (data?.status == 200) {
      dispatch({
        type: GET_FAQS_BY_ID,
        payload: data?.data?.data,
      });
    }
  return data 
  } catch (error) {
    toast.error(error);
  }
};



