import Layout from "../components/layouts/Layout";
import React, { useReducer } from "react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import LightGallery from "lightgallery/react";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import AirplayIcon from "@mui/icons-material/Airplay";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
/// Image
import profile from "../../images/profile/profile.png";
import MainPagetitle from "../components/layouts/MainPagetitle";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changePasswordDriver,
  deleteDriverAcc,

  updatepharmacistProfile,
  viewDriverDetail,
} from "../../store/actions/AdminAction";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { Button } from "@mui/material";
import { IMAGES } from "../constant/theme";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import LicenceImg from "../../images/profile/license-placeholder.png"
import uploadImg from "../../images/profile/profile-placeholder.png"

const DriverDetail = () => {
  const IMG_URL = process.env.REACT_APP_FILE_BASE_URL;
  const [profileImage, setProfileImage] = useState("");
  const [preview, setPreview] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [status, setStatus] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const driverDetail = useSelector((state) => state?.adminReducer?.driverDetail);
  useEffect(() => {
    dispatch(viewDriverDetail(id));
  }, []);
  const galleryBlog = [
    { image:driverDetail?.vehicle_photos[0]?IMG_URL+"/"+driverDetail?.vehicle_photos[0]:LicenceImg},
    { image:driverDetail?.vehicle_photos[1]?IMG_URL+"/"+driverDetail?.vehicle_photos[1]:LicenceImg},
    { image:driverDetail?.vehicle_photos[2]?IMG_URL+"/"+driverDetail?.vehicle_photos[2]:LicenceImg},
    { image:driverDetail?.vehicle_photos[3]?IMG_URL+"/"+driverDetail?.vehicle_photos[3]:LicenceImg},
    { image:driverDetail?.vehicle_photos[4]?IMG_URL+"/"+driverDetail?.vehicle_photos[4]:LicenceImg},
    { image:driverDetail?.vehicle_photos[5]? IMG_URL+"/"+driverDetail?.vehicle_photos[5]:LicenceImg},
  ];
  useEffect(() => {
    if (driverDetail) {
      setProfileImage(driverDetail?.profile_image);
      setFullName(driverDetail?.full_name);
      setEmail(driverDetail?.email);
      setPhoneNumber(driverDetail?.phone_number);
      setStatus(driverDetail?.status);
    }
  }, [driverDetail]);
  const handleSubmit = (e) => {
    const formData = new FormData();
    formData.append("driverId", driverDetail?._id);
    profileImage && formData.append("profile_image", profileImage);
    fullName && formData.append("full_name", fullName);
    email && formData.append("email", email);
    phoneNumber && formData.append("phone_number", phoneNumber);
    status && formData.append("status", status);
    dispatch(updatepharmacistProfile(formData));
  };
  const handleImageUpload = (e) => {
    if (e.target.files[0]) {
      setProfileImage(e.target.files[0]);
      setPreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleChangePass = () => {
    const dataVal = {
      userId: driverDetail?._id,
      password: password,
    };
    if (password) {
      if (password == confirmPassword) {
        dispatch(changePasswordDriver(dataVal));
      } else {
        toast.error("Password dosen't match");
      }
    }
  };

  return (
    <Layout>
      <div>
        <MainPagetitle
          mainTitle="Driver Detail & Edit"
          pageTitle={"Driver Detail"}
          parentTitle={"Home"}
        />
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="profile card card-body px-3 pt-3 pb-0">
                <div className="profile-head">
                  <div className="photo-content ">
                    {/* <div className="cover-photo rounded"></div> */}
                  </div>
                  <div className="profile-info">
                    <div className="user_detail_img_inner ">
                      {preview ? (
                        <img
                          src={preview}
                          className="img-fluid rounded-circle profile-image"
                          alt="profile"
                        />
                      ) : profileImage ? (
                        <img
                          src={IMG_URL + profileImage}
                          className="img-fluid rounded-circle profile-image"
                          alt="profile"
                        />
                      ) : (
                        <img
                          src={uploadImg}
                          className="img-fluid rounded-circle profile-image"
                          alt="profile"
                        />
                      )}
                    </div>
                    <input
                      className="user_detail_upload"
                      id="formFileSm"
                      type="file"
                      onChange={(e) => handleImageUpload(e)}
                      accept="image/*"
                    />
                    <div className="card-body">
                      <div className="basic-form">
                        <form onSubmit={(e) => e.preventDefault()}>
                          <div className="row">
                            <div className="form-group mb-3 col-md-6">
                              <label>Full Name</label>
                              <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="Full Name"
                                onChange={(e) => setFullName(e.target.value)}
                                value={fullName }
                              />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label>Email</label>
                              <input
                                type="text"
                                name="email"
                                className="form-control"
                                placeholder="Email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                              />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label>Phone Number</label>
                              <input
                                type="number"
                                name="phone_number"
                                className="form-control"
                                placeholder="Phone Number"
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                value={phoneNumber}
                              />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label>Status</label>
                              <select
                                defaultValue={"option"}
                                id="inputState"
                                className="form-control"
                                onChange={(e) => setStatus(e.target.value)}
                              >
                                <option value="option" disabled hidden>
                                  {status == 1
                                    ? "Active"
                                    : status == 0
                                    ? "Suspended"
                                    : "Select.."}
                                </option>
                                <option value="1">Active</option>
                                <option value="0">Suspended</option>
                              </select>
                            </div>
                          </div>
                          <div>
                            <button
                              onClick={() => handleSubmit()}
                              className="btn btn-primary"
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
          <div className="row">
            <div className="col-xl-6 col-lg-12">
              <div className="card">
                <div className="card-header border-0">
                  <h4 className="heading mb-0">Licence Info</h4>
                </div>
                <div className="card-body pt-0">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4">
                      <img src={driverDetail?.license_photo_front?IMG_URL+"/"+driverDetail?.license_photo_front:LicenceImg} alt="" className="w-100"  style={{height:"100px"}}/>
                    </div>
                    <div className="col-xl-8 col-lg-8">
                      <div className="row">
                        <div className="col-xl-10 col-lg-6">
                          <div className="c-card-details">
                            <ul>
                              <li>
                                <h6>License Number :</h6>
                                <span className="ms-1">{driverDetail?.license_number?driverDetail?.license_number:"N/A"}</span>
                              </li>
                              <li>
                                <h6>Issued Date :</h6>
                                <span className="ms-1">{driverDetail?.issued_date?driverDetail?.issued_date:"N/A"}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-12">
              <div className="card">
                <div className="card-header border-0">
                  <h4 className="heading mb-0">Vehicle information</h4>
                </div>
                <div className="card-body pt-0">
                  <div className="c-work ">
                    <LightGallery
                      // onInit={onInit}
                      speed={500}
                      plugins={[lgThumbnail, lgZoom]}
                      elementClassNames="row sp4"
                    >
                      {galleryBlog?.map((item, index) => (
                        <div
                          data-src={item?.image}
                          className="col-lg-2 pointerUser"
                          key={index}
                        >
                          <img
                            className="px-1 py-1 img-fluid rounded"
                            src={item?.image}
                            style={{ width: "100%", height:"80px"}}
                            alt="gallery"
                          />
                        </div>
                      ))}
                    </LightGallery>
                  </div>
                  <ul className="c-primium">
                    <li>
                        <h6>Vehicle Company</h6>
                        <span>{driverDetail?.vehicle_company?driverDetail?.vehicle_company:"N/A"}</span>
                      </li>
                      <li>
                        <h6>Vehicle Model</h6>
                        <span>{driverDetail?.vehicle_model?driverDetail?.vehicle_model:"N/A"}</span>
                      </li>
                      <li>
                        <h6>Vehicle Number</h6>
                        <span>{driverDetail?.vehicle_number?driverDetail?.vehicle_number:"N/A"}</span>
                      </li>
                      <li>
                        <h6>Purchased Date</h6>
                        <span>{driverDetail?.purchased_date?driverDetail?.purchased_date:"N/A"}</span>
                      </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Other Information</h4>
                </div>
                <div className="card-body">
                  <div className="basic-form">
                    <ul className="user_additional_info">
                      <li>
                        <div className="user_additional_info_inner">
                          <Button className="theme-change-btn">
                            <AirplayIcon />
                          </Button>
                        </div>
                        <div className="user_additional_info_content">
                          <p>Last Login</p>
                          <h2>
                            {moment(driverDetail?.lastLogin).format(
                              "Do MMMM YYYY"
                            )}
                          </h2>
                        </div>
                      </li>
                      <li>
                        <div className="user_additional_info_inner">
                          {/* <img  src={profile} alt="" /> */}
                          <Button className="theme-change-btn">
                            <SpaceDashboardIcon />
                          </Button>
                        </div>
                        <div className="user_additional_info_content">
                          <p>Last type</p>
                          <h2>Genreal</h2>
                        </div>
                      </li>
                      <li>
                        <div className="user_additional_info_inner">
                          <Button className="theme-change-btn">
                            <CalendarMonthIcon />
                          </Button>
                        </div>
                        <div className="user_additional_info_content">
                          <p>Registered Date</p>
                          <h2>
                            {moment(driverDetail?.createdAt).format(
                              "Do MMMM YYYY"
                            )}
                          </h2>
                        </div>
                      </li>
                      <li>
                        <div className="user_additional_info_inner">
                          <Button className="theme-change-btn">
                            <PhoneAndroidIcon />
                          </Button>
                        </div>
                        <div className="user_additional_info_content">
                          <p>Device Type</p>
                          <h2>
                            {driverDetail?.device_type == 1 ? "ios" : "android"}
                          </h2>
                        </div>
                      </li>
                      <li>
                        <div className="user_additional_info_inner">
                          <Button className="theme-change-btn">
                            <ReportGmailerrorredIcon />
                          </Button>
                        </div>
                        <div className="user_additional_info_content">
                          <p>Device Model</p>
                          <h2>{driverDetail?.device_model?driverDetail?.device_model:"N/A"}</h2>
                        </div>
                      </li>
                      <li>
                        <div className="user_additional_info_inner">
                          <Button className="theme-change-btn">
                            <LocationOnIcon />
                          </Button>
                        </div>
                        <div className="user_additional_info_content">
                          <p>Country</p>
                          <h2>N/A</h2>
                        </div>
                      </li>
                      <li>
                        <div className="user_additional_info_inner">
                          <Button className="theme-change-btn">
                            <PermIdentityIcon />
                          </Button>
                        </div>
                        <div className="user_additional_info_content">
                          <p>Gender</p>
                          <h2>N/A</h2>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Social</h4>
                </div>
                <div className="card-body">
                  <div className="basic-form">
                    <ul className="social_content">
                      <li>
                        <div className="social_content_img">
                          {/* <img  src={profile} alt="" /> */}
                          <Button className="theme-change-btn">
                            <LinkedInIcon />
                          </Button>
                        </div>
                        <div className="badge-primary light social_content_inner">
                          <p>N/A</p>
                        </div>
                      </li>
                      <li>
                        <div className="social_content_img">
                          <Button className="theme-change-btn">
                            <TwitterIcon />
                          </Button>
                        </div>
                        <div className="badge-primary light social_content_inner">
                          <p>N/A</p>
                        </div>
                      </li>
                      <li>
                        <div className="social_content_img">
                          <Button className="theme-change-btn">
                            <FacebookIcon />
                          </Button>
                        </div>
                        <div className="badge-primary light social_content_inner">
                          <p>N/A</p>
                        </div>
                      </li>
                      <li>
                        <div className="social_content_img">
                          <Button className="theme-change-btn">
                            <GitHubIcon />
                          </Button>
                        </div>
                        <div className="badge-primary light social_content_inner">
                          <p>N/A</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="row">
                <div className="col-xl-6 col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Deactivate Account</h4>
                    </div>
                    <div className="card-body">
                      <div className="basic-form">
                        <h6>
                          You will not be able to receive messages,
                          notifications for up to 24 hours.
                        </h6>
                        <div className="row align-items-center">
                          <div className="col-auto">
                            <label className="sr-only">Name</label>
                          </div>
                          <div className="col-xl-4 col-xxl-6 col-6">
                            <div className="form-check custom-checkbox mb-3 checkbox-success">
                              <input
                                type="checkbox"
                                defaultChecked
                                className="form-check-input"
                                id="customCheckBox3"
                                required
                              />
                              <label
                                className="form-check-label"
                                htmlFor="customCheckBox3"
                              >
                                Click here
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-8">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Delete Account</h4>
                    </div>
                    <div className="card-body">
                      <div className="basic-form">
                        <h6>
                          Once you delete the account, there is no going back.
                          Please be certain.
                        </h6>
                        <div className="row align-items-center">
                          <div className="col-auto">
                            <label className="sr-only">Name</label>
                          </div>
                          <div className="col-12">
                            <div className="form-check custom-checkbox mb-3 checkbox-success">
                              <button
                                className="btn btn-danger mb-2"
                                onClick={() =>
                                  dispatch(
                                    deleteDriverAcc(driverDetail?._id, navigate)
                                  )
                                }
                              >
                                Delete my account
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Reset Password</h4>
                </div>
                <div className="card-body">
                  <div className="basic-form">
                    {/* <form> */}
                    <div className="row">
                      <div className="form-group mb-3 col-md-6">
                        <label>New Password</label>
                        <input
                          type="password"
                          name="password"
                          className="form-control"
                          placeholder="Password"
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                        />
                      </div>
                      <div className="form-group mb-3 col-md-6">
                        <label>Confirm Password</label>
                        <input
                          type="password"
                          name="confirmPassword"
                          className="form-control"
                          placeholder="Password"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          value={confirmPassword}
                        />
                      </div>
                    </div>
                    <div className="row"></div>
                    <div className="form-group">
                      <div className=" "></div>
                    </div>
                    <button
                      onClick={() => {
                        handleChangePass();
                      }}
                      className="btn btn-primary"
                    >
                      Submit
                    </button>
                    {/* </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DriverDetail;
