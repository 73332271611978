export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";
export const NAVTOGGLE = "NAVTOGGLE";
export const GET_ALL_PHARMACIST = "GET_ALL_PHARMACIST";
export const GET_ALL_PATIENT = "GET_ALL_PATIENT";
export const VIEW_USER_DETAIL = "VIEW_USER_DETAIL";
export const UPDATE_PROFILE_DETAIL = "UPDATE_PROFILE_DETAIL";
export const UPDATE_PHARMACIST_PROFILE = "UPDATE_PHARMACIST_PROFILE";
export const VIEW_DRIVER_DETAIL = "VIEW_DRIVER_DETAIL";

export const GET_ALL_SERVICES = "GET_ALL_SERVICES";
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const UPDATE_PHARMACIST_SERVICE = "UPDATE_PHARMACIST_SERVICE";
export const GET_ALL_PRODUCTLIST = "GET_ALL_PRODUCTLIST";
export const ADD_PRODUCT_CATEGORY = "ADD_PRODUCT_CATEGORY";
export const GET_PRODUCSTS = "GET_PRODUCSTS";
export const ADD_PRODUCTS = "ADD_PRODUCTS";
export const DELETE_PRODUCT_CATEGOTY = "DELETE_PRODUCT_CATEGOTY";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const UPDATE_PRODUCT_CATEGORY = "UPDATE_PRODUCT_CATEGORY";
export const FIND_PRODUCTS = "FIND_PRODUCTS";
export const DELETE_SERVICE = "DELETE_SERVICE";
export const PRODUCT_STOCK = "PRODUCT_STOCK";
export const DROPDOWN_CATE_LIST = "DROPDOWN_CATE_LIST";
export const GET_PROMOCODE_LISTING = "GET_PROMOCODE_LISTING";
export const MANAGE_ORDER_LISTING = "MANAGE_ORDER_LISTING";
export const ADD_PROMOCODES = "ADD_PROMOCODES";
export const ORDERS_DETAILS = "ORDERS_DETAILS";
export const ORDERS_STATUS = "ORDERS_STATUS";
export const DELETE_PROMOCODE = "DELETE_PROMOCODE";
export const UPDATE_PROMOCODE = "UPDATE_PROMOCODE";
export const GET_BLOGS = "GET_BLOGS";
export const FIND_PROMOCODE = "FIND_PROMOCODE";
export const ADD_BLOGS = "ADD_BLOGS";
export const UPDATE_BLOG_STATUS = "UPDATE_BLOG_STATUS";
export const DEACTIVATE_USER = "DEACTIVATE_USER";
export const UNDEACTIVATE_USER = "UNDEACTIVATE_USER";
export const PAUSE_PROMOCODE = "PAUSE_PROMOCODE";
export const MANAGE_ADMIN_LIST = "MANAGE_ADMIN_LIST";
export const ADMIN_ADD_PERMISSION = "ADMIN_ADD_PERMISSION";
export const DEL_ADMIN_PERMISSION = "DEL_ADMIN_PERMISSION";
export const FIND_BLOGS = "FIND_BLOGS";
export const UPDATE_BLOGS = "UPDATE_BLOG";
export const DELETE_BLOG="DELETE_BLOG"
export const UPDATE_ADMIN_PERMISSION="UPDATE_ADMIN_PERMISSION"
export const GET_PROMOTION_LIST="GET_PROMOTION_LIST"
export const UPDATE_BLOGS_STATUS = "UPDATE_BLOGS_STATUS"
export const FIND_ADMIN_LIST="FIND_ADMIN_LIST"
export const ADD_PROMOTION="ADD_PROMOTION"
export const DELETE_PROMOTION="DELETE_PROMOTION"
export const ADD_NOTIFICATION="ADD_NOTIFICATION"
export const GET_NOTIFICATIONS="GET_NOTIFICATIONS"
export const BLOG_COMMENTS="BLOG_COMMENTS"
export const DELETE_NOTIFICATION="DELETE_NOTIFICATION"
export const ADD_COMMISSION="ADD_COMMISSION"
export const GET_COMMISSIONS="GET_COMMISSIONS"
export const UPDATE_COMMISSIONS="UPDATE_COMMISSIONS"
export const DELETE_COMMISSIONS="DELETE_COMMISSIONS"
export const SUPPORT_LIST="SUPPORT_LIST"
export const SUPPORT_ORDER_DETAIL="SUPPORT_ORDER_DETAIL"
export const SUPPORT_CHAT="SUPPORT_CHAT"
export const APPROVE_REJECT_BLOG="APPROVE_REJECT_BLOG"
export const CHAT_STATUS="CHAT_STATUS"
export const USERS_LIST="USERS_LIST"
export const SUPPORT_CHAT_DATA="SUPPORT_CHAT_DATA"
export const FAQS_DATA="FAQS_DATA"
export const FAQS_ACTION="FAQS_ACTION"
export const GET_FAQS_BY_ID="GET_FAQS_BY_ID"


