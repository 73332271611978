import Layout from "../components/layouts/Layout";
import React, { useReducer } from "react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import AirplayIcon from "@mui/icons-material/Airplay";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import SchoolIcon from "@mui/icons-material/School";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import TranslateIcon from "@mui/icons-material/Translate";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
/// Image
import profile from "../../images/profile/profile.png";
import uploadImg from "../../images/profile/profile-placeholder.png";
import MainPagetitle from "../components/layouts/MainPagetitle";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addProducts,
  DropdownCateList,
  changePassword,
  deleteUserAcc,
  updateUserProfile,
  viewUserDetail,
  productListing,
  ProdusctList,
} from "../../store/actions/AdminAction";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import Buttonreact from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { Button, Switch } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const CreateProduct = () => {
  const DropdownCategorylist = useSelector(
    (state) => state?.adminReducer?.drop_cate_list
  );
  console.log(DropdownCategorylist);

  const IMG_URL = process.env.REACT_APP_FILE_BASE_URL;
  const [profileImage, setProfileImage] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [images, setImages] = useState([]);
  const [imgArr, setImgArr] = useState([]);
  console.log(images);
  const [video, setvideo] = useState(null);
  console.log(video, "kjefbnlskmn;slegm");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  console.log(location);

  const [error, setError] = useState("");
  const [videoError, setVideoError] = useState("");
  const [previewUrl, setPreviewUrl] = useState("");

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(" Required"),
    productoverview: Yup.string().required("Required"),
    product_description: Yup.string().required("Required"),
    product_category_id: Yup.string().required("Required"),
    price: Yup.number()
      .required("Required")
      .positive("Price must be a positive number"),
    productimage: Yup.array().min(1, " Atleast select one image "),
    // video: Yup.mixed().required("Required"),
  });

  const [formData, setFormData] = useState({
    title: "",
    productoverview: "",
    product_description: "",
    product_category_id: "",
    price: "",
    productimage: [],
    video: "",
  });

  useEffect(() => {
    dispatch(DropdownCateList());
  }, []);

  // const handleVideoChange = (e) => {
  //   const file = e.target.files[0];
  //   setvideo(file);
  // };

  // useEffect(() => {
  //   return () => {
  //     if (video) {
  //       URL.revokeObjectURL(URL.createObjectURL(video));
  //     }
  //   };
  // }, [video]);

  // const handleVideoChange = (e, setFieldValue) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const videoElement = document.createElement("video");
  //     const fileURL = URL.createObjectURL(file);
  //     videoElement.src = fileURL;
  //     videoElement.onloadedmetadata = () => {
  //       if (videoElement.duration > 60) {
  //         setVideoError("Max 60 sec video only");
  //         setFieldValue("video", null);
  //         URL.revokeObjectURL(fileURL);
  //       } else {
  //         setVideoError("");
  //         setFieldValue("video", file);
  //         setPreviewUrl(fileURL);
  //       }
  //     };
  //   }
  // };

  const handleVideoChange = (e, setFieldValue) => {
    const file = e.target.files[0];
    if (file) {
      const videoElement = document.createElement("video");
      const fileURL = URL.createObjectURL(file);
      videoElement.src = fileURL;
      videoElement.onloadedmetadata = () => {
        if (videoElement.duration > 60) {
          setVideoError("Max 60 sec video only");
          setFieldValue("video", null);
          setPreviewUrl(null);
          URL.revokeObjectURL(fileURL);
        } else {
          setVideoError("");
          setFieldValue("video", file);
          setPreviewUrl(fileURL); // Update previewUrl with the new file URL
        }
      };
    }
  };
  


  useEffect(() => {
    let demo = images?.map((x) => x);
    setImgArr(demo);
    //  setError(false)
  }, [images]);

  return (
    <Layout>
      <div>
        <MainPagetitle
          mainTitle="Products"
          pageTitle="Create Product"
          parentTitle="Products"
        />
        <Formik
          initialValues={{
            title: "",
            productoverview: "",
            product_description: "",
            product_category_id: "",
            price: "",
            productimage: [],
            video: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            console.log(values);

            const formDataObj = new FormData();
            formDataObj.append("title", values.title);
            formDataObj.append("productoverview", values.productoverview);
            formDataObj.append(
              "product_description",
              values.product_description
            );
            formDataObj.append(
              "product_category_id",
              values.product_category_id
            );
            formDataObj.append("price", values.price);
            // formDataObj.append("productimage", imgArr);

            imgArr.forEach((image, index) => {
              formDataObj.append(`productimage`, image);
            });
            console.log(values, "werwe23423423423");
            formDataObj.append("video", values.video);

            try {
              const data = await dispatch(addProducts(formDataObj));
              console.log(data, "return data;return data;return data;");
              if (data?.status === 200) {
                toast.success(data?.data?.message);
                navigate("/Products");
              } else {
                toast.error(data?.data?.message);
              }
            } catch (error) {
              console.log(error);
            }
          }}
        >
          {({
            isSubmitting,
            values,
            submitForm,
            handleChange,
            setFieldValue,
          }) => (
            <Form>
              <div className="container-fluid">
                <div className="row">
                  <div
                    className="mb-3 col-auto float-end ms-auto "
                    // onClick={createProducthandler}
                  >
                    <button
                      className="btn btn-secondary"
                      type="button"
                      onClick={submitForm}
                    >
                      Create
                    </button>
                  </div>
                  <div className="col-lg-12">
                    <div className="profile card card-body px-3 pt-3 pb-0">
                      <div className="profile-head">
                        <div className="photo-content ">
                          {/* <div className="cover-photo rounded"></div> */}
                        </div>
                        <div className="profile-info">
                          <div className="card-body">
                            <div className="basic-form">
                              <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                  <label>Product name</label>
                                  <Field
                                    type="text"
                                    name="title"
                                    className="form-control"
                                    placeholder="Type Here"
                                    value={values?.title}
                                    onChange={handleChange}
                                  />
                                  <ErrorMessage
                                    name="title"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                  <label>Product Overview</label>
                                  <Field
                                    type="text"
                                    name="productoverview"
                                    className="form-control"
                                    placeholder="Type Here"
                                    value={values?.productoverview}
                                    onChange={handleChange}
                                  />
                                  <ErrorMessage
                                    name="productoverview"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                  <label>Product Description</label>
                                  <Field
                                    rows={3}
                                    name="product_description"
                                    className="form-control"
                                    value={values?.product_description}
                                    onChange={handleChange}
                                    placeholder="Type Here"
                                  />
                                  <ErrorMessage
                                    name="product_description"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                  <label>Categories</label>
                                  <Field
                                    as="select"
                                    name="product_category_id"
                                    className="form-select"
                                    aria-label="Default select example"
                                    value={values?.product_category_id}
                                    onChange={handleChange}
                                  >
                                    <option>Select Categories</option>
                                    {DropdownCategorylist?.map((data, i) => {
                                      return (
                                        <option value={data?._id}>
                                          {data?.title}
                                        </option>
                                      );
                                    })}
                                  </Field>
                                  <ErrorMessage
                                    name="product_category_id"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                  <label>Price</label>
                                  <Field
                                    type="number"
                                    name="price"
                                    className="form-control"
                                    placeholder="Type Here"
                                    value={values?.price}
                                    onChange={handleChange}
                                  />
                                  <ErrorMessage
                                    name="price"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>{" "}
                                <div className="form-group mb-3 col-md-6"></div>



                                <div className="form-group mb-3 col-md-6">
                                  <label>
                                    Add Optional video (max 60 seconds.)
                                  </label>
                                  <div className="user_detail_img_inner">
                                    <img
                                      src={require("../../images/uploadimage.png")}
                                    />
                                    <div className="main-img-contain">
                                   
                                      <div
                                  
                                        className="image-container"
                                      >


                                        {previewUrl ? (
                                          <div>
                                            <video
                                              controls
                                              width="130"
                                              height="130"
                                              style={{ objectFit: "cover" }}
                                            >
                                              <source
                                                src={previewUrl}
                                                type="video/mp4"
                                              />
                                              Your browser does not support the
                                              video tag.
                                            </video>
                                          </div>
                                        ) : (
                                          <input
                                            className="user_detail_upload"
                                            id="formFileSm"
                                            type="file"
                                            name="video"
                                            onChange={(e) =>
                                              handleVideoChange(
                                                e,
                                                setFieldValue
                                              )
                                            }
                                            accept="video/*"
                                          />
                                        )}
                                        {/* {video && (
                                          <video width="200"
                                          height="170"
                                          controls
                                          style={{ objectFit: "cover" }}>
                                            <source
                                             src={`https://api.telepharmacare.com/public/production/${video}`}
                                             type="video/mp4"
                                            />
                                            Your browser does not support the
                                            video tag.
                                          </video> */}
                                        {/* )} */}
                                      </div>
                                      {/* ))} */}
                                    </div>

                                    {videoError && <p>{videoError}</p>}

                                
                                  </div>
                                </div>

                                
                                <div className="form-group mb-3 col-md-6">
                                  <label>Product Images</label>
                                  <div className="user_detail_img_inner w-100">
                                    <img
                                      src={require("../../images/uploadimage.png")}
                                    />

                                    <input
                                      className="user_detail_upload"
                                      id="formFileSm"
                                      type="file"
                                      name="productimage"
                                      onChange={(e) => {
                                        const files = e.target.files;
                                        const selectedImages = [];
                                        Array.from(files).forEach((file) => {
                                          selectedImages.push(file);
                                        });
                                        if (selectedImages.length <= 3) {
                                          setImages(selectedImages);
                                          setFieldValue(
                                            "productimage",
                                            selectedImages
                                          );
                                        } else {
                                          toast.error(
                                            "Max Three images upload only"
                                          );
                                          setImages([]);
                                          setFieldValue("productimage", []);
                                        }
                                      }}
                                      accept="image/*"
                                      multiple
                                    />
                                    <ErrorMessage
                                      name="productimage"
                                      component="div"
                                      className="text-danger"
                                    />
                                 
                                  <div className="main-img-contain">
                                    {images.map((image, index) => (
                                      <div
                                        key={index}
                                        className="image-container"
                                      >
                                        <img
                                          src={URL.createObjectURL(image)}
                                          alt={`Preview ${index}`}
                                        />
                                      </div>
                                      
                                    ))}
                                     </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};

export default CreateProduct;
