import { applyMiddleware, combineReducers, compose,createStore,} from 'redux';
import PostsReducer, {toggleMenu} from './reducers/PostsReducer';
import thunk from 'redux-thunk';
import { AuthReducer } from './reducers/AuthReducer';
import todoReducers from './reducers/Reducers';
import AdminReducer from './reducers/AdminReducer';
//import { reducer as reduxFormReducer } from 'redux-form';
const middleware = applyMiddleware(thunk);

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
    sideMenu: toggleMenu,
    posts: PostsReducer,
    auth: AuthReducer,
		todoReducers,
   adminReducer: AdminReducer,
   productslist: AdminReducer,
   productlistmain:AdminReducer,
   dropdowncatelist:AdminReducer,
   promocode_list:AdminReducer,
   manageOrders:AdminReducer,
   blog_listing: AdminReducer,
   manageAdminListing:AdminReducer,
   Promotion_list:AdminReducer,
   get_notifications:AdminReducer,
   get_commissions:AdminReducer,
   getsupportlist:AdminReducer,
   getfaqs:AdminReducer,
//    getSupportChat:AdminReducer
	//form: reduxFormReducer,	
	
});

//const store = createStore(rootReducers);

export const store = createStore(reducers,  composeEnhancers(middleware));
