import Layout from "../components/layouts/Layout";
import React, { useReducer } from "react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import AirplayIcon from "@mui/icons-material/Airplay";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import SchoolIcon from "@mui/icons-material/School";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import TranslateIcon from "@mui/icons-material/Translate";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
/// Image
import profile from "../../images/profile/profile.png";
import uploadImg from "../../images/profile/profile-placeholder.png";
import MainPagetitle from "../components/layouts/MainPagetitle";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changePassword,
  deleteUserAcc,
  updateUserProfile,
  viewUserDetail,
} from "../../store/actions/AdminAction";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import Buttonreact from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Button, Switch } from "@mui/material";
import MyEditor from "../components/elements/TextEditor";
import { addPromotion } from "../../store/actions/AdminAction";
import { Formik, ErrorMessage, Form, Field } from "formik";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  img: yup.mixed().required(<span style={{ color: "red" }}>Required</span>),
});



// const handleChange = (e) => {
 
// };

const CreatePromotion = () => {


  const [formdata,setformdata]=useState({
    promotion_image:""
  })

  const [file, setFile] = useState();
  function handleChangefile(e, handleChange) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
    setformdata((old) => ({ ...old, promotion_image: e.target.files[0] }));
    handleChange(e);
    
  }


  const dispatch = useDispatch();
  const navigate = useNavigate();



  return (
    <Layout>
      <div>
        <MainPagetitle
          mainTitle="Create Promotion"
          pageTitle="Create Promotion"
          parentTitle="Promotions"
        />
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <Formik
                initialValues={{ img: null }}
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                  console.log(values);
                  try {
                    const formData = new FormData();
                    formData.append('promotion_image', formdata.promotion_image);

                    console.log("skdjfgjksdfjksdfh");
                    const data = await dispatch(
                      addPromotion(formData)
                    );
                    console.log(data);
                    if (data?.status == 200) {
                      toast.success(data?.data?.message);
                      setFile(null);
                      navigate('/promotion');
                    } else {
                      toast.error(data?.data?.message);
                    }
                  } catch (error) {
                    console.log(error, "facing error issue");
                  }
                }}
              >
                {({ values, handleSubmit, handleChange }) => (
                  <Form>
                    <div className="profile card card-body ">
                      <div className="main-promotion-box mt-4">
                        <h4 className="heading mb-0 text-center">
                          Promotion Banner
                        </h4>
                        <div className="upload-banner-input">
                          {" "}
                          <Field
                            type="file"
                            name="img"
                            onChange={(e) => handleChangefile(e, handleChange("img"))}
                          />
                          <ErrorMessage name="img" component="div" />
                          {file && (
                            <img
                              src={file}
                              alt="Uploaded Image"
                              style={{ maxWidth: "100%" }}
                            />
                          )}
                          <img src={file} />
                          <p>Upload Your banner</p>
                        </div>
                      </div>
                      <div className="col-lg-12 mb-3 mt-4 col-auto d-flex justify-content-center">
                        <button
                          className="btn btn-secondary "
                          type="submit"
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>{" "}
        </div>
      </div>
    </Layout>
  );
};

export default CreatePromotion;
