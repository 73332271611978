import React, { useEffect } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import { useState } from "react";



const EditEditor = ({ htmlHandle, givenContent, webData }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  console.log(webData,"webdata")

  // Convert HTML content to EditorState when the givenContent prop changes
  // useEffect(() => {
  //   if (givenContent) {
  //     const blocksFromHTML = convertFromHTML(givenContent);
  //     const contentState = ContentState.createFromBlockArray(blocksFromHTML);
  //     const newEditorState = EditorState.createWithContent(contentState);
  //     setEditorState(newEditorState);
  //   }
  // }, [givenContent]);
  useEffect(() => {
    

    // if (givenContent) {
    //   const contentState = webData == undefined ? null : convertFromRaw(JSON.parse(webData));
    //   setEditorState(EditorState.createWithContent(contentState));
    // }


    if (givenContent) {
      try {
        if (webData) {
          const contentState = convertFromRaw(JSON.parse(webData));
          setEditorState(EditorState.createWithContent(contentState));
        } else {
          const blocksFromHTML = convertFromHTML(givenContent);
          const contentState = ContentState.createFromBlockArray(blocksFromHTML);
          const newEditorState = EditorState.createWithContent(contentState);
          setEditorState(newEditorState);
        }
      } catch (error) {
        console.error("Error parsing webData:", error);
        const blocksFromHTML = convertFromHTML(givenContent);
        const contentState = ContentState.createFromBlockArray(blocksFromHTML);
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
      }
    }
  }, [givenContent, webData]);

  const fontFamilyStyleMap = {
    // Arial: {
    //   fontFamily: "Arial, sans-serif",
    // },
    // "Times New Roman": {
    //   fontFamily: "Times New Roman, serif",
    // },
    // Add more font families as needed
    Poppins: {
      fontFamily: "Poppins, sans-serif",
    },
  };

  const onEditorStateChange = (newEditorState) => {
    //     setEditorState(newEditorState);
    // let blogContent = JSON.stringify(convertToRaw(newEditorState.getCurrentContent()))

    //     const editorStateInHtml = draftToHtml(
    //       convertToRaw(newEditorState.getCurrentContent())
    //     );

    // setDescription({
    //   htmlValue: editorStateInHtml,
    //   editorState: blogContent,
    // });

    // const editorStateInHtml = draftToHtml(
    //   convertToRaw(newEditorState.getCurrentContent())
    // );

    htmlHandle(
      JSON.stringify(convertToRaw(newEditorState.getCurrentContent()))
    );

    setEditorState(newEditorState);
    // // htmlHandle(editorStateInHtml);
  };

  return (
    <div>
      <Editor
        toolbarHidden={false}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        placeholder="Description..."
        
      />
    </div>
  );
};

export default EditEditor;
