import React from "react";

const Footer = () => {
  var d = new Date();
  return (
    <div className="footer out-footer">
      <div className="copyright">
        <p>© 2024 Telepharmacare AB All rights reserved</p>
      </div>
    </div>
  );
};

export default Footer;
