import React, { useEffect, useState } from "react";
import Layout from "../components/layouts/Layout";
import { IMAGES } from "../constant/theme";
import MainPagetitle from "../components/layouts/MainPagetitle";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { Description, Details } from "@mui/icons-material";
import { Button, Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import {
  addNotification,
  getNotifications,
  DeleteNotification,
  AddCommission,
  getCommissions,
  updateCommission,
  deleteCommision,
} from "../../store/actions/AdminAction";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";

const validationSchema = Yup.object().shape({
  title: Yup.string().required(<span style={{ color: "red" }}>Required</span>),
  // commision: Yup.string().required(<span style={{ color: "red" }}>Required</span>),
  commision: Yup.string()
  .matches(/^[0-9]+$/, "Commission must be a number")
  .required(<span style={{ color: "red" }}>Required</span>),

});

const Commission = () => {
  const commissiondata = useSelector(
    (state) => state?.get_commissions?.getcommi
  );
  console.log(commissiondata);

  const [showd, setshowd] = useState(false);
  const [sho, setsho] = useState(false);
  const [ID, setID] = useState("");


  const [notificationid, setNotificationid] = useState("");
  const [limit, setlimit] = useState(10);
  const [pagination, setpagination] = useState("");
  console.log(pagination);
  const [details, setdetails] = useState([]);
  console.log(details);
  const [title, setTitle] = useState("Add Commission");
  const [categoryid, setcategoryid] = useState("");
  const [page, setPage] = useState(1);
  const [flag, setflag] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  

  const lastIndex = page * limit;
  const npage = Math.ceil(
    (pagination?.total == undefined ? 0 : pagination?.total) / limit
  );
  const number = npage ? [...Array(npage + 1).keys()]?.slice(1) : [1];

  function prePage() {
    if (page !== 1) {
      setPage(page - 1);
    }
  }
  function changeCPage(id) {
    setPage(id);
  }
  function nextPage() {
    if (page !== npage) {
      setPage(page + 1);
    }
  }
  const [show, setshow] = useState(false);
  const handleClose = () => setshow(false);
  const handleShow = () => setshow(true);

  const dispatch = useDispatch();

  useEffect(() => {
    const getdata = async () => {
      try {
        const data = await dispatch(
          getCommissions({ limit: limit, page: page })
        );
        console.log(data);
        setpagination(data?.data?.pagination);
        setdetails(data?.data?.data);
      } catch (error) {}
    };
    getdata();
  }, [limit, page, flag]);

  const formik = useFormik({
    initialValues: {
      title: "",
      commision: "",
    },
    validationSchema,

    onSubmit: (values) => {
      const fomdate = new FormData();

      fomdate.set("title", values.title);
      fomdate.set("commision", values.commision);

      if (categoryid != "") {
        fomdate.set("commision_id", categoryid);
        dispatch(updateCommission(fomdate)).then((res) => {
          if (res?.data?.status == 200) {
            setflag(!flag);
            setshow(false);
          }else{
            formik.setFieldValue("title", "");
            formik.setFieldValue("commision", "");
          }
        });
       

        setcategoryid("");

      } else {
        dispatch(AddCommission(fomdate)).then((res) => {
          console.log(res, "res");
          if(res?.status === 200){
            setflag(!flag)
          }
        });
        setshow(false);
      }

      
    },
  });


  const deletePRoductHandler = async () => {
    try {
      const data = await dispatch(deleteCommision({id:ID}));
      console.log(data);
      setsho(false);
      setflag(!flag);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <MainPagetitle
        mainTitle="Commission"
        pageTitle="Commission"
        parentTitle="Home"
      />
      <div className="container-fluid">
        <div className="row">
          {commissiondata?.length === 0 && ( 
          <div className="mb-3 col-auto float-end ms-auto ">
            <button
              className="btn btn-secondary "
              onClick={() => {
                formik.setFieldValue("title", "");
                formik.setFieldValue("commision", "");
                setcategoryid("");
                setTitle("Add Commission");
                handleShow();
              }}
            >
              Add Commission
            </button>
          </div>
            )}
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects manage-client">
                  <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                    <h4 className="heading mb-0">Commission</h4>
                    {/* <div>
                                            <CSVLink {...csvlink} className="btn btn-primary light btn-sm">
                                                <i className="fa-solid fa-file-excel" /> {" "} 
                                                Export Report
                                            </CSVLink> 
                                        </div> */}
                  </div>
                  <div
                    id="manage-tblwrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="reports-tbl"
                      className="table ItemsCheckboxSec dataTable no-footer mb-0"
                    >
                      <thead>
                        <tr>
                          <th>S.no</th>
                          <th>Title</th>
                          <th>Commission</th>                         
                          <th>Action</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {commissiondata?.map((data, index) => {
                          console.log(data);
                          const serialNumber = (page - 1) * limit + index + 1;
                          return (
                            <tr>
                              <td>{serialNumber}</td>
                              <td>{data?.title}</td>
                              <td>{data?.commision}%</td>
                              
                              <td>
                                <span className="badge light border-0 pl-0">
                                  <button
                                    onClick={() => {
                                      formik.setFieldValue(
                                        "title",
                                        data?.title
                                      );
                                      formik.setFieldValue(
                                        "commision",
                                        data?.commision
                                      );
                                      setcategoryid(data?._id);
                                      handleShow();
                                      setTitle("Update Commission");
                                    }}
                                    className="badge border-0 badge-success"
                                  >
                                    <i class="fa fa-pencil"></i>
                                  </button>
                                </span>
                              </td>
                              <td>
                                <span
                                  className="badge-danger light border-0 p-2"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    

                                    {
                                      setsho(true);
                                      setID(data?._id);
                                    }
                                  }
                                >
                                  Delete
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {lastIndex - limit + 1} to{" "}
                        {pagination?.total &&
                        pagination?.total < lastIndex
                          ? pagination?.total
                          : lastIndex}{" "}
                        of {pagination?.total && pagination?.total}{" "}
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                page === n ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Title"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="title"
                value={formik.values.title}
                // autoFocus
              />
              {formik.submitCount > 0 && formik.errors.title && (
                <div className="text-danger fs-12 mt-2">
                  {formik.errors.title}
                </div>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Commission</Form.Label>
              <Form.Control
                type="text"
                placeholder="Commission"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="commision"
                value={formik.values.commision}
                // autoFocus
              />
              {formik.submitCount > 0 && formik.errors.commision && (
                <div className="text-danger fs-12 mt-2">
                  {formik.errors.commision}
                </div>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-secondary " onClick={handleClose}>
              Cancel
            </Button>
            <Button className="btn btn-secondary " type="submit">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* delete notification */}
      <Modal show={sho} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to Delete this Account
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={deletePRoductHandler}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setsho(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

export default Commission;
