


import React, { useEffect } from 'react'
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from 'react-redux';
import { dashboardction } from '../../../store/actions/AdminAction';
export default function ProjectStatusBlogDonutChart({userDetail}) {
    const data= {
        series: [(userDetail?.complete==undefined?0:userDetail?.complete), (userDetail?.pending==undefined?0:userDetail?.pending), ],

      options: {
        chart: {
			type: 'donut',
			width: 250,
		},
        colors: [ '#3AC977','#FF5E5E'],
        labels: ["Compete", "Pending"],
        dataLabels: {
            enabled: false,
        },      
        
        legend: {
          show: false,
        },
        plotOptions: {          
                
            pie: {
                donut: {
                  size: '90%',
                  labels: {
                      show: true,
                      name: {
                          show: true,
                          offsetY: 12,
                      },
                      value: {
                          show: true,
                          fontSize: '24px',
                          fontFamily:'Arial',
                          fontWeight:'500',
                          offsetY: -17,
                      },
                      total: {
                          show: true,
                          fontSize: '11px',
                          fontWeight:'500',
                          fontFamily:'Arial',
                          label: 'Total Singup',
                         
                          formatter: function (w) {
                            return w.globals.seriesTotals.reduce((a, b) => {
                              return a + b
                            }, 0)
                          }
                      }
                  }
                }
            }

        },
       
      },
    };
  
  return (
    <div id="projectChart" className="project-chart">
    <ReactApexChart
     options={data.options}
     series={data.series}
      type="donut"
      width={250}
    />
  </div>
  )
}