import React, { useEffect, useState } from "react";
import Layout from "../components/layouts/Layout";
import { IMAGES } from "../constant/theme";
import MainPagetitle from "../components/layouts/MainPagetitle";
import { CSVLink } from "react-csv";
import { Link, useNavigate } from "react-router-dom";
import { manageAdminList } from "../../store/actions/AdminAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { DELadminPermission } from "../../store/actions/AdminAction";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";




const ManageAdmins = () => {
   
  const adminData =useSelector((state)=> state?.manageAdminListing?.Admin_list);
  console.log(adminData);
  const[limit,setlimit]=useState(10);
  const[Pagination,setPagination]=useState("");
  console.log(Pagination?.count,"jjjjjjjjjjjjjjjj")
  const[page,setpage]=useState(0);
  const[showd,setshowd]=useState(false);
  const[flag,setflag]=useState(false);
  const[id,setid]=useState(false);

  //  const lastIndex = page * limit;

  //  const npage = Math.ceil(
  //   (Pagination?.count == undefined ? 0 : Pagination?.count) /
  //     limit
  // );
  const lastIndex = (page + 1) * limit; 
const startRange = lastIndex - limit + 1;
const endRange =
  Pagination?.count && Pagination.count < lastIndex
    ? Pagination.count
    : lastIndex;
  const number = page ? [...Array(page + 1).keys()]?.slice(1) : [1];

  function prePage() {
    if (page !== 0) {
      setpage(page - 1);
    }
  }
  function changeCPage(id) {
    setpage(id);
  }
  function nextPage() {
    if (page !== page) {
      setpage(page + 1);
    }
  }
  const dispatch = useDispatch();

  useEffect(()=> {
      const fetchdata = async() => {
     const data = await dispatch(manageAdminList({limit:limit, page:page}));
     console.log(data);
     if(data?.status === 200){
      setPagination(data?.data)
     }
      }
      fetchdata();
  },[limit, page, flag]);

  



  const deletehandler = async () => {
    try {
      const data = await dispatch(DELadminPermission({ id: id}));
      console.log(data);
      if (data?.status == 200) {
        toast.success(data?.data?.message);
        setshowd(false);
      } else {
        toast.error(data?.data?.message);
      }
      setflag(!flag);
    } catch (error) {
      console.log(error);
    }
  };

 const navigate = useNavigate();
  const handleuprofile = (admin) => {
    navigate("/view-admin", {
      state: {
        id: admin,

      },
    });
  };


  return (
    <Layout>
      <MainPagetitle
        mainTitle="Manage Admin"
        pageTitle="Manage Admin"
        parentTitle="Home"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="mb-3 col-auto float-end ms-auto ">
            <Link to="/create-Admin" className="btn btn-secondary ">
              Create Admin
            </Link>
          </div>
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects manage-client">
                  <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                    <h4 className="heading mb-0">Manage Admin</h4>
                    {/* <div>
                                            <CSVLink {...csvlink} className="btn btn-primary light btn-sm">
                                                <i className="fa-solid fa-file-excel" /> {" "} 
                                                Export Report
                                            </CSVLink> 
                                        </div> */}
                  </div>
                  <div
                    id="manage-tblwrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="reports-tbl"
                      className="table ItemsCheckboxSec dataTable no-footer mb-0"
                    >
                      <thead>
                        <tr>
                          <th>id #</th>
                          <th>created</th>
                          <th>name</th>
                          <th>role</th>
                          <th>permissions</th>
                          <th>last active</th>
                          <th>action</th>
                        </tr>
                      </thead>
                      <tbody>
                         {adminData?.map((details, index) => {
                          const serialNumber = (page - 0) * limit + index + 1;
                          console.log(details);
                          return(
                          
                          <tr>
                            <td>{serialNumber}</td>
                            <td>{moment(details.createAt).format('YYYY-MM-DD')}</td>
                            <td>
                              <p style={{ cursor:"pointer" }} onClick={ () => {handleuprofile(details?._id) }}>
                                <h6>{details.name}</h6>
                              </p>
                            </td>
                            <td>
                              <span>{details.role}</span>
                            </td>
                            <td>
                            <span>{details.permissions.map(permission => permission.charAt(0).toUpperCase() + permission.slice(1)).join(', ')}</span>

                            </td>
            
                            <td>
                              <span>{moment(details.last_active).format('YYYY-MM-DD')}</span>
                            </td>
                            <td style={{ textAlign: "left" }}>
                              <span className="badge-danger light border-0 p-2"
                              style={{ cursor:"pointer" }}  
                              onClick={() => {setshowd(true)
                                setid(details?._id) }}>
                                Delete
                                {details.action}
                              </span>
                            </td>
                          </tr>
                        )
                      })}
                      </tbody>               
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                      {/* Showing {lastIndex - limit + 1} to{" "}
                      {Pagination?.count &&
                      Pagination?.count < lastIndex
                        ? Pagination?.count
                        : lastIndex}{" "}
                      of{" "}
                      {Pagination?.count && Pagination?.count}{" "} */}
                       Showing {startRange} to {endRange} of {Pagination?.count || 0}
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number?.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                page === i ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(i)}
                            >
                              {i+1}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showd} animation={false} className="delete-popup">
      <Modal.Body className="text-center">
        <h2 className="are-sure-text">
          Are you sure you want to Delete this Account
        </h2>
      </Modal.Body>
      <Modal.Footer className="justify-content-center border-none">
        <Button className="theme-btn" onClick={deletehandler}>
          Yes
        </Button>
        <Button className="theme-btn" onClick={() => setshowd(false)}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
    </Layout>
  );
};

export default ManageAdmins;
