import Layout from "../components/layouts/Layout";
import React, { useReducer, useRef } from "react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import AirplayIcon from "@mui/icons-material/Airplay";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import SchoolIcon from "@mui/icons-material/School";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import TranslateIcon from "@mui/icons-material/Translate";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
/// Image
// import logo from "../../images/user1.png"
import profile from "../../images/profile/profile.png";
import uploadImg from "../../images/profile/profile-placeholder.png";
import MainPagetitle from "../components/layouts/MainPagetitle";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  faqsdetailsByid,
  getsupportChatdata,
  getsupportchatdetails,
  supportChat,
} from "../../store/actions/AdminAction";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import * as yup from "yup";
import { Formik, ErrorMessage } from "formik";

import Modal from "react-bootstrap/Modal";
import { Button, Switch } from "@mui/material";
import OwlCarousel from "react-owl-carousel";
import { useLocation } from "react-router-dom";
import { Form } from "react-bootstrap";
import { CleanHands, Flag, Message } from "@mui/icons-material";
import Placholder from "../../images/placholder-img-admin1.png";




const FAQChat = () => {
  
  const [flag, setflag] = useState(false);
  const [Faqdetails, setFaqdetails] = useState([]);

  const location = useLocation();
  const dispatch = useDispatch();
  
 

  useEffect(() => {
    const getdata = async () => {
      const data = await dispatch(faqsdetailsByid({ id: location?.state?.id }));
      console.log(data);
      if (data?.status === 200) {
        setFaqdetails(data?.data?.data);
      }
    };
    getdata();
  }, [flag]);

  

  return (
    <Layout>
      <div>
        <MainPagetitle
          mainTitle="FAQ chat"
          pageTitle="FAQ chat"
          parentTitle="FAQ"
        />
        <div className="container-fluid">
          <Link to="/faq-support" className="btn btn-secondary ">
            Back
          </Link>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <div className="col-lg-12">
                <div className="profile card card-body px-3 pt-3 pb-0">
                  <div className="profile-head">
                    <h4>User Details</h4>

                    <div className="profile-info">
                      <div className="profile-info">
                        <div className="user_detail_img_inner">
                          <img
                            // src={require("../../images/avatar/1.png")}
                            src={
                              Faqdetails?.user_id?.main_image
                                ? `https://api.telepharmacare.com/user_docs/${Faqdetails?.user_id?.main_image}`
                                : uploadImg
                            }
                            className="img-fluid rounded-circle profile-image"
                            alt="profile"
                          />
                        </div>
                      </div>
                      <div className="card-body d-flex align-items-center">
                        <div className="user-data-view">
                          <h5>{`${Faqdetails?.first_name} ${Faqdetails?.last_name}`}</h5>
                          <h5>{Faqdetails?.email}</h5>
                          <h5>{`${Faqdetails?.country_code || ""} ${
                            Faqdetails?.phone_number || ""
                          }`}</h5>
                          {/* <h5 className="mb-0">Lorem Ipsum is simply </h5> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                {/* <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Title"
                  name="title"
                  disabled
                  readOnly
                  // value={MesssageTitle?.title}
                /> */}
                <br />
                <Form.Label>Question</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Question.."
                  // name="description"
                  disabled
                  readOnly
                  value={Faqdetails?.question}
                />
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FAQChat;
