import Layout from "../components/layouts/Layout";
import React, { useReducer } from "react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import AirplayIcon from "@mui/icons-material/Airplay";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import SchoolIcon from "@mui/icons-material/School";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import TranslateIcon from "@mui/icons-material/Translate";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import InterestsIcon from '@mui/icons-material/Interests';
/// Image
import profile from "../../images/profile/profile.png";
import uploadImg from "../../images/profile/profile-placeholder.png";
import MainPagetitle from "../components/layouts/MainPagetitle";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changePassword,
  deleteUserAcc,
  updateUserProfile,
  viewUserDetail,
  DeactivateUser,
  UnDeactivateUser,
} from "../../store/actions/AdminAction";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import Buttonreact from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Button, Switch, checkboxClasses } from "@mui/material";
import SourceIcon from '@mui/icons-material/Source';
import { Details } from "@mui/icons-material";
import InfoIcon from '@mui/icons-material/Info';
const Pharmacistdetail = () => {
  const location = useLocation();
  console.log(location?.state?.nav);
  const [images, setImages] = useState([]);
  const [file, setFile] = useState();
  function handleChange(e) {
    console.log(e.target.files);
    // setFile(URL.createObjectURL(e.target.files[0]));

    if (e.target.files && e.target.files.length > 0) {
      setFile(URL.createObjectURL(e.target.files[0]));
  } else {
      console.error('No files selected');
  }
  }

  const [file2, setFile2] = useState();
  function handleChange2(e) {
    console.log(e.target.files);
    setFile2(URL.createObjectURL(e.target.files[0]));
  }

  const [file3, setFile3] = useState();
  
  function handleChange3(e) {
    console.log(e.target.files,"files");
    if(e.target.files != null){
      setFile3(URL.createObjectURL(e?.target?.files[0]));

    }
    
  }

  const IMG_URL = process.env.REACT_APP_FILE_BASE_URL;
  const [profileImage, setProfileImage] = useState("");

  const [showAD, setShowAD] = useState(false);
  const handleADClose = () => setShowAD(false);
  const handleADShow = () => setShowAD(true);

  // const [showDC, setShowDC] = useState(false);
  // const handleDCClose = () => setShowDC(false);
  // const handleDCShow = () => setShowDC(true);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [preview, setPreview] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [flag, setflag] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [CountryCode, setCountryCode] = useState("");
  console.log(CountryCode);
  const [status, setStatus] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const { id } = useParams();
  console.log(id);
  const [isChecked, setIsChecked] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetail = useSelector((state) => state?.adminReducer?.userDetail);
  console.log(userDetail, "userDetail");
  useEffect(() => {
    dispatch(viewUserDetail(id));
  }, [flag]);

  useEffect(() => {
    if (userDetail) {
      setProfileImage(userDetail?.main_image);
      setFullName(userDetail?.name);
      setEmail(userDetail?.email);
      setPhoneNumber(userDetail?.phone_number);
      setCountryCode(userDetail?.country_code);
      setStatus(userDetail?.status);

    }
  }, [userDetail]);
  const handleSubmit = (e) => {
    const formData = new FormData();
    formData.append("id", userDetail?._id);
    profileImage && formData.append("main_image", profileImage);
    fullName && formData.append("name", fullName);
    email && formData.append("email", email);
    phoneNumber && formData.append("phone_number", phoneNumber);

    dispatch(updateUserProfile(formData));
  };
  const handleImageUpload = (e) => {
    if (e.target.files[0]) {
      setProfileImage(e.target.files[0]);
      setPreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleChangePass = () => {
    const dataVal = {
      userId: userDetail?._id,
      password: password,
    };
    if (password) {
      if (password == confirmPassword) {
        dispatch(changePassword(dataVal));
        setPassword("");
        setConfirmPassword("");
      } else {
        toast.error("Password dosen't match");
      }
    }
  };

  const handlerDeactivate = async () => {
    console.log("jabsdflkasdnm");
    try {
      const data = await dispatch(DeactivateUser({ userId: id }));
      console.log(data);
      if (data?.status == 200) {
        toast.success(data?.data?.message);
        setflag(!flag);
        setShowAD(false);
      } else {
        toast.error(data?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const HandlerUnactivate = async () => {
    console.log("jabsdflkasdnm");
    try {
      const data = await dispatch(UnDeactivateUser({ userId: id }));
      console.log(data);
      if (data?.status == 200) {
        toast.success(data?.data?.message);
        setflag(!flag);
        setShowAD(false);
      } else {
        toast.error(data?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setIsChecked(userDetail?.isActive);
  }, [userDetail]);

  const handleSwitchChange = () => {
    setIsChecked(!isChecked);
    if (!isChecked) {
      handlerDeactivate();
    } else {
      HandlerUnactivate();
    }
  };

  const openLightbox = (url) => {
    setSelectedImage(url);
  };
  // console.log(selectedImage);

  const closeLightbox = () => {
    setSelectedImage(null);
  };

 
 
  return (
    <Layout>
      <div>
        <MainPagetitle
          mainTitle={`${
            userDetail?.role == 2 ? "Pharmacist" : "Patients"
          } Details & Edit`}
          pageTitle={`${
            userDetail?.role == 2 ? "Pharmacist" : "Patients"
          } Details`}
          parentTitle={"Home"}
        />
        <div className="container-fluid">
          <Link
            to={location?.state?.nav ? "/Patient" : "/Pharmacist"}
            className="btn btn-secondary "
          >
            Back
          </Link>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="profile card card-body px-3 pt-3 pb-0">
                <div className="profile-head">
                  <div className="photo-content ">
                    {/* <div className="cover-photo rounded"></div> */}
                  </div>
                  <div className="profile-info">
                    <div className="user_detail_img_inner">
                      {preview ? (
                        <img
                          src={preview}
                          className="img-fluid rounded-circle profile-image"
                          alt="profile"
                        />
                      ) : profileImage ? (
                        <img
                          src={IMG_URL + "/user_docs/" + profileImage}
                          className="img-fluid rounded-circle profile-image"
                          alt="profile"
                        />
                      ) : (
                        <img
                          src={uploadImg}
                          className="img-fluid rounded-circle profile-image"
                          alt="profile"
                        />
                      )}
                      {/* <img src={`https://api.telepharmacare.com/user_docs/${userDetail?.main_image}`}/> */}
                    </div>
                    <input
                      className="user_detail_upload"
                      id="formFileSm"
                      type="file"
                      onChange={(e) => handleImageUpload(e)}
                      accept="image/*"
                    />
                    <div className="card-body">
                      <div className="basic-form">
                        <form onSubmit={(e) => e.preventDefault()}>
                          <div className="row">
                            <div className="form-group mb-3 col-md-6">
                              <label>Full Name</label>
                              <input
                                type="text"
                                name="full_name"
                                className="form-control"
                                placeholder="1234 Main St"
                                onChange={(e) => setFullName(e.target.value)}
                                value={fullName + " " + userDetail?.last_name}
                              />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label>Email</label>
                              <input
                                type="text"
                                name="email"
                                className="form-control"
                                placeholder="1234 Main St"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                              />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label>Country Code</label>
                              <input
                                // inputmode="numeric"
                                // type="number"
                                name="CountryCode"
                                className="form-control"
                                placeholder="CountryCode"
                                onChange={(e) => setCountryCode(e.target.value)}
                                value={CountryCode}
                              />
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label>Phone Number</label>
                              <input
                                inputmode="numeric"
                                type="number"
                                name="phone_number"
                                className="form-control"
                                placeholder="Phone Number"
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                value={phoneNumber}
                              />
                            </div>
                          </div>
                          <div>
                            <button
                              onClick={() => handleSubmit()}
                              className="btn btn-primary"
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {userDetail?.role == 2 && (
              <div className="col-lg-6">
                <div className="profile card card-body px-3 pt-3 pb-0 ">
                  <div className="row">
                    <div className="col-lg-12 px-0">
                      <div className="card-header">
                        <h4 className="card-title">Documents</h4>
                      </div>
                    </div>

                    <div className="row mt-4 pb-4">
                      <div className="col-lg-4">
                        <div className="form-group mb-3 col-md-4">
                          <label>Front</label>
                          <div className="user_detail_img_inner">
                            {userDetail?.main_image && (
                              <img
                                src={`https://api.telepharmacare.com/user_docs/${userDetail?.image_id}`}
                              />
                            )}
                            
                            <div className="main-img-contain">
                              {/* //src={file} */}
                            </div>
                            <input
                              className="user_detail_upload"
                              id="formFileSm"
                              // type="file"
                              // onChange={handleChange}
                              onClick={() => {
                                console.log("first");
                                openLightbox(
                                  `https://api.telepharmacare.com/user_docs/${userDetail?.image_id}`
                                );
                              }}
                              // accept="image/*"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group mb-3 col-md-4">
                          <label>Back</label>
                          <div className="user_detail_img_inner">
                            {userDetail?.Back_image && (
                              <img
                                src={`https://api.telepharmacare.com/user_docs/${userDetail?.Back_image}`}
                              />
                            )}

                            <div className="main-img-contain">
                              {/* <img src={file2} /> */}
                            </div>
                            <input
                              className="user_detail_upload"
                              id="formFileSm"
                              // type="file"
                              // onChange={handleChange2}
                              onClick={() => {
                                console.log("first");
                                openLightbox(
                                  `https://api.telepharmacare.com/user_docs/${userDetail?.Back_image}`
                                );
                              }}
                              // accept="image/*"
                            />
                          </div>
                        </div>

                        {selectedImage !== null && (
                          <div className="lightbox">
                            <span className="close" onClick={closeLightbox}>
                              &times;
                            </span>
                            <img
                              src={selectedImage}
                              alt={`Image ${selectedImage}`}
                              style={{
                                maxWidth: "100%",
                                maxHeight: "80vh",
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group mb-3 col-md-4">
                          <label>Selfie</label>
                          <div className="user_detail_img_inner">
                            {userDetail?.main_image && (
                              <img
                                src={`https://api.telepharmacare.com/user_docs/${userDetail?.Selfie_image}`}
                              />
                            )}
                           
                            <div className="main-img-contain">
                              {/* <img src={file3} /> */}
                            </div>
                            <input
                              className="user_detail_upload"
                              id="formFileSm"
                              // type="file"
                              // onChange={handleChange3}
                              onClick={() => {
                                console.log("first");
                                openLightbox(
                                  `https://api.telepharmacare.com/user_docs/${userDetail?.Selfie_image}`
                                );
                              }}
                              // accept="image/*"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            )}
          </div>{" "}

          {userDetail?.role == 2 && (



<div className="col-lg-12">
<div className="profile card card-body px-3 pt-3 pb-0">
  <div className="row">
    <div className="col-lg-12 px-0">
      <div className="card-header">
        <h4 className="card-title">Certificates</h4>
      </div>
    </div>

    <div className="row mt-4 pb-4">
      {/* {userDetail?.image?.map((image, index) => ( */}
      {userDetail?.image?.map((image, index) => {
        console.log(userDetail?.image);
        return(

      
        <div key={index} className="col-lg-4">
          <div className="form-group mb-3 col-md-4">
            <label>{`Image ${index + 1}`}</label>
            <div className="user_detail_img_inner">
              {image && (
                <img
                  src={`https://api.telepharmacare.com/user_docs/${image}`}
                  alt={`User Document ${index + 1}`}
                />
              )}
              <div className="main-img-contain">
                {/* Placeholder for additional content */}
              </div>
              <input
                className="user_detail_upload"
                id={`formFileSm-${index}`}
                // type="file"
                onChange={(e) => handleChange(e, index)}
                onClick={() => {
                  console.log("first");
                  openLightbox(`https://api.telepharmacare.com/user_docs/${image}`);
                }}
                accept="image/*"
              />
            </div>
          </div>
        </div>
       )
      })}

      {selectedImage !== null && (
        <div className="lightbox">
          <span className="close" onClick={closeLightbox}>
            &times;
          </span>
          <img
            src={selectedImage}
            alt="Selected"
            style={{
              maxWidth: '100%',
              maxHeight: '80vh',
            }}
          />
        </div>
      )}
    </div>
  </div>
</div>
</div>
  )}
          <div className="row">
            <div className="col-xl-6 col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Other Information</h4>
                </div>
                <div className="card-body">
                  <div className="basic-form">
                    <ul className="user_additional_info">
                      <li>
                        <div className="user_additional_info_inner">
                          <Button className="theme-change-btn">
                            <AirplayIcon />
                          </Button>
                        </div>
                        <div className="user_additional_info_content">
                          <p>Last Login</p>
                          <h2>
                            {moment(userDetail?.updatedAt).format(
                              "Do MMMM YYYY"
                            )}
                          </h2>
                        </div>
                      </li>
                      <li>
                        <div className="user_additional_info_inner">
                          {/* <img  src={profile} alt="" /> */}
                          <Button className="theme-change-btn">
                            <SpaceDashboardIcon />
                          </Button>
                        </div>
                        <div className="user_additional_info_content">
                          <p>Last type</p>
                          <h2>General</h2>
                        </div>
                      </li>
                      <li>
                        <div className="user_additional_info_inner">
                          <Button className="theme-change-btn">
                            <CalendarMonthIcon />
                          </Button>
                        </div>
                        <div className="user_additional_info_content">
                          <p>Registered Date</p>
                          <h2>
                            {moment(userDetail?.createdAt).format(
                              "Do MMMM YYYY"
                            )}
                          </h2>
                        </div>
                      </li>
                      <li>
                        <div className="user_additional_info_inner">
                          <Button className="theme-change-btn">
                            <PhoneAndroidIcon />
                          </Button>
                        </div>
                        <div className="user_additional_info_content">
                          <p>Device Type</p>
                          <h2>
                            {userDetail?.device_type == 1 ? "iOS" : "android"}
                          </h2>
                        </div>
                      </li>

                      <li>
                        <div className="user_additional_info_inner">
                          <Button className="theme-change-btn">
                            <PermIdentityIcon />
                          </Button>
                        </div>
                        <div className="user_additional_info_content">
                          <p>Gender</p>
                          <h2>
                            {" "}
                            {userDetail?.gender == 1
                              ? "Male"
                              : userDetail?.gender == 2
                              ? "Female"
                              : userDetail?.gender == 3
                              ? "Not To Say"
                              : "N/A"}
                          </h2>
                        </div>
                      </li>

                      {userDetail?.role == 2 && (
                        <>
                          <li>
                            <div className="user_additional_info_inner">
                              <Button className="theme-change-btn">
                                <SchoolIcon />
                              </Button>
                            </div>
                            <div className="user_additional_info_content">
                              <p>PhD</p>
                              <h2> {userDetail?.phd == 1 ? "Yes" : "No"}</h2>
                            </div>
                          </li>
                          <li>
                            <div className="user_additional_info_inner">
                              <Button className="theme-change-btn">
                                <AccountBalanceIcon />
                              </Button>
                            </div>
                            <div className="user_additional_info_content">
                              <p>University</p>
                              <h2> {userDetail?.university}</h2>
                            </div>
                          </li>

                          <li>
                            <div className="user_additional_info_inner">
                              <Button className="theme-change-btn">
                                <SchoolIcon />
                              </Button>
                            </div>
                            <div className="user_additional_info_content">
                              <p>PhD Details</p>
                              <h2> {userDetail?.phd_details}</h2>
                            </div>
                          </li>
                        </>
                      )}

                      <li>
                        <div className="user_additional_info_inner">
                          <Button className="theme-change-btn">
                            <TranslateIcon />
                          </Button>
                        </div>
                        <div className="user_additional_info_content">
                          <p>Language</p>
                          <h2> {userDetail?.language}</h2>
                        </div>
                      </li>
                      {userDetail?.role == 2 && (
                        <li>
                          <div className="user_additional_info_inner">
                            <Button className="theme-change-btn">
                              <PermIdentityIcon />
                            </Button>
                          </div>
                          <div className="user_additional_info_content">
                            <p>Profession</p>
                            <h2> {userDetail?.profession}</h2>
                          </div>
                        </li>
                      )}
                      {userDetail?.role == 1 && (
                       <li>
                          <div className="user_additional_info_inner">
                            <Button className="theme-change-btn">
                             <InfoIcon/>
                            </Button>
                          </div>
                          <div className="user_additional_info_content">
                            <p>About Us</p>
                            <h2> {userDetail?.about_us ? userDetail?.about_us : "N/A"}</h2>
                          </div>
                        </li>
                      )} 
                      {userDetail?.role == 1 && (
                        <li>
                          <div className="user_additional_info_inner">
                            <Button className="theme-change-btn">
                              <SourceIcon />
                            </Button>
                          </div>
                          <div className="user_additional_info_content">
                            <p>Referral Code</p>
                            <h2> {userDetail?.referral_code ? userDetail?.referral_code : "N/A"}</h2>
                          </div>
                        </li>
                      )}  
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {userDetail?.role == 2 && (
              <div className="col-xl-6 col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Services</h4>
                  </div>
                  {/* {userDetail?.role == 1 && ( */}
                  <div className="card-body">
                    <div className="basic-form">
                      <ul className="social_content">
                        {
                        userDetail?.service.map(function (service_object, i) {
                          console.log(
                            service_object?.title,
                            "seivice  seivice seivice seivice"
                          );
                          return (
                            <li key={i}>
                              <div className="social_content_service">
                                <img
                                  src={
                                    IMG_URL +
                                    "/seviceicon/" +
                                    service_object?.icon
                                  }
                                  alt=""
                                  style={{ height: "55px" }}
                                />
                              </div>
                              <div className="badge-primary light social_content_inner">
                                <p>{service_object?.title}</p>
                              </div>
                            </li>
                          );
                        })
                        
                        }

                        {
                         userDetail?.role ==1 ?
                         userDetail?.description:""
                        }
                      </ul>
                    </div>
                  </div>
                   {/* )}  */}
                </div>
              </div>

                )}


              <div className="col-xl-6 col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Healthcare Area of Interest</h4>
                  </div>
                
                  <div className="card-body">
                    <div className="basic-form">
                      <ul className="social_content">

                        {
                         userDetail?.description ?
                         userDetail?.description:"No data Found"
                        }
                      </ul>
                    </div>
                  </div>
               
                </div>
              </div>

                
           
            <div className="col-xl-6">
              <div className="row">
                <div className="col-xl-6 col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Deactivate Account</h4>
                    </div>
                    <div className="card-body">
                      <div className="basic-form">
                        <h6>
                          You will not be able to receive messages,
                          notifications for up to 24 hours.
                        </h6>
                        <div className="row align-items-center">
                          <div className="col-auto">
                            <label className="sr-only">Name</label>
                          </div>
                          <div className="col-xl-4 col-xxl-6 col-6">
                            <div className="form-check custom-checkbox mb-3 checkbox-success">
                              <Switch
                                checked={isChecked !== 1 ? false : true}
                                //  value={Details?.isActive == 1 ? ON : false}
                                onChange={(e) => {
                                  console.log(e.target.checked, "checked");
                                  setShowAD(true);
                                }}
                                color="primary"
                                name="status"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-8">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Delete Account</h4>
                    </div>
                    <div className="card-body">
                      <div className="basic-form">
                        <h6>
                          Once you delete the account, there is no going back.
                          Please be certain.
                        </h6>
                        <div className="row align-items-center">
                          <div className="col-auto">
                            <label className="sr-only">Name</label>
                          </div>
                          <div className="col-12">
                            <div className="form-check custom-checkbox mb-3 checkbox-success">
                              <button
                                className="btn btn-danger mb-2"
                                onClick={handleShow}
                              >
                                Delete my account
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Reset Password</h4>
                </div>
                <div className="card-body">
                  <div className="basic-form">
                    {/* <form> */}
                    <div className="row">
                      <div className="form-group mb-3 col-md-6">
                        <label>New Password</label>
                        <input
                          type="password"
                          name="password"
                          className="form-control"
                          placeholder="Password"
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                        />
                      </div>
                      <div className="form-group mb-3 col-md-6">
                        <label>Confirm Password</label>
                        <input
                          type="password"
                          name="confirmPassword"
                          className="form-control"
                          placeholder="Password"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          value={confirmPassword}
                        />
                      </div>
                    </div>
                    <div className="row"></div>
                    <div className="form-group">
                      <div className=" "></div>
                    </div>
                    <button
                      onClick={() => {
                        handleChangePass();
                      }}
                      className="btn btn-primary"
                    >
                      Submit
                    </button>
                    {/* </form> */}
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are You 100% Sure About This?</Modal.Body>
        <Modal.Footer>
          <Buttonreact variant="secondary" onClick={handleClose}>
            Cancel
          </Buttonreact>
          <Buttonreact
            variant="danger"
            onClick={() => {
              dispatch(deleteUserAcc(userDetail?._id, navigate));
              setShow(false);
            }}
          >
            Delete
          </Buttonreact>
        </Modal.Footer>
      </Modal>

      {/* user deactivate */}

      <Modal show={showAD} onHide={handleADClose}>
        <Modal.Header closeButton>
          <Modal.Title> Activate / Deactivate Account </Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you want to sure?</Modal.Body>
        <Modal.Footer>
          {/* <Buttonreact variant="secondary" onClick={handleACClose}>
            Cancel
          </Buttonreact> */}
          <Buttonreact variant="danger" onClick={handleSwitchChange}>
            Yes
          </Buttonreact>
          <Buttonreact
            variant="danger"
            // onClick={handleSwitchChange}
            onClick={handleADClose}
          >
            No
          </Buttonreact>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

export default Pharmacistdetail;
