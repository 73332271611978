import { SVGICON } from "../../../constant/theme";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import WebIcon from "@mui/icons-material/Web";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import ReorderIcon from '@mui/icons-material/Reorder';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import PercentIcon from '@mui/icons-material/Percent';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import QuizIcon from '@mui/icons-material/Quiz';

export const MenuList = [
  //Dashboard
  {
    title: "Dashboard",
    classsChange: "mm-collapse",
    iconStyle: SVGICON.Home,
    to: "/dashboard",
    permission:"dashboard"
  },
  {
    title: "Pharmacist",
    iconStyle: SVGICON.Employe,
    to: "/Pharmacist",
    permission:"pharmacist"
  },
  {
    title: "Patient",
    iconStyle: SVGICON.Employe,
    to: "/Patient",
    permission:"patients"
  },

  {
    title: "Service",
    iconStyle: <MedicalServicesIcon />,
    to: "/service",
    permission:"services"
  },

  {
    title: "Products",
    iconStyle: <Inventory2OutlinedIcon />,
    to: "/products",
    permission:"products"
  },
  {
    title: "Product Categories",
    iconStyle: <MedicalServicesIcon />,
    to: "/product-categories",
    permission:"product_categories"
  },
  {
    title: "Order Management",
    iconStyle: <ReorderIcon/>,
    to: "/order-management",
    permission:"orders"
  },
  {
    title: "Blogs",
    iconStyle: <WebIcon />,
    to: "/blogs",
    permission:"blogs"
  },
  {
    title: "Promotions",
    iconStyle: <RecentActorsIcon />,
    to: "/promotion",
    permission:"promotions"
  },
  {
    title: "Promocode",
    iconStyle: <LibraryAddCheckIcon/>,
    to: "/promocode",
    permission:"promocode"
  },
  {
    title: "Notifications",
    iconStyle: <NotificationsNoneIcon/>,
    to: "/notification",
    permission:"notifications"
  },
  {
    title: "Commission",
    iconStyle: <PercentIcon/>,
    to: "/commissions",
    permission:
    "commission"
  },
  {
    title: "Manage Admin",
    iconStyle: SVGICON.Employe,
    to: "/manage-admins",
    permission:"manage_admin"
  },
  {
    title: "Order Support",
    iconStyle: <SupportAgentIcon/>,
    to: "/support",
    permission:"support"
  },
  
  {
    title: "FAQ Support",
    iconStyle: <QuizIcon/>,
    to: "/faq-support",
    permission:"faq-support"
  },
  

];
