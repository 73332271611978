import React, { useEffect } from 'react';

import DepositlineChart from './DepositlineChart';
import AllProjectDonutChart from './AllProjectDonutChart';
import { SVGICON } from '../../constant/theme';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardction } from '../../../store/actions/AdminAction';

const CardWidget = ({userDetail}) => {
   
    return (
        <>
            <div className="col-xl-4 col-sm-6">
                <div className="card chart-grd same-card">
                    <div className="card-body depostit-card">
                        <div className="depostit-card-media d-flex justify-content-between">
                            <div>
                                <h6>Total Users</h6>
                                <h3>{userDetail?.Totalusers}</h3>
                            </div>
                            <div className="icon-box bg-primary-light">
                                {SVGICON.User}
                            </div>
                        </div>                        
                        <DepositlineChart chartcolor="var(--primary)" />
                    </div>
                </div>
            </div>
    

            <div className="col-xl-4 col-sm-6">
                <div className="card chart-grd same-card">
                    <div className="card-body depostit-card">
                        <div className="depostit-card-media d-flex justify-content-between">
                            <div>
                                <h6>Total Patients</h6>
                                <h3>{userDetail?.patient}</h3>
                            </div>
                            <div className="icon-box bg-primary-light">
                                {SVGICON.User}
                            </div>
                        </div>                        
                        <DepositlineChart chartcolor="var(--primary)" />
                    </div>
                </div>
            </div>


            <div className="col-xl-4 col-sm-6">
                <div className="card chart-grd same-card">
                    <div className="card-body depostit-card">
                        <div className="depostit-card-media d-flex justify-content-between">
                            <div>
                                <h6>Total Pharmacists</h6>
                                <h3>{userDetail?.pharmacist}</h3>
                            </div>
                            <div className="icon-box bg-primary-light">
                                {SVGICON.User}
                            </div>
                        </div>                        
                        <DepositlineChart chartcolor="var(--primary)" />
                    </div>
                </div>
            </div>


       

 
        </>
    );
};

export default CardWidget;