import React, { useEffect, useState } from "react";
import Layout from "../components/layouts/Layout";
import { IMAGES } from "../constant/theme";
import MainPagetitle from "../components/layouts/MainPagetitle";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  PromocodesListing,
  productListing,
  AddPromocodes,
  deletePromocodes,
  updatepromocode,
  findpromocode,
  PausePromocode,
} from "../../store/actions/AdminAction";
import { useDispatch, useSelector } from "react-redux";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import moment from "moment";
import { Pause } from "@mui/icons-material";
const tableData = [
  {
    id: "1",
    Promocode: "ALOLYBC",
    Discount: "10%",
    Validity: "20-10-24",
    actionedit: "Edit",
    actiondelete: "Delete",
    actionpause: "Pause",
  },
  {
    id: "2",
    Promocode: "ALOLYBC",
    Discount: "10%",
    Validity: "20-10-24",
    actionedit: "Edit",
    actiondelete: "Delete",
    actionpause: "Pause",
  },
  {
    id: "3",
    Promocode: "ALOLYBC",
    Discount: "10%",
    Validity: "20-10-24",
    actionedit: "Edit",
    actiondelete: "Delete",
    actionpause: "Pause",
  },
  {
    id: "4",
    Promocode: "ALOLYBC",
    Discount: "10%",
    Validity: "20-10-24",
    actionedit: "Edit",
    actiondelete: "Delete",
    actionpause: "Pause",
  },
  {
    id: "5",
    Promocode: "ALOLYBC",
    Discount: "10%",
    Validity: "20-10-24",
    actionedit: "Edit",
    actiondelete: "Delete",
    actionpause: "Pause",
  },
  {
    id: "6",
    Promocode: "ALOLYBC",
    Discount: "10%",
    Validity: "20-10-24",
    actionedit: "Edit",
    actiondelete: "Delete",
    actionpause: "Pause",
  },
  {
    id: "7",
    Promocode: "ALOLYBC",
    Discount: "10%",
    Validity: "20-10-24",
    actionedit: "Edit",
    actiondelete: "Delete",
    actionpause: "Pause",
  },
  {
    id: "8",
    Promocode: "ALOLYBC",
    Discount: "10%",
    Validity: "20-10-24",
    actionedit: "Edit",
    actiondelete: "Delete",
    actionpause: "Pause",
  },
  {
    id: "9",
    Promocode: "ALOLYBC",
    Discount: "10%",
    Validity: "20-10-24",
    actionedit: "Edit",
    actiondelete: "Delete",
    actionpause: "Pause",
  },
];

const headers = [
  { label: "Name", key: "name" },
  { label: "Designation", key: "designation" },
  { label: "User Name", key: "username" },
  { label: "Contact", key: "contact" },
  { label: "Gender", key: "gender" },
  { label: "Location", key: "location" },
  { label: "action", key: "action" },
];

const csvlink = {
  headers: headers,
  data: tableData,
  filename: "csvfile.csv",
};

const PromocodeSchema = Yup.object().shape({
  name: Yup.string().required(<span style={{ color: "red" }}>Required</span>),
  discount: Yup.number().required(
    <span style={{ color: "red" }}>Required</span>
  ),
  startvalidity: Yup.date().required(
    <span style={{ color: "red" }}>Required</span>
  ),
  endvalidity: Yup.date().required(
    <span style={{ color: "red" }}>Required</span>
  ),
});

export default function Promocode() {
  const promocodedata = useSelector(
    (state) => state?.promocode_list?.promocodelist
  );
  console.log(promocodedata);
  const [page, setPage] = useState(1);

  const [limit, setlimit] = useState(10);
  const [flag, setflag] = useState(false);
  const [id, setid] = useState("");
  console.log(id);

  const [pid, setpid] = useState("");
  const [promoid, setpromoid] = useState("");
  console.log(promoid);

  const [ID, setID] = useState("");

  const lastIndex = page * limit;
  const npage = Math.ceil(
    (promocodedata?.totalCount == undefined ? 0 : promocodedata?.totalCount) /
      limit
  );
  const number = npage ? [...Array(npage + 1).keys()]?.slice(1) : [1];

  function prePage() {
    if (page !== 1) {
      setPage(page - 1);
    }
  }
  function changeCPage(id) {
    setPage(id);
  }
  function nextPage() {
    if (page !== npage) {
      setPage(page + 1);
    }
  }
  const [show, setShow] = useState(false);
  const [promocodeobject, setPromocode] = useState({});
  const [showd, setshowd] = useState(false);
  const [showp, setshowp] = useState(false);
  const [showedit, setshowedit] = useState(false);
  const [showact, setshowact] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseEdit = () => {
    setshowedit(false);

    setPromocode({});
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(PromocodesListing({ limit: limit, page: page }));
  }, [limit, page, flag]);

  const handlerdeleteP = async () => {
    try {
      const data = await dispatch(deletePromocodes({ promo_id: id }));
      console.log(data);
      if (data?.status == 200) {
        toast.success(data?.data?.message);
        setshowd(false);
      } else {
        toast.error(data?.data?.message);
      }
      setflag(!flag);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePausePromo = async () => {
    try {
      const data = await dispatch(
        PausePromocode({ promo_id: pid, is_pause: 1 })
      );
      console.log(data);
      if (data?.status == 200) {
        toast.success(data?.data?.message);
        setshowp(false);
      } else {
        toast.error(data?.data?.message);
      }
      setflag(!flag);
    } catch (error) {
      console.log(error);
    }
  };

  const handleActivatePromo = async () => {
    try {
      const data = await dispatch(
        PausePromocode({ promo_id: ID, is_pause: 0 })
      );
      console.log(data);
      if (data?.status == 200) {
        toast.success(data?.data?.message);
        setshowact(false);
      } else {
        toast.error(data?.data?.message);
      }
      setflag(!flag);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Layout>
        <MainPagetitle
          mainTitle="Promocode"
          pageTitle="Promocode"
          parentTitle="Home"
        />
        <div className="container-fluid">
          <div className="row">
            <div className="mb-3 col-auto float-end ms-auto ">
              <Link className="btn btn-secondary " onClick={handleShow}>
                Add Promocode
              </Link>
            </div>
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body p-0">
                  <div className="table-responsive active-projects manage-client">
                    <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                      <h4 className="heading mb-0">Promocode</h4>
                      {/* <div>
                                          <CSVLink {...csvlink} className="btn btn-primary light btn-sm">
                                              <i className="fa-solid fa-file-excel" /> {" "} 
                                              Export Report
                                          </CSVLink> 
                                      </div> */}
                    </div>
                    <div
                      id="manage-tblwrapper"
                      className="dataTables_wrapper no-footer"
                    >
                      <table
                        id="reports-tbl"
                        className="table ItemsCheckboxSec dataTable no-footer mb-0"
                      >
                        <thead>
                          <tr>
                            <th>S.no</th>
                            <th>Promocode</th>
                            <th>Discount</th>
                            <th> Start Validity</th>
                            <th> End Validity</th>
                            <th>status</th>

                            <th style={{ textAlign: "center" }}>action</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {promocodedata?.data?.map((data, index) => {
                            const serialNumber = (page - 1) * limit + index + 1;
                            console.log(data);
                            return (
                              <tr>
                                <td>{serialNumber}</td>
                                <td>{data.code_name}</td>
                                <td>{data.discount}%</td>
                                <td>
                                  {` ${new Date(
                                    data?.validity_period
                                  ).toLocaleDateString()}       
                              `}
                                </td>
                                <td>
                                  {/* {" "}
                              {` ${new Date(
                                data?.end_period
                              ).toLocaleDateString()}           
                              `} */}{" "}
                                  {new Date(
                                    data?.end_period
                                  ).toLocaleDateString()}{" "}
                                  {new Date(data?.end_period) >=
                                  new Date(new Date().setHours(0, 0, 0, 0))}{" "}
                                </td>
                                <td>
                                  {data?.is_pause == 0 ? "Active" : "Pause"}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <button
                                    // to=""
                                    className="badge-success light border-0 px-4 py-2"
                                    onClick={async () => {
                                      setshowedit(true);

                                      setPromocode(data);
                                      setpromoid(data?._id);

                                      // FindPromohandler(data._id);
                                    }}
                                  >
                                    {" "}
                                    Edit
                                  </button>
                                  <button
                                    className="badge-danger light border-0 px-4 py-2"
                                    onClick={() => {
                                      setshowd(true);

                                      setid(data?._id);
                                    }}
                                  >
                                    Delete
                                  </button>
                                  <button
                                    className="badge-secondary light border-0 px-4 py-2"
                                    onClick={() => {
                                      setshowp(true);
                                      setpid(data?._id);
                                    }}
                                  >
                                    Pause {data.actionpause}
                                  </button>
                                  <button
                                    // to=""
                                    className="badge-success light border-0 px-4 py-2"
                                    onClick={async () => {
                                      setshowact(true);
                                      setID(data._id);
                                    }}
                                  >
                                    {" "}
                                    Activate
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <div className="d-sm-flex text-center justify-content-between align-items-center">
                        <div className="dataTables_info">
                          Showing {lastIndex - limit + 1} to{" "}
                          {promocodedata?.totalCount &&
                          promocodedata?.totalCount < lastIndex
                            ? promocodedata?.totalCount
                            : lastIndex}{" "}
                          of{" "}
                          {promocodedata?.totalCount &&
                            promocodedata?.totalCount}{" "}
                        </div>
                        <div
                          className="dataTables_paginate paging_simple_numbers justify-content-center"
                          id="example2_paginate"
                        >
                          <Link
                            className="paginate_button previous disabled"
                            to="#"
                            onClick={prePage}
                          >
                            <i className="fa-solid fa-angle-left" />
                          </Link>
                          <span>
                            {number.map((n, i) => (
                              <Link
                                className={`paginate_button ${
                                  page === n ? "current" : ""
                                } `}
                                key={i}
                                onClick={() => changeCPage(n)}
                              >
                                {n}
                              </Link>
                            ))}
                          </span>
                          <Link
                            className="paginate_button next"
                            to="#"
                            onClick={nextPage}
                          >
                            <i className="fa-solid fa-angle-right" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Promocode</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                name: "",
                discount: "",
                startvalidity: "",
                endvalidity: "",
              }}
              validationSchema={PromocodeSchema}
              onSubmit={async (values) => {
                console.log(values);

                try {
                  const data = await dispatch(
                    AddPromocodes({
                      code_name: values?.name,
                      discount: values?.discount,
                      validity_period: values?.startvalidity,
                      end_period: values?.endvalidity,
                    })
                  );
                  console.log(data);
                  if (data?.status == 200) {
                    toast.success(data?.data?.message);
                    handleClose(false);
                    setflag(!flag);
                  } else {
                    toast.error(data?.data?.message);
                  }
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              {({ values, handleChange, handleSubmit }) => (
                <Form>
                  <div className="promocode-add">
                    <div className="row">
                      <div className="form-group mb-3 col-md-6">
                        <label>Name</label>
                        <Field
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="Type Here"
                          onChange={handleChange}
                          value={values?.name}
                        />
                        <ErrorMessage name="name" component="div" />
                      </div>
                      <div className="form-group mb-3 col-md-6">
                        <label>Discount</label>
                        <Field
                          type="number"
                          name="discount"
                          className="form-control"
                          placeholder="Enter In %"
                          onChange={handleChange}
                          value={values?.discount}
                        />
                        <ErrorMessage name="discount" component="div" />
                      </div>
                      <div className="form-group mb-3 col-md-6">
                        <label> Start Validity</label>
                        <Field
                          type="date"
                          name="startvalidity"
                          className="form-control"
                          placeholder="Type Here"
                          onChange={handleChange}
                          value={values?.startvalidity}
                        />
                        <ErrorMessage name="startvalidity" component="div" />
                      </div>
                      <div className="form-group mb-3 col-md-6">
                        <label> End Validity</label>
                        <Field
                          type="date"
                          name="endvalidity"
                          className="form-control"
                          placeholder="Type Here"
                          onChange={handleChange}
                          value={values?.endvalidity}
                        />
                        <ErrorMessage name="endvalidity" component="div" />
                      </div>
                    </div>
                  </div>

                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      type="button"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Save Changes
                    </Button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>

        {/* delete promocode modal */}

        <Modal show={showd} animation={false} className="delete-popup">
          <Modal.Body className="text-center">
            <h2 className="are-sure-text">
              Are you sure you want to Delete this Promocode
            </h2>
          </Modal.Body>
          <Modal.Footer className="justify-content-center border-none">
            <Button className="theme-btn" onClick={handlerdeleteP}>
              Yes
            </Button>
            <Button className="theme-btn" onClick={() => setshowd(false)}>
              No
            </Button>
          </Modal.Footer>
        </Modal>

        {/* edit */}

        <Modal show={showedit} onHide={handleCloseEdit}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Promocode</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                name: promocodeobject?.code_name,
                discount: promocodeobject?.discount,
                startvalidity: moment(promocodeobject?.validity_period).format(
                  "YYYY-MM-DD"
                ),
                endvalidity: moment(promocodeobject?.end_period).format(
                  "YYYY-MM-DD"
                ),
              }}
              validationSchema={PromocodeSchema}
              onSubmit={async (values) => {
                console.log(values);

                try {
                  const data = await dispatch(
                    updatepromocode({
                      code_name: values?.name,
                      discount: values?.discount,
                      validity_period: values?.startvalidity,
                      end_period: values?.endvalidity,
                      promo_id: promoid,
                    })
                  );
                  console.log(data);
                  if (data?.status == 200) {
                    toast.success(data?.data?.message);
                    handleClose(false);
                    setflag(!flag);
                    setshowedit(false);
                  } else {
                    toast.error(data?.data?.message);
                  }
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              {({ values, handleChange, handleSubmit }) => (
                <Form>
                  <div className="promocode-add">
                    <div className="row">
                      <div className="form-group mb-3 col-md-6">
                        <label>Name</label>
                        <Field
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="Type Here"
                          onChange={handleChange}
                          value={values.name}
                        />
                        <ErrorMessage name="name" component="div" />
                      </div>
                      <div className="form-group mb-3 col-md-6">
                        <label>Discount</label>
                        <Field
                          type="number"
                          name="discount"
                          className="form-control"
                          placeholder="Enter In %"
                          onChange={handleChange}
                          value={values?.discount}
                        />
                        <ErrorMessage name="discount" component="div" />
                      </div>
                      <div className="form-group mb-3 col-md-6">
                        <label> Start Validity</label>
                        <Field
                          type="date"
                          name="startvalidity"
                          className="form-control"
                          placeholder="Type Here"
                          onChange={handleChange}
                          value={values?.startvalidity}
                        />
                        <ErrorMessage name="startvalidity" component="div" />
                      </div>
                      <div className="form-group mb-3 col-md-6">
                        <label> End Validity</label>
                        <Field
                          type="date"
                          name="endvalidity"
                          className="form-control"
                          placeholder="Type Here"
                          onChange={handleChange}
                          value={values?.endvalidity}
                        />
                        <ErrorMessage name="endvalidity" component="div" />
                      </div>
                    </div>
                  </div>

                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEdit}>
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      type="button"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Save Changes
                    </Button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>

        {/* Pause Promocode */}
        <Modal show={showp} animation={false} className="delete-popup">
          <Modal.Body className="text-center">
            <h2 className="are-sure-text">
              Are you sure you want to Pause this Promocode
            </h2>
          </Modal.Body>
          <Modal.Footer className="justify-content-center border-none">
            <Button className="theme-btn" onClick={handlePausePromo}>
              Yes
            </Button>
            <Button className="theme-btn" onClick={() => setshowp(false)}>
              No
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showact} animation={false} className="delete-popup">
          <Modal.Body className="text-center">
            <h2 className="are-sure-text">
              Are you sure you want to Activate this Promocode
            </h2>
          </Modal.Body>
          <Modal.Footer className="justify-content-center border-none">
            <Button className="theme-btn" onClick={handleActivatePromo}>
              Yes
            </Button>
            <Button className="theme-btn" onClick={() => setshowact(false)}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </Layout>
    </div>
  );
}
