import { Route, Routes } from "react-router-dom";
import "./other/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import Login from "./jsx/pages/Login";
import Dashboard from "./jsx/pages/Dashboard";
import { ToastContainer } from "react-toastify";

import Report from "./jsx/pages/Report";
import ManageAdmins from "./jsx/pages/ManageAdmins";
import ChangePassword from "./jsx/pages/ChangePassword";
import PrivateRoute from "./jsx/routes/PrivateRoute";

import DriverDetail from "./jsx/pages/DriverDetail";
import Patients from "./jsx/pages/Patients";
import Pharmacist from "./jsx/pages/Pharmacist";
import Service from "./jsx/pages/Service";
import Pharmacistdetail from "./jsx/pages/Pharmacistdetail";
import CreateAdmin from "./jsx/pages/CreateAdmin";
import Products from "./jsx/pages/Products";
import CreateProduct from "./jsx/pages/CreateProduct";
import ViewProduct from "./jsx/pages/ViewProduct";
import Blogs from "./jsx/pages/Blogs";
import CreateBlog from "./jsx/pages/CreateBlog";
import ViewBlog from "./jsx/pages/ViewBlog";
import ViewAdmin from "./jsx/pages/ViewAdmin";
import OrderManagement from "./jsx/pages/OrderManagement";
import ViewOrder from "./jsx/pages/ViewOrder";
import Promotion from "./jsx/pages/Promotion";
import CreatePromotion from "./jsx/pages/CreatePromotion";
import Promocode from "./jsx/pages/Promocode";
import Notification from "./jsx/pages/Notification";
import ProductCategories from "./jsx/pages/ProductCategories";
import Commisson from "./jsx/pages/Commission";
import Support from "./jsx/pages/Support";
import FAQ from "./jsx/pages/FAQ";
import FAQChat from "./jsx/pages/FAQChat";


import SupportChat from "./jsx/pages/SupportChat";
function App() {
  return (
    <div className="vh-100">
      <ToastContainer limit={1} />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route element={<PrivateRoute />}>
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/Pharmacist" element={<Pharmacist />} />
          <Route path="/pharmacist/:id" element={<Pharmacistdetail />} />
          <Route path="/patient" element={<Patients />} />
          <Route path="/driver-detail/:id" element={<DriverDetail />} />
          <Route path="/service" element={<Service/>} />
          <Route path="/manage-admins" element={<ManageAdmins />} />
          <Route path="/create-Admin" element={<CreateAdmin />} />
          <Route path="/products" element={<Products />} />
          <Route path="/create-product" element={<CreateProduct />} />
          <Route path="/view-product" element={<ViewProduct />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/create-blog" element={<CreateBlog />} />
          <Route path="/view-blog/:id" element={<ViewBlog />} />
          <Route path="/view-admin" element={<ViewAdmin />} />
          <Route path="/order-management" element={<OrderManagement />} />
          <Route path="/view-order" element={<ViewOrder />} />
          <Route path="/promotion" element={<Promotion />} />
          <Route path="/create-promotion" element={<CreatePromotion />} />
          <Route path="/promocode" element={<Promocode />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/product-categories" element={<ProductCategories />} />
          <Route path="/commissions" element={<Commisson />} />
          <Route path="/support" element={<Support />} />
          <Route path="/support-chat" element={<SupportChat />} />
          <Route path="/support-chat" element={<SupportChat />} />
          <Route path="/faq-support" element={<FAQ />} />
          <Route path="/faq-chat" element={<FAQChat />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
