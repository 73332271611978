import Layout from "../components/layouts/Layout";
import React, { useReducer } from "react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import AirplayIcon from "@mui/icons-material/Airplay";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import SchoolIcon from "@mui/icons-material/School";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import TranslateIcon from "@mui/icons-material/Translate";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
/// Image
import profile from "../../images/profile/profile.png";
import uploadImg from "../../images/profile/profile-placeholder.png";
import MainPagetitle from "../components/layouts/MainPagetitle";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  changePassword,
  deleteUserAcc,
  updateUserProfile,
  viewUserDetail,
} from "../../store/actions/AdminAction";
import { AddadminPermission } from "../../store/actions/AdminAction";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import Buttonreact from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Button, Switch } from "@mui/material";
import { Formik, Form, input, ErrorMessage } from "formik";
// import PhoneInput from 'react-phone-number-input';
// import 'react-phone-number-input/style.css';
import * as yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const CreateAdmin = () => {
  const IMG_URL = process.env.REACT_APP_FILE_BASE_URL;
  const [profileImage, setProfileImage] = useState("");
  const [show, setShow] = useState(false);
  const [error, seterror] = useState(null);

  const [productCategories, setproductsCategories] = useState("");
  const [Dashboard, setdashboard] = useState("");
  const [manageAdmin, setManageadmin] = useState("");
  const [Promocode, setPromocode] = useState("");
  const [promotions, setPromotions] = useState("");
  const [Ordermanagement, setOrdermanagemanet] = useState("");
  const [notifications, setNotifications] = useState("");
  const [Commision,setCommission] = useState("");
  const [Support, setSupport] = useState("");
  
  const [patient, setpatient] = useState("");
  const [service, setservice] = useState("");
  const [products, setproducts] = useState("");
  const [Pharmacist, setpharmasist] = useState("");
  const [blogs, setblogs] = useState("");
  const [name, setname] = useState(null);
  const [namerre, setnameerr] = useState(null);
  const [email, setemail] = useState(null);
  const [emailerr, setemailerr] = useState(null);
  const [phone, setphone] = useState(null);
  const [countrycode, setcountrycode] = useState(null);

  const [phoneerr, setphoneerr] = useState(null);
  const [role, setrole] = useState(null);
  const [roleerr, setroleerr] = useState(null);
  const [password, setpassword] = useState(null);
  const [passerr, setpasserr] = useState(null);
  const [CCodeerr, setCCodeerr] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  console.log(error, "error");

  const handlesave = async () => {
    let isvaild = true;

    const permissions = [];
    if (Pharmacist) permissions.push("pharmacist");
    if (patient) permissions.push("patients");
    if (service) permissions.push("services");
    if (products) permissions.push("products");
    if (blogs) permissions.push("blogs");
    if (Dashboard) permissions.push("dashboard");
    if (productCategories) permissions.push("product_categories");
    if (Ordermanagement) permissions.push("orders");
    if (promotions) permissions.push("promotions");
    if (notifications) permissions.push("notifications");
    if (Commision) permissions.push("commission");
    if (manageAdmin) permissions.push("manage_admin");
    if (Support) permissions.push("support");
    if (Promocode) permissions.push("promocode");

    if (!name) {
      setnameerr("Required*");
      isvaild = false;
    }
    if (!email) {
      setemailerr("Required*");
      isvaild = false;
    }
    if (!phone) {
      setphoneerr("Required*");
      isvaild = false;
    } else if (phone.length !== 10) {
      setphoneerr("Invalid phone number");
      isvaild = false;
    } else if (phone.length > 10) {
      setphoneerr("Invalid phone number");
      isvaild = false;
    }

    if (!countrycode) {
      setCCodeerr("Required*");
      isvaild = false;
    }

    if (!role) {
      setroleerr("Required*");
      isvaild = false;
    }
    if (!password) {
      setpasserr("Required*");
      isvaild = false;
    }

    if (!Pharmacist && !patient && !service && !products && !blogs && !notifications && !manageAdmin && !Commision && !productCategories && ! Support && !promotions && !Promocode && !Ordermanagement && !Dashboard) {
      console.log("first");
      const err = "Please select at least one";
      seterror(err);
      isvaild = false;
    }
    if (isvaild) {
      try {
        const data = await dispatch(
          AddadminPermission({
            name: name,
            email: email,
            phone_number: phone,
            country_code: countrycode,
            role: role,
            password: password,
            permission: permissions,
          })
        );
        console.log(data);
        if (data?.status == 200) {
          toast.success(data?.data?.message);
          handleClose(false);
          // setflag(!flag);
          navigate("/manage-admins");
        } else {
          toast.error(data?.data?.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <Layout>
      <div>
        <MainPagetitle
          mainTitle="Create Admin"
          pageTitle="Create Admin"
          parentTitle="Manage Admin"
        />
        <div className="container-fluid">
          <div className="row">
            <div className="mb-3 col-auto float-end ms-auto ">
              <button
                className="btn btn-secondary"
                type="submit"
                onClick={handlesave}
              >
                Save
                {/* "/create-Admin" */}
              </button>
            </div>
            <div className="col-lg-12">
              <div className="profile card card-body px-3 pt-3 pb-0">
                <div className="profile-head">
                  <div className="photo-content ">
                    {/* <div className="cover-photo rounded"></div> */}
                  </div>
                  <div className="profile-info">
                    <div className="card-body">
                      <div className="basic-form">
                        <form onSubmit={(e) => e.preventDefault()}>
                          <div className="row">
                            <div className="form-group mb-3 col-md-6">
                              <label>Full Name</label>
                              <input
                                type="name"
                                name="name"
                                className="form-control"
                                onChange={(e) => {
                                  setname(e.target.value);
                                  setnameerr("");
                                }}
                                values={name}
                                placeholder="Full Name"
                              />
                              <div className="text-danger">{namerre}</div>
                              {/* <ErrorMessage name="name" component="div" /> */}
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label>Email</label>
                              <input
                                type="text"
                                name="email"
                                onChange={(e) => {
                                  setemail(e.target.value);
                                  setemailerr("");
                                }}
                                values={email}
                                className="form-control"
                                placeholder="Email"
                              />
                              <div className="text-danger">{emailerr}</div>
                            </div>

                            <div className="form-group  col-md-6">
                              <label>Phone</label>
                              <div className="d-flex">
                                {/* <PhoneInput
                               style={{ width: "100px" }}
                                international
                                defaultCountry="AU"
                                value={countrycode}
                                onChange={(value) => {
                                  setcountrycode(value);
                                  setCCodeerr("");
                                }}
                                
                                className="form-control"
                              /> */}
                                <PhoneInput
                                 style={{ width: "100px" }}
                                 international
                            
                                  enableSearch={true}
                                  value={countrycode}
                                  placeholder="+2"
                                  onChange={(value) => {
                                    setcountrycode(value);
                                    setCCodeerr("");
                                  }}
                                  inputStyle={{ width: '100px' ,}}
                                />

                                <input
                                  type="number"
                                  name="phone"
                                  onChange={(e) => {
                                    setphone(e.target.value);
                                    setphoneerr("");
                                  }}
                                  value={phone}
                                  className="form-control"
                                  placeholder="Phone"
                                
                                />
                              </div>
                              <div className="text-danger">{phoneerr}</div>
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label>Password</label>
                              <input
                                type="text"
                                name="password"
                                className="form-control"
                                onChange={(e) => {
                                  setpassword(e.target.value);
                                  setpasserr("");
                                }}
                                values={password}
                                placeholder="Password"
                              />
                              <div className="text-danger">{passerr}</div>
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label>Role</label>
                              <input
                                type="text"
                                name="role"
                                onChange={(e) => {
                                  setrole(e.target.value);
                                  setroleerr("");
                                }}
                                values={role}
                                className="form-control"
                                placeholder="Role"
                              />
                              {/* <ErrorMessage name="role" component="div" /> */}
                              <div className="text-danger">{roleerr}</div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-6 col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">ACCESS & PERMISSIONS</h4>
                </div>
                <div className="card-body">
                  <div className="basic-form">
                    <div className="permissions d-flex justify-content-between">
                      <h2>General</h2>
                      <div className="right d-flex">
                        <p>YES </p>
                        <p className="ms-4">No</p>
                      </div>
                    </div>
                    <div className="permissions d-flex justify-content-between">
                      <h4>Dashboard</h4>
                      <div className="right d-flex">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="DashboardAccess"
                            id="flexRadioDefault1"
                            onChange={(e) => {
                              setdashboard(e.target.checked);
                              seterror("");
                            }}
                          />
                        </div>
                        <div className="form-check ms-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="DashboardAccess"
                            id="flexRadioDefault2"
                            onChange={(e) => {
                              setdashboard(!e.target.checked);
                              seterror("");
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="permissions d-flex justify-content-between">
                      <h4>Pharmacist</h4>
                      <div className="right d-flex">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="pharmacistAccess"
                            onChange={(e) => {
                              console.log("hiii");
                              setpharmasist(e.target.checked);
                              seterror("");
                            }}
                          />
                        </div>
                        <div className="form-check ms-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="pharmacistAccess"
                            onChange={(e) => {
                              setpharmasist(!e.target.checked);
                              seterror("");
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="permissions d-flex justify-content-between">
                      <h4>Patient</h4>
                      <div className="right d-flex">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="patientAccess"  
                            id="flexRadioDefault1"
                            onChange={(e) => {
                              setpatient(e.target.checked);
                              seterror("");
                            }}
                          />
                        </div>
                        <div className="form-check ms-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="patientAccess"
                            id="flexRadioDefault2"
                            onChange={(e) => {
                              setpatient(!e.target.checked);
                              seterror("");
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="permissions d-flex justify-content-between">
                      <h4>Service</h4>
                      <div className="right d-flex">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="serviceAccess"
                            id="flexRadioDefault1"
                            onChange={(e) => {
                              setservice(e.target.checked);
                              seterror("");
                            }}
                          />
                        </div>
                        <div className="form-check ms-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="serviceAccess"
                            id="flexRadioDefault2"
                            onChange={(e) => {
                              setservice(!e.target.checked);
                              seterror("");
                            }}
                          />
                        </div>
                      </div>
                    </div>
                   <div className="permissions d-flex justify-content-between">
                      <h4>Products</h4>
                      <div className="right d-flex">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="productsAccess"
                            id="flexRadioDefault6"
                            onChange={(e) => {
                              setproducts(e.target.checked);
                              seterror("");
                            }}
                          />
                        </div>
                        <div className="form-check ms-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="productsAccess"
                            id="flexRadioDefault6"
                            onChange={(e) => {
                              setproducts(!e.target.checked);
                              seterror("");
                            }}
                          />
                        </div>
                      </div>
                    </div>
                   
                     <div className="permissions d-flex justify-content-between">
                      <h4>Product Categories</h4>
                      <div className="right d-flex">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="productsCategoriesAccess"
                            id="flexRadioDefault1"
                            onChange={(e) => {
                              setproductsCategories(e.target.checked);
                              seterror("");
                            }}
                          />
                        </div>
                        <div className="form-check ms-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="productsCategoriesAccess"
                            id="flexRadioDefault2"
                            onChange={(e) => {
                              setproductsCategories(!e.target.checked);
                              seterror("");
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="permissions d-flex justify-content-between">
                      <h4>Order Management</h4>
                      <div className="right d-flex">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="OrderManagementAccess"
                            id="flexRadioDefault1"
                            onChange={(e) => {
                              setOrdermanagemanet(e.target.checked);
                              seterror("");
                            }}
                          />
                        </div>
                        <div className="form-check ms-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="OrderManagementAccess"
                            id="flexRadioDefault2"
                            onChange={(e) => {
                              setOrdermanagemanet(!e.target.checked);
                              seterror("");
                            }}
                          />
                        </div>
                      </div>
                    </div>
                   
                    <div className="permissions d-flex justify-content-between">
                      <h4>Blogs</h4>
                      <div className="right d-flex">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="blogsAccess"
                            id="flexRadioDefault1"
                            onChange={(e) => {
                              setblogs(e.target.checked);
                              seterror("");
                            }}
                          />
                        </div>
                        <div className="form-check ms-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="blogsAccess"
                            id="flexRadioDefault2"
                            onChange={(e) => {
                              setblogs(!e.target.checked);
                              seterror("");
                            }}
                          />
                        </div>
                      </div>
                    </div>
                   



                    <div className="permissions d-flex justify-content-between">
                      <h4>Promotions</h4>
                      <div className="right d-flex">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="PromotionsAccess"
                            id="flexRadioDefault1"
                            onChange={(e) => {
                              setPromotions(e.target.checked);
                              seterror("");
                            }}
                          />
                        </div>
                        <div className="form-check ms-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="PromotionsAccess"
                            id="flexRadioDefault2"
                            onChange={(e) => {
                              setPromotions(!e.target.checked);
                              seterror("");
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="permissions d-flex justify-content-between">
                      <h4>Promocode</h4>
                      <div className="right d-flex">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="PromocodeAccess"
                            id="flexRadioDefault1"
                            onChange={(e) => {
                              setPromocode(e.target.checked);
                              seterror("");
                            }}
                          />
                        </div>
                        <div className="form-check ms-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="PromocodeAccess"
                            id="flexRadioDefault2"
                            onChange={(e) => {
                              setPromocode(!e.target.checked);
                              seterror("");
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="permissions d-flex justify-content-between">
                      <h4>Notifications</h4>
                      <div className="right d-flex">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="NotificationsAccess"
                            id="flexRadioDefault1"
                            onChange={(e) => {
                              setNotifications(e.target.checked);
                              seterror("");
                            }}
                          />
                        </div>
                        <div className="form-check ms-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="NotificationsAccess"
                            id="flexRadioDefault2"
                            onChange={(e) => {
                              setNotifications(!e.target.checked);
                              seterror("");
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="permissions d-flex justify-content-between">
                      <h4>Commission</h4>
                      <div className="right d-flex">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="CommissionAccess"
                            id="flexRadioDefault1"
                            onChange={(e) => {
                              setCommission(e.target.checked);
                              seterror("");
                            }}
                          />
                        </div>
                        <div className="form-check ms-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="CommissionAccess"
                            id="flexRadioDefault2"
                            onChange={(e) => {
                              setCommission(!e.target.checked);
                              seterror("");
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="permissions d-flex justify-content-between">
                      <h4>Manage Admin</h4>
                      <div className="right d-flex">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="ManageadminAccess"
                            id="flexRadioDefault1"
                            onChange={(e) => {
                              setManageadmin(e.target.checked);
                              seterror("");
                            }}
                          />
                        </div>
                        <div className="form-check ms-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="ManageadminAccess"
                            id="flexRadioDefault2"
                            onChange={(e) => {
                              setManageadmin(!e.target.checked);
                              seterror("");
                            }}
                          />
                        </div>
                      </div>
                    </div>

                  

                     <div className="permissions d-flex justify-content-between">
                      <h4>Support</h4>
                      <div className="right d-flex">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="SupportAccess"
                            id="flexRadioDefault1"
                            onChange={(e) => {
                              setSupport(e.target.checked);
                              seterror("");
                            }}
                          />
                        </div>
                        <div className="form-check ms-4">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="SupportAccess"
                            id="flexRadioDefault2"
                            onChange={(e) => {
                              setSupport(!e.target.checked);
                              seterror("");
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <p className="text-danger">{error}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CreateAdmin;
