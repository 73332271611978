import Layout from "../components/layouts/Layout";
import React, { useEffect, useReducer } from "react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import MainPagetitle from "../components/layouts/MainPagetitle";
import { useState } from "react";
import MyEditor from "../components/elements/TextEditor";
import EditEditor from "../components/elements/EditEditor";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  deleteBlog,
  findblog,
  updateBlogStatus,
} from "../../store/actions/AdminAction";
import draftToHtml from "draftjs-to-html";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import Buttonreact from "react-bootstrap/Button";

const ViewBlog = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [titleerr, setTitleerr] = useState("");
  const [htmlEntity, setHtmlEntity] = useState("");
  const [htmlContent, setHtmlContent] = useState();
  const [htmlContenterr, setHtmlContenterr] = useState();
  const [status, setstatus] = useState();
  const [statuserr, setstatuserr] = useState();
  const [givenContent, setGivenContent] = useState();
  const [webdata, setwebdata] = useState();
  const [show, setshow] = useState(false);
  const [prevImages, setprevImages] = useState([]);
  console.log(prevImages, "prview");
  const [prevImageserr, setprevImageserr] = useState(null);
  const [image, setImage] = useState([]);

  var fetchdata = async () => {
    let data = await dispatch(findblog({ blog_id: id }));
    console.log(data?.data?.data?.blog?.blog_image, "datadsfsdfsdfsdf");
    if (data?.data?.data?.blog?.blog_image) {
      setTitle(data?.data?.data?.blog?.title);
      setstatus(data?.data?.data?.blog?.publish_status);
      setHtmlContent(data?.data?.data?.blog?.description);
      setwebdata(data?.data?.data?.blog?.filter_description);
      setGivenContent(data?.data?.data?.blog?.description);
      setprevImages(data?.data?.data?.blog?.blog_image);
      setImage(data?.data?.data?.blog?.blog_image || []);
    }
  };

  useEffect(() => {
    fetchdata();
  }, [id]);

  // const handleImageChange = (e) => {
  //   const files = e.target.files;
  //   console.log(files,"filesfiles")
  //   if (files.length > 3) {
  //     toast.error("You can upload a maximum of 3 images.");
  //     return;
  //   }
  //   setImage(Array.from(files));
  //   setprevImages(Array.from(files).map(file => URL.createObjectURL(file)));
  //   setprevImageserr("");
  // };

  const handleImageChange = (e) => {
    const files = e.target.files;
    if (files.length > 3) {
      toast.error("You can upload a maximum of 3 images.");
      return;
    }
    setImage(Array.from(files));
    setprevImages(Array.from(files).map((file) => URL.createObjectURL(file)));
    setprevImageserr("");
  };

  const dataHtmlHandle = (html) => {
    console.log(
      html,
      draftToHtml(JSON.parse(html)),
      "---------html-----------------"
    );
    setHtmlContent(draftToHtml(JSON.parse(html)));
    setHtmlEntity(html);
  };

  const handlesave = async () => {
    let isvalid = true;

    if (!title) {
      setTitleerr("Required*");
      isvalid = false;
    }
    // if (!htmlContent) {
    //   setHtmlContenterr("Required*");
    //   isvalid = false;
    // }
    if (!status) {
      setstatuserr("Required*");
      isvalid = false;
    }
    if (isvalid) {
      const formdata = new FormData();

      formdata.append("description", htmlContent);

      formdata.append("title", title);

      formdata.append("filter_description", htmlEntity);
      formdata.append("publish_status", status);
      formdata.append("blogs_id", id);
      console.log(image, "image");
      image.forEach((img, index) => {
        formdata.append(`blog_image`, img);
      });

      try {
        let data = await dispatch(updateBlogStatus(formdata));

        if (data?.status == 200) {
          toast.success(data?.data?.message);
          navigate("/blogs");
        } else {
          toast.error(data?.data?.message);
        }
      } catch (error) {
        toast.error(error?.message);
      }
    }
  };

  return (
    <Layout>
      <div>
        <MainPagetitle
          mainTitle="Blog Detail"
          pageTitle="Blog Detail"
          parentTitle="Blogs"
        />
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 mb-3 col-auto d-flex justify-content-end ">
              <button className="btn btn-secondary " onClick={handlesave}>
                Update
              </button>
            </div>
            <div className="row">
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="profile card card-body px-3 pt-3 pb-0">
                      <div className="profile-head">
                        <div className="photo-content ">
                          {/* <div className="cover-photo rounded"></div> */}
                        </div>
                        <div className="profile-info">
                          <div className="card-body">
                            <div className="basic-form">
                              <form onSubmit={(e) => e.preventDefault()}>
                                <div className="row">
                                  <div className="form-group mb-3 col-md-12">
                                    <label>Blog Title</label>
                                    <input
                                      type="text"
                                      name="title"
                                      className="form-control"
                                      placeholder="John"
                                      onChange={(e) => {
                                        setTitle(e.target.value);
                                        setTitleerr("");
                                      }}
                                      value={title}
                                    />
                                    <div className="text-danger">
                                      {titleerr}
                                    </div>
                                  </div>

                             

                                  <div className="form-group mb-3 col-md-6">
                                    <label>Blog Image</label>
                                    <div className="user_detail_img_inner">
                                      <img
                                        src={require("../../images/uploadimage.png")}
                                      />
                                      <div className="main-img-contain view-blog">
                                        {prevImages?.map((prevImage, index) => (
                                          <div
                                            key={index}
                                            className="image-container"
                                          >
                                            <img
                                              src={
                                                prevImage.startsWith("blob:")
                                                  ? prevImage
                                                  : `https://api.telepharmacare.com/public/production/${prevImage}`
                                              }
                                              alt={`Preview ${index}`}
                                            />
                                          </div>
                                        ))}
                                      </div>
                                      <input
                                        className="user_detail_upload"
                                        id="formFileSm"
                                        type="file"
                                        name="productimage"
                                        onChange={handleImageChange}
                                        accept="image/*"
                                        multiple
                                      />
                                    </div>
                                    <label
                                      htmlFor="formFileSm"
                                      className="add-more-image"
                                    >
                                      <i className="fas fa-pencil-alt"></i> Edit
                                      Images
                                    </label>
                                    <div className="text-danger">
                                      {prevImageserr}
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <EditEditor
                      htmlHandle={dataHtmlHandle}
                      givenContent={givenContent}
                      webData={webdata}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="profile card card-body px-3 pt-3 pb-4 h-auto">
                  <div className="profile-head">
                    <div class="product-overview-box">
                      <h2>PUBLISH STATUS</h2>
                      <select
                        aria-label="Default select example"
                        name="publish_status"
                        class="form-select"
                        onChange={(e) => {
                          setstatus(e.target.value);
                          setstatuserr("");
                        }}
                        value={status}
                      >
                        <option>SELECT STATUS</option>
                        <option value="2">DRAFT</option>
                        <option value="1">PUBLISH</option>
                      </select>

                      <div className="text-danger">{statuserr}</div>
                      <div class="blog-save-btn">
                        <div class="cmn-btn">
                          {/* <button type="submit" className="btn btn-secondary ">
                            SAVE
                          </button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="profile card card-body px-3 pt-3 pb-4 h-auto">
                  <div className="profile-head mt-4">
                    <div class="product-overview-box">
                      <h2>ADMIN ACCESS</h2>

                      <div class="blog-delete-btn">
                        <div class="cmn-btn">
                          <button
                            type="submit"
                            className="btn btn-danger "
                            onClick={() => setshow(true)}
                          >
                            Delete Blog
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>

      <Modal show={show}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Blog</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you want to sure?</Modal.Body>
        <Modal.Footer>
          <Buttonreact variant="secondary" onClick={() => setshow(false)}>
            Cancel
          </Buttonreact>
          <Buttonreact
            variant="danger"
            onClick={async () => {
              try {
                let data = await dispatch(deleteBlog({ id: id }));

                if (data?.status == 200) {
                  toast.success(data?.data?.message);
                  navigate("/blogs");
                } else {
                  toast.error(data?.data?.message);
                }
              } catch (error) {
                toast.error(error?.message);
              }
            }}
          >
            Delete
          </Buttonreact>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

export default ViewBlog;
